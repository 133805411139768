import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTarifasEnvioPortes } from "../../actions/tarifasEnvioPortes";
import i18n from "../../lang/idiomas";
import { useEffect } from "react";

const TarifasEnvioPortes = (props) => {
  const dispatch = useDispatch();

  const tarifas = useSelector((state) => state.tarifas.tarifas);
  const pedido = useSelector((state) => state.pedidos.pedido);
  const id_pedido_seleccionado = useSelector((state) => state.pedidos.id_pedido_seleccionado);
  const locale = useSelector((state) => state.precios.locale);
  const moneda = useSelector((state) => state.precios.moneda);

  useEffect(() => {
    dispatch(getTarifasEnvioPortes(id_pedido_seleccionado || pedido.id_pedido));
  }, [pedido.domicilio_envio, pedido.estado_dom_envio, pedido.provincia_dom_envio, id_pedido_seleccionado]);

  return tarifas && props.mostrar_mensaje === "S"
    ? tarifas.map((tarifa, index) =>
        props.tipo_tarifa && props.tipo_tarifa === "mixto" ? (
          <div className="row tarifa-envio-portes" key={index}>
            {`${i18n.t("tarifa.tppasiis")} ` +
              new Intl.NumberFormat(locale, {
                style: "currency",
                currency: moneda,
              }).format(Number.parseFloat(tarifa.importe_min_pagados).toFixed(2)) +
              ` ${i18n.t("tarifa.typesped")} ` +
              Number.parseFloat(tarifa.peso_max_pagados).toFixed(2) +
              " Kg"}
          </div>
        ) : props.tipo_tarifa && props.tipo_tarifa === "texto_peso" ? (
          <div className="row tarifa-envio-portes" key={index}>
            {`${i18n.t("tarifa.tppsipes")} ` + Number.parseFloat(tarifa.peso_max_pagados).toFixed(2)}
          </div>
        ) : (
          <div className="row tarifa-envio-portes" key={index}>
            {`${i18n.t("tarifa.tppasiis")} ` +
              new Intl.NumberFormat(locale, {
                style: "currency",
                currency: moneda,
              }).format(Number.parseFloat(tarifa.importe_min_pagados).toFixed(2))}
          </div>
        )
      )
    : "";
};

export default TarifasEnvioPortes;
