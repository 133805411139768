import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPresentaciones,
  limpiarPresentacion,
  setPresentacion,
} from "../../../actions/presentaciones";
import i18n from "../../../lang/idiomas";
import { cargarParametros } from "../../../helpers/funciones";

let parametros;
let parametros_usados = ["mostrar_des_pres"];

const Presentaciones = (props) => {
  const dispatch = useDispatch();

  const presentaciones = useSelector(
    (state) => state.presentaciones.presentaciones
  );
  const presentacion = useSelector(
    (state) => state.presentaciones.presentacion
  );
  const portal = useSelector((state) => state.portal.portal);

  parametros = cargarParametros(
    parametros_usados,
    portal.parametros_ecom,
    "ART"
  );

  useEffect(() => {
    //Ejecutado al montar el componente
    window.wookie();

    return () => {
      // Ejecutado al desmontarlo
      dispatch(limpiarPresentacion());
    };
  }, []);

  useEffect(() => {
    if (props.articulo) {
      if (!presentaciones[props.articulo]) {
        // Si el articulo no tiene presentaciones cargadas las solicitamos
        dispatch(getPresentaciones(props.articulo));
      }
    }
  }, [props.articulo]);

  useEffect(() => {
    if (
      Object.keys(presentaciones).length > 0 &&
      presentaciones[props.articulo] &&
      presentaciones[props.articulo][props.presentacion_defectiva] &&
      !presentacion?.presentacion
    ) {
      // Seteamos la presentación defectiva del articulo
      dispatch(
        setPresentacion(
          presentaciones[props.articulo][props.presentacion_defectiva]
        )
      );
    }
  }, [presentaciones]);

  const onClick = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      if (presentaciones[props.articulo][e.target.value]) {
        // Seteamos la presentacion seleccionada por el usuario
        dispatch(
          setPresentacion(presentaciones[props.articulo][e.target.value])
        );
      }
    }
  };

  return (
    <Fragment>
      {presentaciones[props.articulo] &&
      Object.keys(presentaciones[props.articulo]).length > 0 ? (
        <div className="row presentaciones">
          <div className="col">
            {props.oculta_titulo && props.oculta_titulo === true ? (
              ""
            ) : (
              <div className="titulo-presentaciones">
                {i18n.t("eparciales.eppresen")}
              </div>
            )}

            {Object.keys(presentaciones[props.articulo]).map((pres, index) => {
              return (
                <button
                  key={index}
                  className={
                    presentacion.presentacion &&
                    presentacion.articulo === props.articulo &&
                    presentacion.presentacion === pres
                      ? "btn btn-small btn-primary"
                      : "btn btn-small btn-border"
                  }
                  name="presentacion"
                  value={pres}
                  onClick={onClick}
                >
                  {parametros.mostrar_des_pres === "S"
                    ? presentaciones[props.articulo][pres].descripcion
                    : pres}
                </button>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Presentaciones;
