import React, { Fragment } from "react";
import ListadoArticulos from "./ListadoArticulos";

const PedidoHabitual = () => {
  return (
    <Fragment>
      <div className="container">
        <div id="tt-pageContent">
          <div className="container-indent">
            <div className="container-fluid-custom container-fluid-custom-mobile-padding">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-12">
                  <ListadoArticulos mostrar="lista" origen="pedido_habitual" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PedidoHabitual;
