import React, { Fragment } from "react";
import ListadoArticulos from "./ListadoArticulos";

const ArticulosEquivalentes = (props) => {
  switch (props.origen) {
    case "tabla":
      return (
        <Fragment>
          <ListadoArticulos mostrar="tabla" origen="equivalentes" id_articulo={props.id_articulo} />
        </Fragment>
      );
    case "ficha":
      return (
        <Fragment>
          <ListadoArticulos mostrar="lista" origen="equivalentes" id_articulo={props.id_articulo} />
        </Fragment>
      );

    default:
      return "";
  }
};

export default ArticulosEquivalentes;
