import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  GET_LISTAS_COMPRA_FAIL,
  DELETE_LISTA_COMPRA,
  DELETE_LISTA_COMPRA_SUCCESS,
  CREATE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA_SUCCESS,
  SET_VER_LISTA,
  DELETE_LINEA_LISTA_COMPRA,
  DELETE_LINEA_LISTA_COMPRA_SUCCESS,
  ACTUALIZAR_LINEA_LISTA,
  ACTUALIZAR_LINEA_LISTA_SUCCESS,
  GENERA_PEDIDO_LISTA,
  GENERA_PEDIDO_LISTA_SUCCESS,
  GENERA_PEDIDO_LISTA_FAIL,
  SET_VER_LISTA_SUCCESS,
  FLUSH_LISTAS_COMPRA_DETALLE,
} from "../actions/types";

const initialState = {
  listas_compra: [],
  lista_detalle: 0,
  lineas_lista_detalle: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LISTAS_COMPRA_FAIL:
    case GENERA_PEDIDO_LISTA_FAIL:
    case GENERA_PEDIDO_LISTA_SUCCESS:
    case ACTUALIZAR_LINEA_LISTA:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_LISTAS_COMPRA:
      return {
        ...state,
        listas_compra: [],
        lista_detalle: 0,
        lineas_lista_detalle: [],
        isLoading: true,
      };

    case DELETE_LISTA_COMPRA:
    case CREATE_LISTA_COMPRA:
    case GENERA_PEDIDO_LISTA:
    case SET_VER_LISTA:
    case DELETE_LINEA_LISTA_COMPRA:
      return {
        ...state,
        isLoading: true,
      };

    case SET_VER_LISTA_SUCCESS:
      return {
        ...state,
        lista_detalle: parseInt(action.payload.codigo_lista),
        lineas_lista_detalle: action.payload.lineas,
        isLoading: false,
      };

    case GET_LISTAS_COMPRA:
      return {
        ...state,
        isLoading: false,
        listas_compra: action.payload,
      };

    case DELETE_LISTA_COMPRA_SUCCESS:
      let listas = state.listas_compra.filter((lista) => lista.codigo_lista !== parseInt(action.payload));
      return {
        ...state,
        listas_compra: listas,
        isLoading: false,
      };

    case CREATE_LISTA_COMPRA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listas_compra: [...state.listas_compra, action.payload],
      };

    case DELETE_LINEA_LISTA_COMPRA_SUCCESS:
      let lineas = state.lineas_lista_detalle.filter((linea) => linea.id !== parseInt(action.payload));
      return {
        ...state,
        lineas_lista_detalle: lineas,
        isLoading: false,
      };

    case ACTUALIZAR_LINEA_LISTA_SUCCESS:
      let lineas_lista = state.lineas_lista_detalle.filter((linea) => linea.id !== parseInt(action.payload.linea.id));

      return {
        ...state,
        lineas_lista_detalle: [...lineas_lista, action.payload.linea],
        isLoading: false,
      };

    case FLUSH_LISTAS_COMPRA_DETALLE:
      return {
        ...state,
        lista_detalle: 0,
        lineas_lista_detalle: [],
        isLoading: false,
      };
    default:
      return state;
  }
}
