import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlmacenes, setAlmacenRecogida } from '../../actions/almacenes'
import { getEstado, getProvincia } from '../../actions/domiciliosEnvio'

const Almacenes = props => {
  const dispatch = useDispatch()

  const almacenes = useSelector(state => state.almacenes.almacenes)
  const almacen = useSelector(state => state.almacenes.almacen)
  const estado = useSelector(state => state.domiciliosEnvio.estado)
  const provincia = useSelector(state => state.domiciliosEnvio.provincia)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const pedido = useSelector(state => state.pedidos.pedido)

  useEffect(() => {
    dispatch(getAlmacenes())
  }, [])

  useEffect(() => {
    if (!pedido_cargando) {
      if (almacenes?.length === 1) {
        dispatch(getEstado(almacenes[0].codigo_estado))
        dispatch(getProvincia(almacenes[0].codigo_estado, almacenes[0].codigo_provincia))
        dispatch(setAlmacenRecogida(almacenes[0]))
      }

      if (
        almacenes?.length > 1 &&
        Object.keys(pedido).length > 0 &&
        pedido?.almacen !== '' &&
        pedido?.almacen !== null
      ) {
        const almacen_recogida = almacenes.filter(alma => alma.almacen === pedido.almacen)
        dispatch(getEstado(almacen_recogida[0].codigo_estado))
        dispatch(
          getProvincia(almacen_recogida[0].codigo_estado, almacen_recogida[0].codigo_provincia),
        )
        dispatch(setAlmacenRecogida(almacen_recogida[0]))
      }
    }
  }, [almacenes])

  const onchange = e => {
    if (e.target.value !== '') {
      const almacen_recogida = almacenes.filter(alma => alma.almacen === e.target.value)
      dispatch(getEstado(almacen_recogida[0].codigo_estado))
      dispatch(
        getProvincia(almacen_recogida[0].codigo_estado, almacen_recogida[0].codigo_provincia),
      )
      dispatch(setAlmacenRecogida(almacen_recogida[0]))
    }
  }

  return (
    <Fragment>
      <div className='tt-shopcart-box'>
        <h4 className='tt-title'>PUNTOS DE RECOGIDA</h4>

        <p>Seleccione un lugar de la lista</p>
        <form className='form-default'>
          <div className='form-group'>
            <label htmlFor='almacen_recogida'>
              PUNTO DE RECOGIDA<sup>*</sup>
            </label>

            {almacenes.length > 0 ? (
              <Fragment>
                <div className='form-group'>
                  <select
                    className='form-control'
                    value={almacen.almacen || ''}
                    name='almacen_recogida'
                    onChange={onchange}
                    disabled={pedido_cargando || props.pedidoGuardado}>
                    <option
                      key='0'
                      value=''>
                      Seleccione un punto de recogida
                    </option>
                    {almacenes.map((alm, index) => {
                      return (
                        <option
                          key={index}
                          value={alm.almacen}>
                          {alm.nombre}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor='address_province'>DIRECCIÓN</label>
                  <input
                    type='text'
                    name='direccion'
                    value={almacen.direccion || ''}
                    className='form-control'
                    placeholder='Dirección'
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='address_province'>LOCALIDAD</label>
                  <input
                    type='text'
                    name='localidad'
                    value={almacen.localidad || ''}
                    className='form-control'
                    placeholder='Localidad'
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='address_province'>CÓDIGO POSTAL</label>
                  <input
                    type='text'
                    name='cod_postal'
                    value={almacen.cod_postal || ''}
                    className='form-control'
                    placeholder='Código Postal'
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='address_province'>
                    ESTADO <sup>*</sup>
                  </label>
                  <input
                    type='text'
                    name='estado'
                    value={estado ? estado.nombre : ''}
                    className='form-control'
                    placeholder='Estado'
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='address_zip'>PROVINCIA</label>
                  <input
                    type='text'
                    name='provincia'
                    value={provincia ? provincia.nombre : ''}
                    className='form-control'
                    placeholder='Provincia'
                    readOnly
                  />
                </div>
              </Fragment>
            ) : (
              <p>No existen puntos de recogida disponibles</p>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default Almacenes
