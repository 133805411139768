import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormasEnvio, setFormaEnvio } from '../../actions/formasEnvio'
import i18n from '../../lang/idiomas'

const FormasEnvio = props => {
  const dispatch = useDispatch()
  const formas_envio = useSelector(state => state.formasEnvio.formas_envio)
  const forma_envio = useSelector(state => state.formasEnvio.forma_envio)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const pedido = useSelector(state => state.pedidos.pedido)

  useEffect(() => {
    dispatch(getFormasEnvio())
  }, [])

  useEffect(() => {
    if (!pedido_cargando) {
      if (formas_envio?.length === 1) {
        dispatch(setFormaEnvio(formas_envio[0]))
      }

      if (formas_envio?.length > 1 && pedido.forma_envio) {
        const forma_env = formas_envio.filter(
          form_env => form_env.forma_envio === pedido.forma_envio,
        )

        dispatch(setFormaEnvio(forma_env[0]))
      }
    }
  }, [formas_envio])

  const onChange = e => {
    if (e.target.value !== '') {
      const forma_env = formas_envio.filter(form_env => form_env.forma_envio === e.target.value)

      dispatch(setFormaEnvio(forma_env[0]))
    }
  }

  return (
    <Fragment>
      <div className='tt-shopcart-box'>
        <h4 className='tt-title'>{i18n.t('formas.formdenv')}</h4>
        <p>{i18n.t('formas.selforen')}</p>
        <form className='form-default'>
          <div className='form-group'>
            <label htmlFor='forma_envio'>
              {i18n.t('formas.forenmay')} <sup>*</sup>
            </label>
            <select
              className='form-control'
              name='forma_envio'
              onChange={e => {
                e.preventDefault()
                onChange(e)
              }}
              disabled={pedido_cargando || props.pedidoGuardado}
              value={forma_envio?.forma_envio || ''}>
              <option
                key='0'
                value=''>
                {i18n.t('formas.selfen')}
              </option>
              {formas_envio.map((forma, index) => {
                return (
                  <option
                    key={index}
                    value={forma.forma_envio}>
                    {forma.descripcion}
                  </option>
                )
              })}
            </select>
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default FormasEnvio
