import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import i18n from "../../lang/idiomas";
import { IdentificationCard, FileText, NewspaperClipping } from "phosphor-react";
import { setCarnetProfesional, nuevaReceta } from "../../actions/modalGenerica";

export class ArticuloNecesidades extends Component {
  static propTypes = {
    carnets: PropTypes.object.isRequired,
    setCarnetProfesional: PropTypes.func.isRequired,
  };

  pintarCarnet(articulo, carnets) {
    if (carnets && carnets.lineas && carnets.lineas.length > 0) {
      let index = -1;

      index = carnets.lineas.findIndex((carnet) => carnet.tipo_carnet === articulo.tipo_carnet_profesional);

      if (index !== -1) {
        switch (this.props.origen) {
          case "tabla":
          case "resumen":
            return (
              <button className="btn carnet-profesional-existe" onClick={() => this.verDatosCarnet(carnets.lineas[index], "carnet-articulo")}>
                <IdentificationCard size={20} alt={i18n.t("articulos.altvrcrt")} />
              </button>
            );
          case "articulo":
            return (
              <button className="btn carnet-profesional-existe" onClick={() => this.verDatosCarnet(carnets.lineas[index], "carnet-articulo")}>
                <IdentificationCard size={30} />
                {i18n.t("articulos.altvrcrt").toUpperCase()}
              </button>
            );
          default:
            return (
              <button className="boton-carnet-existe" data-tposition="left" onClick={() => this.verDatosCarnet(carnets.lineas[index], "carnet-articulo")}>
                <IdentificationCard size={20} weight={"regular"} alt={i18n.t("articulos.altvrcrt")} />
              </button>
            );
        }
      }
    }
    // Si no entra por ninguno de los anteriores
    switch (this.props.origen) {
      case "tabla":
      case "resumen":
        return (
          <button className="btn carnet-profesional-noexiste">
            <IdentificationCard size={20} alt={i18n.t("articulos.altnocrt")} />
          </button>
        );
      case "articulo":
        return (
          <button className="btn carnet-profesional-noexiste">
            <IdentificationCard size={30} alt={i18n.t("articulos.altnocrt")}/>
            {i18n.t("articulos.altcrt").toUpperCase()}
          </button>
        );
      default:
        return (
          <button className="boton-carnet-noexiste" data-tposition="left">
            <IdentificationCard size={20} weight={"regular"} alt={i18n.t("articulos.altnocrt")} />
          </button>
        );
    }
  }
  verDatosCarnet = (linea, origen) => {
    this.props.setCarnetProfesional(linea, origen);
  };

  pintarReceta(user) {
    if (user && user.con_receta && user.con_receta !== "N") {
      switch (this.props.origen) {
        case "tabla":
        case "resumen":
          return (
            <button className="btn receta-noexiste" onClick={() =>  this.props.nuevaReceta(this.props.articulo, 'receta')}>
              <FileText size={20} alt={i18n.t("articulos.altnecrc")} />
            </button>
          );
        case "articulo":
          return (
            <button className="btn receta-noexiste" onClick={() =>  this.props.nuevaReceta(this.props.articulo, 'receta')}>
              <FileText size={30} />
              {i18n.t("articulos.altartrc").toUpperCase()}
            </button>
          );
        default:
          return (
            <button className="boton-receta-noexiste" data-tposition="left" onClick={() =>  this.props.nuevaReceta(this.props.articulo, 'receta')}>
              <FileText size={20} weight={"regular"} alt={i18n.t("articulos.altnecrc")} />
            </button>
          );
      }
    } else {
      switch (this.props.origen) {
        case "tabla":
        case "resumen":
          return (
            <button className="btn receta-existe">
              <FileText size={20} alt={i18n.t("articulos.altnecrc") + i18n.t("articulos.altnecps")} />
            </button>
          );
        case "articulo":
          return (
            <button className="btn receta-existe">
              <FileText size={30} />
              {i18n.t("articulos.altartrc").toUpperCase()}
            </button>
          );
        default:
          return (
            <button className="boton-receta-existe" data-tposition="left" >
              <FileText size={20} weight={"regular"} alt={i18n.t("articulos.altnecrc") + i18n.t("articulos.altnecps")} />
            </button>
          );
      }
    }
  }
  render() {
    let carnets = this.props.carnets ? this.props.carnets : null;
    if (this.props.articulo && (this.props.articulo.tipo || this.props.articulo.tipo_carnet_profesional || this.props.articulo.numero_receta)) {
      switch (this.props.origen) {
        case "tabla":
        case "resumen":
          return (
            <div className={"iconos-articulo-" + this.props.origen}>
              <Fragment>
                {this.props.articulo.tipo && this.props.articulo.tipo === "C" ? (
                  <button className="btn catalogo">
                    <NewspaperClipping size={20} alt={i18n.t("articulos.altartct")} />
                  </button>
                ) : (
                  ""
                )}
                {this.props.articulo.tipo_carnet_profesional ? this.pintarCarnet(this.props.articulo, carnets) : ""}
                {this.props.articulo.numero_receta ? this.pintarReceta(this.props.user) : ""}
              </Fragment>
            </div>
          );
        case "articulo":
          return (
            <div className="iconos-articulo">
              <Fragment>
                {this.props.articulo.tipo && this.props.articulo.tipo === "C" ? (
                  <button className="btn catalogo">
                    <NewspaperClipping size={30} />
                    {i18n.t("articulos.altartct").toUpperCase()}
                  </button>
                ) : (
                  ""
                )}
                {this.props.articulo.tipo_carnet_profesional ? this.pintarCarnet(this.props.articulo, carnets) : ""}
                {this.props.articulo.numero_receta ? this.pintarReceta(this.props.user) : ""}
              </Fragment>
            </div>
          );
        default:
          return (
            <Fragment>
              {this.props.articulo.tipo && this.props.articulo.tipo === "C" ? (
                <button className="boton-catalogo" data-tposition="left">
                  <NewspaperClipping size={20} weight={"regular"} alt={i18n.t("articulos.altartct")} />
                </button>
              ) : (
                ""
              )}
              {this.props.articulo.tipo_carnet_profesional ? this.pintarCarnet(this.props.articulo, carnets) : ""}
              {this.props.articulo.numero_receta ? this.pintarReceta(this.props.user) : ""}
            </Fragment>
          );
      }
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  carnets: state.auth.user.carnets,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  setCarnetProfesional,
  nuevaReceta
})(ArticuloNecesidades);
