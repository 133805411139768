import config from './config'
import axios from 'axios'
import store from './store'
import { LOGOUT_SUCCESS, TOKEN_REFRESH } from './actions/types'
import { memoizedRefreshToken } from './actions/auth'

const axiosConfig = axios.create({
  baseURL: config.backend.url
    ? config.backend.url
    : window.location.protocol + '//' + window.location.host + '/api/v1',
})

let body

axiosConfig.interceptors.request.use(
  function (request) {
    let estado = store.getState()
    let actualLang = '01'
    let permisoSeleccionado =
      Object.keys(estado).filter(estado => estado === 'selectorCliente').length > 0
        ? estado.selectorCliente.cliente_seleccionado
        : ''

    estado.languageSelector.languages.forEach(lang => {
      if (lang.codigo_idioma.toLowerCase() === estado.languageSelector.language.toLowerCase()) {
        actualLang = lang.codigo
      }
    })

    if (config.log) {
      console.log('Codigo idioma actual url', actualLang)
      console.log('ID permiso seleccionado url ', permisoSeleccionado)
    }

    if (request.data !== undefined) {
      if (typeof request.data === 'string') {
        body = JSON.parse(request.data)
        body.idioma = actualLang
        body.permisoSeleccionado = permisoSeleccionado
        request.data = JSON.stringify(body)
      } else {
        body = request.data
        body.idioma = actualLang
        body.permisoSeleccionado = permisoSeleccionado
        request.data = JSON.parse(JSON.stringify(body))
      }

      if (config.log) {
        console.log('axios_config_request_data', request.data)
      }
    } else {
      if (!request.url.includes('idioma=')) {
        if (request.url.includes('?')) {
          request.url += '&idioma=' + actualLang
        } else {
          request.url += '?idioma=' + actualLang
        }
      }

      if (!request.url.includes('permisoSeleccionado=')) {
        if (request.url.includes('?')) {
          request.url += '&permisoSeleccionado=' + permisoSeleccionado
        } else {
          request.url += '?permisoSeleccionado=' + permisoSeleccionado
        }
      }

      if (!request.url.includes('permisoSeleccionado=')) {
        if (request.url.includes('?')) {
          request.url += '&permisoSeleccionado=' + permisoSeleccionado
        } else {
          request.url += '?permisoSeleccionado=' + permisoSeleccionado
        }
      }

      if (config.log) {
        console.log('axios_config_request_url', request.url)
      }
    }

    return request
  },
  async config => {
    const session = JSON.parse(localStorage.getItem('token'))

    if (session) {
      config.headers.Authorization = `Bearer ${session}`
    }

    return config
  },
  function (config) {
    if (config.log) {
      console.log(config)
    }
    return config
  },
  function (error) {
    if (config.log) {
      console.log(error)
    }
    return Promise.reject(error)
  },
)

axiosConfig.interceptors.response.use(
  function (response) {
    if (config.log) {
      console.log(response)
    }
    return response
  },
  async function (error) {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      // Si la respuesta del refresco devuelve 401
      if (config?.url === '/token/refresh/') {
        store.dispatch({
          type: LOGOUT_SUCCESS,
        })
        window.location.reload()
      } else {
        config.sent = true
        let estado = store.getState()

        const result = await memoizedRefreshToken()
        if (result) {
          config.headers.Authorization = `Bearer ${result}`
          if (estado?.auth?.token !== result) {
            store.dispatch({
              type: TOKEN_REFRESH,
              payload: result,
            })
          }
        } else {
          delete config.headers.Authorization
        }

        return axios(config)
      }
    }

    if (!error?.response?.status) {
      if (!error?.response) {
        error.response = {}
      }
      error.response.status = 500
    }
    return Promise.reject(error)
  },
)

export default axiosConfig
