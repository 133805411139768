import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setTipoExpediente } from "../../actions/expedientes";

export class ExpedientesMenu extends Component {
  static propTypes = {
    expedientes: PropTypes.array.isRequired,
    setTipoExpediente: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  componentWillUnmount() {
    this.props.setTipoExpediente("");
  }

  onClick = (e) => {
    e.preventDefault();
    if (typeof e.target.dataset.expediente !== "undefined") {
      this.props.setTipoExpediente(e.target.dataset.expediente);
    }
  };

  render() {
    return (
      <Fragment>
        <header className="menu-expedientes">
          <div className="esquema-color">
            <div className="container">
              <nav>
                <ul className="listado_tipos_expedientes">
                  {this.props.expedientes && Array.isArray(this.props.expedientes)
                    ? this.props.expedientes.map((expediente, index) => {
                        return (
                          <li key={index} className={"item-" + index}>
                            <a href="/#" data-expediente={index} onClick={this.onClick}>
                              {expediente.nombre.toUpperCase()}
                            </a>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  expedientes: state.expedientes.tipos_expedientes,
  language: state.languageSelector.language,
});

export default connect(mapStateToProps, {
  setTipoExpediente,
})(ExpedientesMenu);
