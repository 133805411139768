import {
  CERRAR_PEDIDO,
  DELETE_LINEA_PEDIDO,
  DELETE_PEDIDO,
  FETCH_LINEAS_PEDIDO_ACTIVO,
  FETCH_LINEAS_PEDIDO_ACTIVO_FAIL,
  FETCH_INFO_PEDIDO,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  FETCH_TEXTOS_VENTAS,
  GENERA_PAGO_PASARELA,
  GENERA_PAGO_PASARELA_SUCCESS,
  GET_INFO_PEDIDO,
  GET_INFO_PEDIDO_FAIL,
  GET_PEDIDO_ACTIVO,
  GET_TEXTOS_VENTAS,
  INTEGRANDO_PEDIDO,
  INTEGRAR_PEDIDO,
  INTEGRAR_PEDIDO_FAIL,
  POST_LINEA_PEDIDO,
  POST_LINEAS_PEDIDO,
  POST_PEDIDO,
  SET_COD_POSTAL_DOM_ENVIO_MOD,
  SET_DIRECCION_DOM_ENVIO_MOD,
  SET_EMAIL_PEDIDO,
  SET_ESTADO_DOM_ENVIO_MOD,
  SET_FECHA_ENTREGA,
  SET_ID_PEDIDO_SELECCIONADO,
  SET_LOCALIDAD_DOM_ENVIO_MOD,
  SET_NOMBRE_DOM_ENVIO_MOD,
  SET_NUMERO_PEDIDO_CLIENTE,
  SET_OBSERVACIONES_PEDIDO,
  SET_PERSONA_PEDIDO,
  SET_PROVINCIA_DOM_ENVIO_MOD,
  SET_TIPO_PORTES_DOM_ENVIO_MOD,
  NUEVO_PEDIDO_FAIL,
} from '../actions/types.js'

const initialState = {
  pedido: {},
  serie_pedido: '',
  num_pedido: '',
  numero_serie_pres: '',
  numero_pres: '',
  ejercicio: '',
  texto_venta: {},
  isLoading: false,
  isLoadingLines: false,
  id_pedido_seleccionado: '',
  almacen_seleccionado: '',
  facturas: '',
}

export default function reducer(state = initialState, action) {
  let ped = JSON.parse(JSON.stringify(state.pedido))

  switch (action.type) {
    case GENERA_PAGO_PASARELA:
    case FETCH_PEDIDO_ACTIVO:
    case INTEGRANDO_PEDIDO:
    case POST_PEDIDO:
      if (action.payload !== undefined) {
        return {
          ...state,
          pedido: action.payload,
          isLoading: false,
        }
      } else {
        return {
          ...state,
          isLoading: true,
        }
      }

    case FETCH_LINEAS_PEDIDO_ACTIVO:
      return {
        ...state,
        isLoadingLines: true,
      }

    case GENERA_PAGO_PASARELA_SUCCESS:
    case INTEGRAR_PEDIDO_FAIL:
    case FETCH_PEDIDO_ACTIVO_FAIL:
    case GET_INFO_PEDIDO_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case FETCH_LINEAS_PEDIDO_ACTIVO_FAIL:
      let nuevas_lineas = state.pedido.lineas.filter(
        linea => linea.numero_linea !== action.payload.linea.numero_linea,
      )

      action.payload.linea.cantidad_pedida = action.payload.cantidad_prev
      nuevas_lineas.push(action.payload.linea)
      return {
        ...state,
        pedido: { ...state.pedido, lineas: nuevas_lineas },
        isLoadingLines: false,
      }

    case GET_PEDIDO_ACTIVO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: '',
        num_pedido: '',
        numero_serie_pres: '',
        numero_pres: '',
        ejercicio: '',
        isLoading: false,
        isLoadingLines: false,
      }

    case POST_LINEA_PEDIDO:
      ped.lineas = [...ped.lineas, action.payload]
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      }

    case POST_LINEAS_PEDIDO:
      ped.lineas = ped.lineas.concat(action.payload)
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      }

    case DELETE_LINEA_PEDIDO:
      ped.lineas = ped.lineas.filter(linea => linea.id !== Number.parseInt(action.payload))
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      }

    case INTEGRAR_PEDIDO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      }

    case CERRAR_PEDIDO:
      return {
        ...state,
        pedido: {},
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      }

    case DELETE_PEDIDO:
      return {
        ...state,
        pedido: action.pedido_nuevo,
        isLoading: false,
      }

    case FETCH_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: {},
        isLoading: true,
      }

    case GET_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: action.payload,
        isLoading: false,
      }

    case SET_ID_PEDIDO_SELECCIONADO:
      return {
        ...state,
        id_pedido_seleccionado: action.payload,
      }

    case SET_OBSERVACIONES_PEDIDO:
      ped.observaciones = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_FECHA_ENTREGA:
      ped.fecha_entrega = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_NUMERO_PEDIDO_CLIENTE:
      ped.pedido_cliente = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_PERSONA_PEDIDO:
      ped.persona_pedido = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_NOMBRE_DOM_ENVIO_MOD:
      ped.nombre_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_DIRECCION_DOM_ENVIO_MOD:
      ped.direccion_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_LOCALIDAD_DOM_ENVIO_MOD:
      ped.localidad_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_ESTADO_DOM_ENVIO_MOD:
      ped.estado_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_PROVINCIA_DOM_ENVIO_MOD:
      ped.provincia_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_COD_POSTAL_DOM_ENVIO_MOD:
      ped.cod_postal_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_EMAIL_PEDIDO:
      ped.email = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case SET_TIPO_PORTES_DOM_ENVIO_MOD:
      ped.tipo_portes_dom_envio = action.payload
      return { ...state, pedido: ped, isLoading: false }

    case FETCH_INFO_PEDIDO:
      let pedido_info = JSON.parse(JSON.stringify(state.pedido.lineas))

      for (const linea of pedido_info) {
        linea.datos_articulo = []
      }
      return {
        ...state,
        pedido: { ...state.pedido, lineas: pedido_info },
      }

    case GET_INFO_PEDIDO:
      let pedido_nuevo = JSON.parse(JSON.stringify(state.pedido.lineas))
      pedido_nuevo.lineas = action.payload
      for (const linea_info of action.payload) {
        let index = -1
        index = pedido_nuevo.findIndex(
          linea_ped => linea_ped.articulo === linea_info.codigo_articulo,
        )

        if (index !== -1) {
          pedido_nuevo[index].datos_articulo.push(linea_info)
        }
      }
      return {
        ...state,
        pedido: { ...state.pedido, lineas: pedido_nuevo },
      }

    case NUEVO_PEDIDO_FAIL:
      return {
        ...state,
      }

    default:
      return state
  }
}
