import React, { Fragment } from "react";
import { getFiltrosPluginsInforme } from "../../actions/informes";
import { useDispatch, useSelector } from "react-redux";

const InformesMenu = (props) => {
  const dispatch = useDispatch();
  const informes = useSelector((state) => state.informes.informes);

  const onClick = (e) => {
    e.preventDefault();
    if (typeof e.target.dataset.informe !== "undefined") {
      dispatch(getFiltrosPluginsInforme(e.target.dataset.informe));
      props.setPlugginState(false);
    }
  };

  const cargaHijosMenu = (informe) => {
    if (informes.filter((inf) => inf.cod_padre === informe.codigo_menu).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0) {
      return (
        <div className="dropdown-menu">
          <div className="row tt-col-list">
            <div className="col">
              <ul className="tt-megamenu-submenu tt-sub-img">
                {informes.map((hijo, hijo_index) => {
                  if (hijo.cod_padre === informe.codigo_menu) {
                    return (
                      <li key={hijo_index}>
                        <a href="/#" data-informe={hijo.nombre_informe} onClick={onClick}>
                          {hijo.nombre}
                        </a>
                      </li>
                    );
                  }
                  return "";
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    } else return "";
  };

  return (
    <Fragment>
      <header className="menu-informes">
        <div className="tt-color-scheme-01">
          <div className="container">
            <div className="tt-header-holder">
              <div className="tt-obj-menu">
                <div className="tt-desctop-parent-menu tt-parent-box">
                  <div className="tt-desctop-menu tt-hover-02">
                    <nav>
                      <ul>
                        {informes && Array.isArray(informes)
                          ? informes.map((informe, index) => {
                              if (informe.nivel === "1") {
                                return (
                                  <li key={index} className="dropdown tt-megamenu-col-02 tt-submenu">
                                    <a href="/#" data-informe={informe.nombre_informe} onClick={onClick}>
                                      {informe.nombre}
                                    </a>
                                    {informes && Array.isArray(informes) ? cargaHijosMenu(informe) : ""}
                                  </li>
                                );
                              }
                              return "";
                            })
                          : ""}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default InformesMenu;
