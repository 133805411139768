import axios from "../axiosConfig";
import { FETCH_PEDIDOS_ANTERIORES, GET_PEDIDOS_ANTERIORES, SET_VER_PEDIDO, GENERAR_PEDIDO_PEDIDO_ANTERIOR, GET_PEDIDO_ACTIVO, GET_PEDIDO_ANTERIOR, FETCH_PEDIDO_ANTERIOR, FLUSH_PEDIDOS_ANTERIORES } from "./types";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getPedidosAnteriores = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDOS_ANTERIORES,
  });
  axios
    .get(`/pedidos/?anteriores=S`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PEDIDOS_ANTERIORES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineasPedido = (id_pedido) => (dispatch, getState) => {
  dispatch({
    type: SET_VER_PEDIDO,
    payload: id_pedido,
  });
};

export const generarPedidoPedidoAnterior = (pedido_anterior) => (dispatch, getState) => {
  dispatch({
    type: GENERAR_PEDIDO_PEDIDO_ANTERIOR,
  });
  axios
    .get(`/pedidos/generarpedido/?pedido_anterior=${pedido_anterior}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PEDIDO_ACTIVO,
        payload: res.data,
      });
      dispatch(createMessage("Se han añadido los artículos al pedido actual", "success"));
    })
    .catch((err) => {
      console.log(err);

      dispatch(createMessage("No se han podido añadir los artículos a su pedido en este momento.", err.response.status));
    });
};

export const getDatosLineasPedido = (pedido_anterior) => (dispatch, getState) => {
  let art_ped = [];
  for (let linea of pedido_anterior.lineas) {
    if (linea.datos_articulo && linea.datos_articulo.length > 0) {
      art_ped.push(linea.datos_articulo[0]);
    }
  }
  if (art_ped.length > 0) {
    dispatch({
      type: GET_PEDIDO_ANTERIOR,
      payload: art_ped,
    });
  }
};

export const limpiarDatosLineasPedido = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDO_ANTERIOR,
  });
};

export const limpiarDatosPedidosAnteriores = () => (dispatch, getState) => {
  dispatch({
    type: FLUSH_PEDIDOS_ANTERIORES,
  });
};
