import login from "../translations/login/pt_PT.json";
import informes from "../translations/informes/pt_PT.json";
import general from "../translations/general/pt_PT.json";
import resumen from "../translations/resumen/pt_PT.json";

export default {
  translation: {
    login,
    informes,
    general,
    resumen,
  },
};
