import axios from '../axiosConfig'

import {
  GET_ARTICULOS,
  FETCH_ARTICULOS,
  FETCH_ARTICULOS_BUSC_RAP,
  GET_MAS_ARTICULOS,
  CHANGE_ARTICULOS_PAGINA,
  SET_BUSQUEDA,
  CHANGE_ARTICULOS_ORDEN,
  FETCH_MAS_ARTICULOS,
  SET_ARTICULO_EQUIVALENTES,
  SET_BUSQUEDA_AVANZADA,
  LIMPIAR_BUSQUEDA_AVANZADA,
} from './types'

import { tokenConfig } from './auth'

let body

// GET ARTICULOS
export const getArticulos =
  (
    search = '',
    limit = 12,
    order = '',
    domicilio_envio = '',
    avanzada = 'N',
    codigo_articulo = '',
    codigo_barras = '',
    descripcion = '',
    palabras_clave = '',
    equivalentes = '',
    referencia_cliente = '',
    codigo_sinonimo = '',
    medida_1 = '',
    medida_2 = '',
    medida_3 = '',
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_ARTICULOS,
    })

    let url = `/articulos/?search=${search}&limit=${limit}&order=${order}&domicilio_envio=${domicilio_envio}`

    if (avanzada === 'S') {
      url =
        url +
        `&avanzada=${avanzada}&codigo_articulo=${codigo_articulo}&descripcion=${descripcion}&codigo_barras=${codigo_barras}&palabras_clave=${palabras_clave}&equivalentes=${equivalentes}&referencia_cliente=${referencia_cliente}&codigo_sinonimo=${codigo_sinonimo}&medida1=${medida_1}&medida2=${medida_2}&medida3=${medida_3}`
    }
    axios
      .get(url, tokenConfig(getState))
      .then(res => {
        //Añado el término de búsqueda a la respuesta
        //para poder guardarlo en el estado dentro del reducer
        res.data.search = search
        res.data.articulos_pagina = limit
        res.data.articulos_orden = order

        dispatch({
          type: GET_ARTICULOS,
          payload: res.data,
        })
      })
      .catch(err => console.log(err))
  }

export const getArticulosByFamily =
  (
    filtros_aplicados,
    limit = '',
    order = '',
    search = '',
    domicilio_envio = '',
    avanzada = 'N',
    codigo_articulo = '',
    codigo_barras = '',
    descripcion = '',
    palabras_clave = '',
    equivalentes = '',
    referencia_cliente = '',
    codigo_sinonimo = '',
    medida_1 = '',
    medida_2 = '',
    medida_3 = '',
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_ARTICULOS,
    })

    let url = `/articulos/listByFamily/?search=${search}&limit=${limit}&order=${order}&domicilio_envio=${domicilio_envio}`

    if (avanzada === 'S') {
      url =
        url +
        `&avanzada=${avanzada}&codigo_articulo=${codigo_articulo}&descripcion=${descripcion}&codigo_barras=${codigo_barras}&palabras_clave=${palabras_clave}&equivalentes=${equivalentes}&referencia_cliente=${referencia_cliente}&codigo_sinonimo=${codigo_sinonimo}&medida1=${medida_1}&medida2=${medida_2}&medida3=${medida_3}`
    }

    axios
      .post(
        url,
        {
          filtros: filtros_aplicados,
        },
        tokenConfig(getState),
      )
      .then(res => {
        res.data.search = search
        res.data.articulos_pagina = limit
        res.data.articulos_orden = order
        dispatch({
          type: GET_ARTICULOS,
          payload: res.data,
        })
      })
      .catch(err => console.log(err))
  }

// GET MÁS ARTÍCULOS
export const getMasArticulos =
  (url, filtros_aplicados = [], origen = 'articulos') =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_MAS_ARTICULOS,
      origen: origen,
    })

    if (filtros_aplicados.length === 0) {
      axios
        .post(url, tokenConfig(getState))
        .then(res => {
          dispatch({
            type: GET_MAS_ARTICULOS,
            payload: res.data,
            origen: origen,
          })
        })
        .catch(err => console.log(err))
    } else {
      axios
        .post(
          url,
          {
            filtros: filtros_aplicados,
          },
          tokenConfig(getState),
        )
        .then(res => {
          dispatch({
            type: GET_MAS_ARTICULOS,
            payload: res.data,
            origen: origen,
          })
        })
        .catch(err => console.log(err))
    }
  }

export const cambiaArticulosPagina = art_pagina => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_PAGINA,
    payload: art_pagina,
  })
}

export const setBusqueda = search => (dispatch, getState) => {
  dispatch({
    type: SET_BUSQUEDA,
    payload: search,
  })
}

export const cambiaArticulosOrden = orden => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_ORDEN,
    payload: orden,
  })
}

export const setArticuloEquivalentes = codigo_articulo => (dispatch, getState) => {
  dispatch({
    type: SET_ARTICULO_EQUIVALENTES,
    payload: codigo_articulo,
  })
}

export const setBusquedaAvanzada =
  (
    avanzada,
    palabras_clave,
    descripcion,
    codigo_articulo,
    codigo_barras,
    equivalentes,
    referencia_cliente,
    codigo_sinonimo,
    medida_1,
    medida_2,
    medida_3,
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_BUSQUEDA_AVANZADA,
      payload: {
        palabras_clave: palabras_clave,
        descripcion: descripcion,
        codigo_articulo: codigo_articulo,
        codigo_barras: codigo_barras,
        equivalentes: equivalentes,
        avanzada: avanzada,
        referencia_cliente: referencia_cliente,
        codigo_sinonimo: codigo_sinonimo,
        medida_1: medida_1,
        medida_2: medida_2,
        medida_3: medida_3,
      },
    })
  }

export const limpiarBusquedaAvanzada = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_BUSQUEDA_AVANZADA,
  })
}

export const limpiarListaArticulos = () => dispatch => {
  dispatch({
    type: FETCH_ARTICULOS_BUSC_RAP,
  })
}
