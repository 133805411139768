import React, { useEffect,useRef, Fragment } from "react";
import { getRutas, setRutaReparto } from "../../actions/rutas";
import i18n from "../../lang/idiomas";
import { useDispatch, useSelector } from "react-redux";
 
 
const Rutas = (props) => {
  const dispatch = useDispatch();
  const rutas = useSelector((state)=>state.rutas.rutas);
  const ruta = useSelector((state)=>state.rutas.ruta);
  const pedido_cargando = useSelector((state)=>state.pedidos.isLoading);
 
  useEffect(()=>{
    dispatch(getRutas());
  },[])
 
  useEffect(()=>{
    if (!pedido_cargando) {
      if (rutas?.length === 1) {
        dispatch(setRutaReparto(rutas[0]));
      }
    }
  },[rutas])
 
  const onChange = (e) => {
    if (e.target.value !== "") {
      const ruta_reparto = rutas.filter((ruta) => ruta.ruta === e.target.value);
      
      dispatch(setRutaReparto(ruta_reparto[0]));
    }
  };
 
  return (
    <Fragment>
      <div className="tt-shopcart-box">
        <h4 className="tt-title">{i18n.t("rutas.rrutdrep")}</h4>
        <p>{i18n.t("rutas.rselrure")}</p>
        <form className="form-default">
          <div className="form-group">
            <label htmlFor="ruta">
              {i18n.t("rutas.rrutrep")}
              <sup>*</sup>
            </label>
            {rutas.length > 0 ? (
              <select className="form-control" value={ruta?.ruta || ""} name="ruta" onChange={onChange} disabled={pedido_cargando || props.pedidoGuardado}>
                <option key="0" value="">
                  {i18n.t("rutas.rselruen")}
                </option>
                {rutas.map((ruta, index) => {
                    return (
                      <option key={index} value={ruta.ruta}>
                        {ruta.descripcion}
                      </option>
                    );
                })}
              </select>
            ) : (
              <p>{i18n.t("rutas.rnexruen")}</p>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
}
 
 
export default Rutas;