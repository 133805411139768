import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { recuperarPass, comprobarToken, reiniciarPass } from "../../actions/auth";

import queryString from "query-string";
import { ordenarMenu, validaPass } from "../../helpers/funciones";
import { createMessage } from "../../actions/messages";
import i18n from "../../lang/idiomas";

export class Recuperar extends Component {
  state = {
    username: "",
    token: "",
    nuevo_pass: "",
    re_nuevo_pass: "",
    error_iguales: false,
    error_vacios: false,
  };

  static propTypes = {
    recuperarPass: PropTypes.func.isRequired,
    comprobarToken: PropTypes.func.isRequired,
    reiniciarPass: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    irAInicio: PropTypes.bool,
    user: PropTypes.object,
    portal: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let params = queryString.parse(window.location.search);

    if (params.t) {
      this.setState({ token: params.t });
      this.props.comprobarToken(params.t);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.irAInicio && this.props.irAInicio !== prevProps.irAInicio) {
      let url_inicio = window.location.protocol + "//" + window.location.hostname;

      window.location.href = url_inicio;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (e.target.name === "recuperar") {
      this.props.recuperarPass(this.state.username);
    } else {
      const { nuevo_pass, re_nuevo_pass, token } = this.state;

      if (nuevo_pass !== "" && re_nuevo_pass !== "") {
        if (nuevo_pass !== re_nuevo_pass) {
          this.props.createMessage(i18n.t("usuarios.ucontnoi"), "error");
          this.setState({ error_iguales: true });
        } else if (!validaPass(nuevo_pass) || !validaPass(re_nuevo_pass)) {
          this.props.createMessage(i18n.t("usuarios.unocumre"), "error");
        } else {
          this.props.reiniciarPass(this.props.user.usuario_web, token, nuevo_pass);
        }
      } else {
        this.props.createMessage(i18n.t("usuarios.ucamnopv"), "error");
        this.setState({ error_vacios: true });
      }
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { username, nuevo_pass, re_nuevo_pass, error_iguales, error_vacios } = this.state;

    if (this.props.isAuthenticated && this.props.invitado === false && this.props.menus && this.props.menus.length > 0) {
      this.props.menus.sort(ordenarMenu);

      const irA = this.props.menus.find((menu) => menu.activo === "S" && menu.programa);

      if (irA) {
        return <Navigate to={"/" + irA.programa} />;
      } else {
        return "";
      }
    }

    let formulario;
    if (this.state.token !== "") {
      formulario = (
        <form name="cambiar" onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="nuevo_pass">{i18n.t("usuarios.unuecont")}</label>
            <input
              type="password"
              name="nuevo_pass"
              onChange={this.onChange}
              value={nuevo_pass}
              className={error_iguales || error_vacios ? "form-control error" : "form-control"}
              id="nuevo_pass"
              placeholder={i18n.t("usuarios.unueconm")}
              required
            />
            <p>{i18n.t("usuarios.ureqmin")}</p>
          </div>
          <div className="form-group">
            <label htmlFor="re_nuevo_pass">{i18n.t("usuarios.urepcont")}</label>
            <input
              type="password"
              name="re_nuevo_pass"
              onChange={this.onChange}
              value={re_nuevo_pass}
              className={error_iguales || error_vacios ? "form-control error" : "form-control"}
              id="re_nuevo_pass"
              placeholder={i18n.t("usuarios.urecontm")}
              required
            />
            <p>{i18n.t("usuarios.ureqmin")}</p>
          </div>
          <div className="row">
            <div className="col-auto">
              <div className="form-group">
                <button className="btn btn-border" type="submit">
                  {i18n.t("usuarios.ucambiar")}
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    } else {
      formulario = (
        <form name="recuperar" onSubmit={this.onSubmit}>
          <div className="form-group">
            <input className="form-control" type="text" name="username" onChange={this.onChange} value={username} placeholder="Usuario" />
          </div>
          <button type="submit" className="btn btn-primary">
            {i18n.t("usuarios.uenvi")}
          </button>
          <Link to="/" className="btn btn-danger ml-3">
            {i18n.t("usuarios.ucanc")}
          </Link>
          <p>
            {i18n.t("usuarios.unocuent")} <Link to="/register">{i18n.t("usuarios.uforregi")}</Link>
          </p>
        </form>
      );
    }

    return (
      <div id="tt-pageContent" className="recuperar-contrasena">
        <div className="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">{this.props.portal.descripcion}</h1>
            <div className="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-md-5">
                  <div className="tt-item">
                    <h2 className="tt-title">{i18n.t("usuarios.ureccon")}</h2>
                    <div className="form-default justify-content-center">
                      {this.state.token !== "" ? <p>{i18n.t("usuarios.uinnueco")}</p> : <p>{i18n.t("usuarios.uparecon")}</p>}

                      {formulario}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  irAInicio: state.auth.irAInicio,
  user: state.auth.user,
  portal: state.portal.portal,
  hash: state.auth.hash,
});

export default connect(mapStateToProps, {
  recuperarPass,
  comprobarToken,
  reiniciarPass,
  createMessage,
})(Recuperar);
