import Conversaciones from "../components/expedientes/Conversaciones";
import Login from "../components/usuarios/Login";
import Articulos from "../components/ecommerce/Articulos";
import Articulo from "../components/ecommerce/Articulo";
import Cuenta from "../components/usuarios/Cuenta";
import Informes from "../components/ecommerce/Informes";
import Resumen from "../components/ecommerce/Resumen";
import Registro from "../components/usuarios/Registro";
import Logout from "../components/usuarios/Logout";
import Recuperar from "../components/usuarios/Recuperar";
import ListasCompra from "../components/ecommerce/ListasCompra";
import Inicio from "../components/common/Inicio";
import Estatico from "../components/common/Estatico";
import Contacto from "../components/common/Contacto";
import PedidosAnteriores from "../components/ecommerce/PedidosAnteriores";
import PedidosPendientes from "../components/ecommerce/PedidosPendientes";
import PedidoHabitual from "../components/ecommerce/PedidoHabitual";
import NovedadesOfertas from "../components/ecommerce/NovedadesOfertas";
import Widgets from "../components/widgets/Widgets";
import RegistroCompleto from "../components/usuarios/RegistroCompleto";
import Pedido from "../components/ecommerce/Pedido";
import Home from "../components/ecommerce/Home";
import Wompi from "../components/ecommerce/Wompi";
import Cardnet from "../components/ecommerce/Cardnet";
import Modular from "../components/common/Modular";
import RegistroHijo from "../components/usuarios/RegistroHijo";
import EfacturaVentas from "../components/efactura/EfacturaVentas";
import EfacturaCompras from "../components/efactura/EfacturaCompras";
import Expedientes from "../components/expedientes/Expedientes";
import Plantillas from "../components/plantillas/Plantillas";
import ArticulosListItems from "../components/ecommerce/ArticulosListItems";

const programasRutas = {
  conversaciones: <Conversaciones />,
  login: <Login />,
  articulos: <Articulos />,
  articulo: <Articulo />,
  cuenta: <Cuenta />,
  informes: <Informes />,
  resumen: <Resumen />,
  registro: <Registro />,
  logout: <Logout />,
  recuperar: <Recuperar />,
  listasCompra: <ListasCompra />,
  inicio: <Inicio />,
  estatico: <Estatico />,
  contacto: <Contacto />,
  pedidosAnteriores: <PedidosAnteriores />,
  pedidosPendientes: <PedidosPendientes />,
  pedidoHabitual: <PedidoHabitual />,
  novedadesOfertas: <NovedadesOfertas />,
  widgets: <Widgets />,
  registroCompleto: <RegistroCompleto />,
  pedido: <Pedido />,
  home: <Home />,
  wompi: <Wompi />,
  cardnet: <Cardnet />,
  modular: <Modular />,
  registroHijos: <RegistroHijo />,
  efacturaVentas: <EfacturaVentas />,
  efacturaCompras: <EfacturaCompras />,
  expedientes: <Expedientes />,
  plantillas: <Plantillas />,
  articulosListItems: <ArticulosListItems />
};

export default programasRutas;
