import React, { useRef, useEffect, Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenus } from "../../actions/portal";
import { ordenarMenu, ProtectedRoute } from "../../helpers/funciones";
import { Helmet } from "react-helmet";
import Loader from "../layout/Loader";
import Articulo from "../ecommerce/Articulo";
import Resumen from "../ecommerce/Resumen";
import Recuperar from "../usuarios/Recuperar";
import Inicio from "./Inicio";
import Estatico from "./Estatico";
import Wompi from "../ecommerce/Wompi";
import Cardnet from "../ecommerce/Cardnet";
import Modular from "./Modular";
import ConfigCookies from "./ConfigCookies";
import i18n from "../../lang/idiomas";

import programasRutas from "../../helpers/programasRutas";

let loged;

const ControladorRutas = (props) => {
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.portal.menus);
  const portal = useSelector((state) => state.portal.portal);
  const isLoadingMenus = useSelector((state) => state.portal.isLoadingMenus);
  const isLoadingPortal = useSelector((state) => state.portal.isLoadingPortal);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const invitado = useSelector((state) => state.auth.invitado);
  const user = useSelector((state) => state.auth.user);

  const prevProps = useRef();
  useEffect(() => {
    prevProps.current = props;
  }, []);

  useEffect(() => {
    if (prevProps.current?.portal !== portal && portal.descripcion && !isLoadingMenus) {
      dispatch(getMenus());
    }
    prevProps.current = props;
  }, [props]);

  const iterar_menus = (menu) => {
    if (menu.programa) {
      const path = menu.programa === "articulos" ? "/articulos/:estadistico?/:familia?/:descripcion?" : `/${menu.programa}`;
      const element = programasRutas[menu.programa];
      return <Route key={menu.programa} exact path={path} element={element} />;
    } else {
      if (menu.hijos.length > 0) {
        return menu.hijos.map(iterar_menus);
      } else {
        return "";
      }
    }
  };

  let Rutainicio;
  let Componenteinicio;
  let menu_articulos;
  loged = authenticated && !invitado ? true : false;

  if (menus && menus.length > 0) {
    menus.sort(ordenarMenu);
    Rutainicio = menus.find((menu) => menu.activo === "S" && menu.programa && menu.programa.substring(0, 4) !== "http");

    menu_articulos = menus.find((menu) => menu.programa === "articulos");

    Componenteinicio = Rutainicio.programa;
  } else {
    Componenteinicio = null;
    menu_articulos = null;
  }

  if (portal.modo_mantenimiento === "S") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>{i18n.t("common.cpagmant")}</h1>
        <p>{i18n.t("common.cporfinm")}</p>
      </div>
    );
  }

  return (
    <Fragment>
      {menus && menus.length > 0 && portal.descripcion ? (
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={portal.parametros_seo.descripcion ? portal.parametros_seo.descripcion : portal.descripcion ? portal.descripcion : "Portal Ecommerce"} />
            <meta name="keywords" content={portal.parametros_seo.palabras_clave ? portal.parametros_seo.palabras_clave : ""} />
            <title>{portal.parametros_seo.titulo ? portal.parametros_seo.titulo : portal.descripcion ? portal.descripcion : "Portal Ecommerce"}</title>
            {portal.favicon ? <link rel="icon" type="image/png" href={"data:image/png;base64, " + portal.favicon} sizes="16x16" /> : ""}
          </Helmet>
          {isLoadingPortal ? (
            <Loader />
          ) : (
            <Routes>
              {Componenteinicio ? <Route key="inicio" exact path={"/"} element={<Inicio componente={Componenteinicio} />} /> : <Route key="no-inicio" />}
              {menus.map(iterar_menus)}

              <Route key="estatico" exact path="/estatico/:componente" element={<Estatico />} />
              <Route key="modular" exact path="/modular/:modulo" element={<Modular />} />
              <Route key="recuperar" exact path="/recuperar" element={<Recuperar />} />
              <Route
                key="resumen"
                exact
                path="/resumen"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Resumen />
                  </ProtectedRoute>
                }
              />
              <Route
                key="wompi"
                exact
                path="/wompi"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Wompi />
                  </ProtectedRoute>
                }
              />
              <Route key="cardnet" exact path="/cardnet" element={<Cardnet />} />
              <Route key="config_cookies" exact path="/configurarCookies" element={<ConfigCookies />} />

              {menu_articulos ? (
                <Route key={`articulo${Math.floor(Math.random() * 1000000)}`} exact path="/articulo/:id_articulo/:descrip_comercial" element={<Articulo />} />
              ) : (
                <Route
                  key={`articulo${Math.floor(Math.random() * 1000000)}`}
                  exact
                  path="/articulo/:id_articulo/:descrip_comercial"
                  element={
                    <ProtectedRoute isAllowed={loged}>
                      <Articulo />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route key="default" path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

export default ControladorRutas;
