import { CheckFat } from '@phosphor-icons/react'
import { ArrowFatLineLeft, CaretDown, CaretUp, Note, Trash } from 'phosphor-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { createMessage } from '../../actions/messages'
import { setCarnetProfesional, setLinea } from '../../actions/modalGenerica'
import {
  actualizaPedido,
  deleteLinea,
  eliminarPedido,
  getPedidoActivo,
  getTextosVentas,
  integraPedido,
  postLinea,
  postLineas,
} from '../../actions/pedidos'
import { getPrecios } from '../../actions/precios'
import { getPresentaciones } from '../../actions/presentaciones'
import {
  cargarArrayPrecios,
  cargarParametros,
  dynamicSort,
  obtenerValorParametro,
} from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'
import Spinner from '../layout/Spinner'
import Almacenes from './Almacenes'
import ArticuloNecesidades from './ArticuloNecesidades'
import BotonAnadirLista from './BotonAnadirLista'
import BuscadorRapido from './BuscadorRapido'
import Descripcion from './Descripcion'
import DomiciliosEnvio from './DomiciliosEnvio'
import EmailPedido from './EmailPedido'
import FormasEnvio from './FormasEnvio'
import FormasPago from './FormasPago'
import Imagen from './Imagen'
import NumeroPedidoCliente from './NumeroPedidoCliente'
import ObservacionesPedido from './ObservacionesPedido'
import PersonaPedido from './PersonaPedido'
import Precio from './Precio'
import Rutas from './Rutas'
import TarifasEnvioPortes from './TarifasEnvioPortes'
import TiposPedido from './TiposPedido'
import Transportistas from './Transportistas'

let parametros_usados = [
  'mostrar_iva',
  'tipo_precio',
  'andr_desc',
  'busc_rapido',
  'andr_a',
  'msg_tar_env',
  'tipo_msg_tar_env',
  'resumen_simple',
  'mostrar_pres',
  'estilo_precio',
]
let parametros

const Resumen = props => {
  const dispatch = useDispatch()
  const prevProps = useRef()

  const almacen_recogida = useSelector(state => state.almacenes.almacen)
  const carnets = useSelector(state => state.auth.user.carnets)
  const cookies = useSelector(state => state.portal.cookies)
  const domicilio_envio = useSelector(state => state.domiciliosEnvio.domicilio)
  const forma_envio = useSelector(state => state.formasEnvio.forma_envio)
  const forma_pago = useSelector(state => state.formasPago.forma_pago)
  const formas_pago = useSelector(state => state.formasPago.formas_pago)
  const hash = useSelector(state => state.auth.hash)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const lineas_pedido_cargando = useSelector(state => state.pedidos.isLoadingLines)
  const locale = useSelector(state => state.precios.locale)
  const moneda = useSelector(state => state.precios.moneda)
  const num_pedido = useSelector(state => state.pedidos.num_pedido)
  const numero_pres = useSelector(state => state.pedidos.numero_pres)
  const numero_serie_pres = useSelector(state => state.pedidos.numero_serie_pres)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const lineasModificadas = useSelector(state => state.pedidos.waitingUpdate)
  const portal = useSelector(state => state.portal.portal)
  const precio = useSelector(state => state.precios.precio)
  const ponerPrecioManual = useSelector(state => state.precios.permisos?.puede_modificar_precio)
  const ponerDtoManual = useSelector(state => state.precios.permisos?.puede_modificar_descuento)
  const ruta = useSelector(state => state.rutas.ruta)
  const serie_pedido = useSelector(state => state.pedidos.serie_pedido)
  const tipo_pedido = useSelector(state => state.tiposPedido.tipo_pedido)
  const transportista = useSelector(state => state.transportistas.transportista)
  const user = useSelector(state => state.auth.user)
  const presentaciones = useSelector(state => state.presentaciones.presentaciones)

  const [state, setState] = useState({
    volver: '',
    articulos_carrito: [],
    importe_pedido: null,
    moneda: null,
    guardado: false,
    ver_resumen: true,
    modificado: false,
  })

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'RES')
  let p_auto_crear_pedido = obtenerValorParametro(
    portal.parametros_ecom,
    'AUTO_CREAR_PEDIDO',
    'GEN',
  )

  useEffect(() => {
    dispatch(getTextosVentas(user['organizacion_comercial'], 'S', '01'))
    if (!pedido_cargando && !lineas_pedido_cargando) {
      dispatch(
        getPedidoActivo('', 'S', id_pedido_seleccionado || pedido.id_pedido, p_auto_crear_pedido),
      )
      if (pedido?.lineas?.length > 0) {
        let aux_precios = cargarArrayPrecios('resumen', pedido.lineas, precio)
        if (aux_precios.length > 0) {
          debugger
          dispatch(getPrecios(aux_precios, 'resumen', pedido.domicilio_envio, null, pedido.almacen))
        }
      }
    }
    prevProps.current = { pedido }
    setState({ ...state, guardado: false })
    return () => {
      dispatch(
        getPedidoActivo('', 'S', id_pedido_seleccionado || pedido.id_pedido, p_auto_crear_pedido),
      )
    }
  }, [])

  useEffect(() => {
    // Sacamos del carrito si este se queda sin lineas
    if (pedido?.lineas?.length === 0) {
      setState({ ...state, volver: 'S' })
    } else if (pedido?.lineas?.length > 0) {
      for (let lin of pedido.lineas) {
        if (!presentaciones[lin.articulo]) {
          // Si no están cargadas las posibles presentaciones del articulo las solicitamos
          dispatch(getPresentaciones(lin.articulo))
        }
      }
    }
  }, [pedido?.lineas])

  useEffect(() => {
    window.wookie()

    if (!pedido_cargando && !lineas_pedido_cargando) {
      // Iniciamos variables para eliminar los regalos del calculo
      if (!pedido?.id) {
        return
      }

      let lineas_pedido_actual_filtrado = pedido?.lineas.filter(linea => linea.tipo_linea !== 'R')
      let lineas_pedido_previo_filtrado = prevProps.current.pedido?.lineas.filter(
        linea => linea.tipo_linea !== 'R',
      )
      if (
        lineas_pedido_actual_filtrado &&
        lineas_pedido_previo_filtrado &&
        lineas_pedido_actual_filtrado.length !== lineas_pedido_previo_filtrado.length
      ) {
        // Si no coincide el tamaño de los arrays
        dispatch(
          getPedidoActivo('', 'S', id_pedido_seleccionado || pedido.id_pedido, p_auto_crear_pedido),
        )
      } else if (lineas_pedido_actual_filtrado?.length > 0) {
        let pido_lineas = false
        for (let linea of lineas_pedido_actual_filtrado) {
          //Si coincide buscamos que los articulos encajen
          let index = -1
          index = lineas_pedido_previo_filtrado.findIndex(
            linea_prev =>
              linea_prev.articulo === linea.articulo && linea_prev.tipo_linea === linea.tipo_linea,
          )

          if (index !== -1) {
            //Si coincide la linea
            if (
              linea.cantidad_pedida &&
              lineas_pedido_previo_filtrado[index].cantidad_pedida &&
              linea.cantidad_pedida !== lineas_pedido_previo_filtrado[index].cantidad_pedida
            ) {
              //Pedimos si no coincide la cantidad
              pido_lineas = true
              dispatch(
                getPedidoActivo(
                  '',
                  'S',
                  id_pedido_seleccionado || pedido.id_pedido,
                  p_auto_crear_pedido,
                ),
              )
            }
          } else {
            //Pedimos si no se encuentra el articulo previo
            pido_lineas = true
            dispatch(
              getPedidoActivo(
                '',
                'S',
                id_pedido_seleccionado || pedido.id_pedido,
                p_auto_crear_pedido,
              ),
            )
          }
        }
        if (!pido_lineas) {
          let aux_precios = cargarArrayPrecios('resumen', lineas_pedido_previo_filtrado, precio)
          if (aux_precios.length > 0) {
            dispatch(
              getPrecios(aux_precios, 'resumen', pedido.domicilio_envio, null, pedido.almacen),
            )
          }
        }
      }
    }
    prevProps.current = { pedido }
  }, [pedido_cargando, lineas_pedido_cargando, pedido])

  const changeQuantity = (e, linea) => {
    // Recuperamos el input
    let input = e.target.parentNode.getElementsByClassName('quantity-input')[0]

    let count = 0
    //Comprobamos si es input mobil o no
    if (e.currentTarget.className !== 'plus-btn' && e.currentTarget.className !== 'minus-btn') {
      count = parseInt(input.value)
    } else {
      count =
        parseInt(input.value, 10) + parseInt(e.currentTarget.className === 'plus-btn' ? 1 : -1, 10)
    }

    let value = Math.max(count, 1)
    if (parseInt(linea.cantidad_pedida) !== value) {
      let cantidad_prev = linea.cantidad_pedida
      linea.cantidad_pedida = value
      dispatch(
        postLinea(linea, hash, id_pedido_seleccionado || pedido.id_pedido, 'S', cantidad_prev),
      )
    }
  }

  const onChange = e => {
    setState({ ...state, observaciones: e.target.value })
  }

  const pedirConfirmacion = e => {
    e.preventDefault()
    let mensaje = ''
    let titulo = ''

    if (
      pedido?.lineas.some(linea => linea.tipo_linea === 'O') &&
      pedido?.lineas.some(linea => linea.tipo_linea === 'P')
    ) {
      titulo = i18n.t('resumen.resfnppt').toLocaleUpperCase()
      mensaje = i18n.t('resumen.resfnppm')
    } else if (
      pedido?.lineas.every(linea => linea.tipo_linea === 'O' || linea.tipo_linea === 'R')
    ) {
      titulo = i18n.t('resumen.resfnprt').toLocaleUpperCase()
      mensaje = i18n.t('resumen.resfnprm')
    } else if (
      pedido?.lineas.every(linea => linea.tipo_linea === 'P' || linea.tipo_linea === 'R')
    ) {
      titulo = i18n.t('resumen.resfnpdt').toLocaleUpperCase()
      mensaje = i18n.t('resumen.resfnpdm')
    }

    confirmAlert({
      title: titulo,
      message: mensaje,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: i18n.t('general.aceptar'),
          onClick: () => {
            integrarPedido()
          },
        },
        {
          label: i18n.t('general.cancelar'),
          onClick: () => {},
        },
      ],
    })
  }

  const integrarPedido = () => {
    if (comprobarPedido()) {
      dispatch(
        integraPedido(
          domicilio_envio,
          pedido.observaciones,
          forma_envio,
          almacen_recogida,
          ruta,
          forma_pago,
          pedido,
          'N',
          id_pedido_seleccionado || pedido.id_pedido,
          '',
          pedido.persona_pedido,
          pedido.pedido_cliente,
          {
            nombre_dom_envio: pedido.nombre_dom_envio,
            direccion_dom_envio: pedido.direccion_dom_envio,
            localidad_dom_envio: pedido.localidad_dom_envio,
            estado_dom_envio: pedido.estado_dom_envio,
            provincia_dom_envio: pedido.provincia_dom_envio,
            cod_postal_dom_envio: pedido.cod_postal_dom_envio,
            tipo_portes_dom_envio: pedido.tipo_portes_dom_envio,
          },
          pedido.email,
          transportista,
        ),
      )

      if (
        Object.keys(portal.parametros_seo).length > 0 &&
        cookies.acepta_cookies &&
        cookies.analiticas
      ) {
        let articulos_carrito = []
        pedido.lineas.forEach(linea => {
          articulos_carrito.push(linea.articulo)
        })

        let importe_pedido = Number.parseFloat(
          pedido.lineas
            .map(linea => {
              return linea.importe_neto_lin
            })
            .reduce((accumulator, currentValue) => accumulator + currentValue),
        ).toFixed(precio.decimales_pvp)

        let precioMoneda = precio.moneda

        setState({
          ...state,
          articulos_carrito: articulos_carrito,
          importe_pedido: importe_pedido,
          moneda: precioMoneda,
        })

        let analytics = new AnalyticsEdisa()
        analytics.registraEvento({
          modulo: 'Pedido',
          tipo: 'InitiateCheckout',
          valor: importe_pedido,
          params: {
            articulo: articulos_carrito,
            divisa: precioMoneda,
            precio: importe_pedido,
          },
        })
      }
    }
  }

  const comprobarPedido = () => {
    // Comprobamos si el pedido está cargando
    if (pedido_cargando) return false

    //Comprobamos la forma de envio
    if (!forma_envio.tipo) {
      dispatch(createMessage(i18n.t('resumen.resfalfe'), 'error'))

      // Comprobamos el domicilio de envio
    } else if (forma_envio.tipo === 'D' && !domicilio_envio.numero_direccion) {
      dispatch(createMessage(i18n.t('resumen.resfalde'), 'error'))

      //Comprobamos el lugar de recogida
    } else if (forma_envio.tipo === 'T' && !almacen_recogida.almacen) {
      dispatch(createMessage(i18n.t('resumen.resfallr'), 'error'))

      //Comprobamos la ruta
    } else if (forma_envio.tipo === 'R' && !ruta.almacen) {
      dispatch(createMessage(i18n.t('resumen.resfalrr'), 'error'))

      // Comprobamos la forma de pago
    } else if (formas_pago.length > 0 && !forma_pago.codigo_forma_pago_web) {
      dispatch(createMessage(i18n.t('resumen.resfalfp'), 'error'))
    } else {
      return true
    }
  }

  const handleEliminarPedido = e => {
    e.preventDefault()
    if (!pedido_cargando) {
      confirmAlert({
        title: i18n.t('carrito.crrbort').toUpperCase(),
        message: i18n.t('carrito.crrborm'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t('general.si'),
            onClick: () => {
              dispatch(
                eliminarPedido(pedido.id_pedido, '', id_pedido_seleccionado || pedido.id_pedido),
              )
              setState({ ...state, volver: 'S' })
            },
          },
          {
            label: i18n.t('general.no'),
          },
        ],
      })
    }
  }

  const eliminarLinea = e => {
    e.preventDefault()
    if (!pedido_cargando && !state.guardado) {
      const linea_seleccionada = e.currentTarget.dataset.linea
      confirmAlert({
        title: i18n.t('resumen.reselmat').toUpperCase(),
        message: i18n.t('resumen.reselmam'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t('general.si'),
            onClick: () =>
              dispatch(
                deleteLinea(linea_seleccionada, hash, id_pedido_seleccionado || pedido.id_pedido),
              ),
          },
          {
            label: i18n.t('general.no'),
          },
        ],
      })
    }
  }

  const mostrar_resumen = e => {
    e.preventDefault()
    state.ver_resumen
      ? setState({ ...state, ver_resumen: false, modificado: true })
      : setState({ ...state, ver_resumen: true, modificado: true })
  }

  const finalizar = () => {
    const info_pedido =
      serie_pedido && serie_pedido !== '' && num_pedido && num_pedido !== ''
        ? serie_pedido + '/' + num_pedido
        : ''

    const info_pres =
      numero_serie_pres && numero_serie_pres !== '' && numero_pres && numero_pres !== ''
        ? numero_serie_pres + '/' + numero_pres
        : ''

    let mensaje = ''
    let titulo = ''
    if (info_pedido !== '' && info_pres !== '') {
      mensaje =
        i18n.t('resumen.resmokf1') +
        info_pedido +
        i18n.t('resumen.resmokf2') +
        info_pres +
        i18n.t('resumen.resmokf3') +
        '\n' +
        i18n.t('resumen.resmokf4')
      titulo = i18n.t('resumen.restokf').toLocaleUpperCase()
    } else if (info_pedido !== '' && info_pres === '') {
      mensaje =
        i18n.t('resumen.resmokf1') +
        info_pedido +
        i18n.t('resumen.resmokf3') +
        '\n' +
        i18n.t('resumen.resmokf4')
      titulo = i18n.t('resumen.restokf1').toLocaleUpperCase()
    } else if (info_pedido === '' && info_pres !== '') {
      mensaje =
        i18n.t('resumen.resmokf0') +
        info_pres +
        i18n.t('resumen.resmokf3') +
        '\n' +
        i18n.t('resumen.resmokf4')
      titulo = i18n.t('resumen.restokf2').toLocaleUpperCase()
    }

    confirmAlert({
      title: titulo,
      message: mensaje,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: i18n.t('general.aceptar'),
          onClick: () => {
            let analytics = new AnalyticsEdisa()
            analytics.registraEvento({
              modulo: 'Pedido',
              tipo: 'Purchase',
              valor: state.importe_pedido,
              params: {
                articulos: state.articulos_carrito,
                divisa: state.moneda,
                precio: state.importe_pedido,
              },
            })
            setState({ ...state, volver: 'S' })
          },
        },
      ],
    })
  }

  const actualizarPedido = e => {
    e.preventDefault()

    if (comprobarPedido()) {
      dispatch(
        actualizaPedido(
          domicilio_envio,
          forma_envio,
          almacen_recogida,
          ruta,
          hash,
          tipo_pedido,
          id_pedido_seleccionado || pedido.id_pedido,
          '',
          pedido.observaciones,
          pedido.persona_pedido,
          pedido.pedido_cliente,
          {
            nombre_dom_envio: pedido.nombre_dom_envio,
            direccion_dom_envio: pedido.direccion_dom_envio,
            localidad_dom_envio: pedido.localidad_dom_envio,
            estado_dom_envio: pedido.estado_dom_envio,
            provincia_dom_envio: pedido.provincia_dom_envio,
            cod_postal_dom_envio: pedido.cod_postal_dom_envio,
            tipo_portes_dom_envio: pedido.tipo_portes_dom_envio,
          },
          pedido.email,
          transportista,
          forma_pago,
        ),
      )
      setState({ ...state, guardado: true })
    }
  }

  const modificarPedido = e => {
    e.preventDefault()
    setState({ ...state, guardado: false, ver_resumen: true, modificado: true })
  }

  const modificarObs = (linea, origen) => {
    dispatch(setLinea(linea, origen))
  }

  const devuelveDatosLineas = tipo_linea => {
    if (lineas_pedido_cargando) {
      return <Spinner showSpinner={lineas_pedido_cargando} />
    } else if (pedido?.lineas?.length > 0 && parametros) {
      return pedido.lineas
        .sort(dynamicSort('articulo'))
        .filter(linea => {
          return filtrarLineas(tipo_linea, linea)
        })
        .map(linea => {
          return (
            <div
              className='row linea-carrito'
              key={linea.id}>
              <div className='col-sm-12 col-md-2 col-lg-2 imagen'>{devuelveImagenLin(linea)}</div>
              <div className='col-sm-12 col-md-3 col-lg-3 descripcion'>
                {devuelveCodigo(linea)}
                {devuelveDescripcion(linea)}
                {devuelveNecesidades(linea)}
              </div>
              <div className='col-sm-12 col-md-2 col-lg-2 info-lin'>
                <>
                  {devuelveInfoCantidad(linea)}
                  {devuelveBotonObservaciones(linea)}
                </>
              </div>
              <div className='col-sm-12 col-md-1 col-lg-1 precio'>{devuelvePrecioLin(linea)}</div>
              <div className='col-sm-12 col-md-1 col-lg-1 descuento'>
                {devuelveDescuentoLin(linea)}
              </div>
              <div className='col-sm-12 col-md-2 col-lg-2 total'>
                {devuelveTotalLin(tipo_linea, linea)}
              </div>
              <div className='col-sm-12 col-md-1 col-lg-1 eliminar-lin'>
                {devuelveBotonesLinea(linea)}
              </div>
            </div>
          )
        })
    } else {
      return ''
    }
  }

  const devuelveLineas = tipo_linea => {
    return (
      <Fragment>
        <div className='row titulo-lineas-pedido'>
          <div className='col'>
            <h1 className='tt-title-subpages noborder'>
              {tipo_linea === 'presupuesto' ? i18n.t('general.presup') : i18n.t('general.pedido')}
            </h1>
          </div>
        </div>
        {(ponerPrecioManual || ponerDtoManual) && !state.guardado ? (
          <div className='row titulos-columnas'>
            <div className='col-7' />
            <div className='col-1'>{i18n.t('resumen.resprice')}</div>
            <div className='col-1'>{i18n.t('resumen.resdto')}</div>
            <div className='col-3' />
          </div>
        ) : (
          ''
        )}
        <div className={'lineas-' + tipo_linea + ' row tt-shopcart-table'}>
          <div className='col'>{devuelveDatosLineas(tipo_linea)}</div>
        </div>
      </Fragment>
    )
  }

  const filtrarLineas = (tipo_linea, linea) => {
    if (tipo_linea === 'presupuesto') {
      return (
        linea.tipo_linea === 'O' ||
        pedido.lineas.some(
          linea_padre =>
            linea_padre.numero_linea === linea.numero_linea_origen &&
            linea_padre.tipo_linea === 'O',
        )
      )
    } else if (tipo_linea === 'pedido') {
      return (
        linea.tipo_linea === 'P' ||
        pedido.lineas.some(
          linea_padre =>
            linea_padre.numero_linea === linea.numero_linea_origen &&
            linea_padre.tipo_linea === 'P',
        )
      )
    } else return false
  }

  const devuelveImagenLin = linea => {
    if (linea.tipo_articulo !== 'F') {
      return (
        <Link
          to={
            '/articulo/' +
            linea.articulo +
            '/' +
            (linea.descripcion
              ? linea.descripcion
                  .replaceAll('/', ' ')
                  .replaceAll(' ', '-')
                  .replaceAll('%', '')
                  .toLowerCase()
              : '')
          }>
          <div className='tt-product-img'>
            <Imagen
              codigo_articulo={linea.articulo}
              principal='S'
              numero_imagen={1}
              src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
            />
          </div>
        </Link>
      )
    } else {
      return (
        <div className='tt-product-img'>
          <Imagen
            codigo_articulo={linea.articulo}
            principal='S'
            numero_imagen={1}
            src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
          />
        </div>
      )
    }
  }

  const devuelveCodigo = linea => {
    if (linea.tipo_articulo !== 'F') {
      return (
        <div className='row desc-cod'>
          <Link
            to={
              '/articulo/' +
              linea.articulo +
              '/' +
              (linea.descripcion
                ? linea.descripcion
                    .replaceAll('/', ' ')
                    .replaceAll(' ', '-')
                    .replaceAll('%', '')
                    .toLowerCase()
                : '')
            }>
            {linea.articulo}
          </Link>
        </div>
      )
    } else {
      return <div className='row desc-cod'>{linea.articulo}</div>
    }
  }

  const devuelveDescripcion = linea => {
    if (linea.tipo_articulo !== 'F') {
      return (
        <div className='row desc-des'>
          <Link
            to={
              '/articulo/' +
              linea.articulo +
              '/' +
              (linea.descripcion
                ? linea.descripcion
                    .replaceAll('/', ' ')
                    .replaceAll(' ', '-')
                    .replaceAll('%', '')
                    .toLowerCase()
                : '')
            }>
            <Descripcion codigo_articulo={linea.articulo} />
          </Link>
        </div>
      )
    } else {
      return (
        <div className='row desc-des'>
          <Descripcion codigo_articulo={linea.articulo} />
        </div>
      )
    }
  }

  const devuelveNecesidades = linea => {
    return (
      <div className='row des-nec'>
        {linea.datos_articulo ? (
          <ArticuloNecesidades
            articulo={linea.datos_articulo}
            origen='resumen'
            carnets={carnets}
            user={user}
            setCarnetProfesional={setCarnetProfesional}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  const devuelveInfoCantidad = linea => {
    if (linea.sub_pres) {
      return linea.sub_pres_cant + ' X ' + linea.sub_pres
    } else {
      return (
        <div className='detach-quantity-desctope info-cantidad'>
          <div className='tt-input-counter style-01 div-cantidad-resumen'>
            <input
              className='quantity-input input-cantidad-resumen'
              type='text'
              defaultValue={Number.parseFloat(linea.cantidad_pedida).toFixed(0)}
              onBlur={e => {
                e.preventDefault()
                changeQuantity(e, linea)
              }}
              size='5'
              data-articulo={linea.articulo}
              data-presentacion_pedido={linea.presentacion_pedido}
              data-descripcion={linea.descripcion}
              readOnly={
                !(
                  linea.tipo_articulo !== 'R' &&
                  linea.tipo_articulo !== 'T' &&
                  linea.tipo_articulo !== 'F' &&
                  !state.guardado
                )
              }
            />
          </div>
        </div>
      )
    }
  }

  const devuelveBotonObservaciones = linea => {
    if (
      linea.tipo_articulo !== 'R' &&
      linea.tipo_articulo !== 'T' &&
      linea.tipo_articulo !== 'F' &&
      !state.guardado
    ) {
      return (
        <div className='botonera-mod'>
          <button
            className='btn modificar-obs'
            onClick={() => modificarObs(linea, 'lin-ped-obs')}>
            <Note
              size={20}
              alt={i18n.t('general.altaddob')}
            />
          </button>
        </div>
      )
    }
  }
  const devuelvePrecioLin = linea => {
    if (linea.tipo_linea !== 'R') {
      return (
        <Precio
          origen={ponerPrecioManual && !state.guardado ? 'resumen' : null}
          linea={ponerPrecioManual && !state.guardado ? linea : null}
          codigo_articulo={linea.articulo}
          presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
          precio_manual={Number.parseFloat(linea.importe_neto_lin)}
          mostrar_iva={parametros.mostrar_iva}
          mostrar_pres={ponerPrecioManual ? 'N' : parametros.mostrar_pres}
          tipo_precio={parametros.tipo_precio}
          estilo_precio={parametros.estilo_precio}
        />
      )
    } else {
      return (
        <Precio
          codigo_articulo={linea.articulo}
          presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
          precio_manual={0}
          mostrar_iva={parametros.mostrar_iva}
        />
      )
    }
  }

  const devuelveDescuentoLin = linea => {
    if (linea.tipo_linea !== 'R') {
      return (
        <Precio
          codigo_articulo={linea.articulo}
          origen={ponerDtoManual && !state.guardado ? 'resumen' : null}
          linea={ponerDtoManual && !state.guardado ? linea : null}
          presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
          precio_manual={Number.parseFloat(linea.dto_1 || 0)}
          tipo_precio={'descuento'}
          mostrar_iva={parametros.mostrar_iva}
          añadir_descuento={ponerDtoManual && !state.guardado ? null : parametros.andr_desc}
          mostrar_pres={'N'}
          estilo_precio={parametros.estilo_precio}
        />
      )
    } else {
      return ''
    }
  }

  const devuelveTotalLin = (tipo_linea, linea) => {
    if (tipo_linea === 'pedido') {
      return (
        <span className='tt-price subtotal'>
          {linea.tipo_linea !== 'R' ? (
            <Precio
              codigo_articulo={linea.articulo}
              presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
              precio_manual={Number.parseFloat(linea.importe_neto_lin)}
              mostrar_iva={parametros.mostrar_iva}
              mostrar_pres={linea.precio_manual === 'S' ? 'N' : parametros.mostrar_pres}
              estilo_precio={parametros.estilo_precio}
            />
          ) : (
            <Precio
              codigo_articulo={linea.articulo}
              presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
              precio_manual={0}
              mostrar_iva={parametros.mostrar_iva}
            />
          )}
        </span>
      )
    }
  }

  const devuelveBotonesLinea = linea => {
    if (
      linea.tipo_articulo !== 'R' &&
      linea.tipo_articulo !== 'T' &&
      linea.tipo_articulo !== 'F' &&
      !state.guardado
    ) {
      return (
        <>
          <button
            className='btn-borrar-linea'
            data-linea={linea.id}
            onClick={eliminarLinea}>
            <Trash size={20} />
            <span className='ocultar'>{i18n.t('general.cerrar')}</span>
          </button>
        </>
      )
    }
  }

  let es_pedido = true
  let es_presupuesto = false
  if (
    pedido &&
    Object.keys(pedido).length > 0 &&
    pedido.lineas &&
    pedido.lineas.some(linea => linea.tipo_linea === 'P')
  ) {
    es_pedido = true
  } else {
    es_pedido = false
  }

  if (
    pedido &&
    Object.keys(pedido).length > 0 &&
    pedido.lineas &&
    pedido.lineas.some(linea => linea.tipo_linea === 'O')
  ) {
    es_presupuesto = true
  } else {
    es_presupuesto = false
  }

  if (parametros.resumen_simple === 'S' && state.modificado === false) {
    if (!state.guardado || !state.ver_resumen) {
      // Si el parametro es S saltamos el 1er paso
      setState({ ...state, guardado: true, ver_resumen: true })
    }
  }
  const { volver } = state
  if (
    ((!pedido?.lineas || pedido.lineas.length === 0) &&
      num_pedido === '' &&
      serie_pedido === '' &&
      numero_pres === '' &&
      numero_serie_pres === '') ||
    volver === 'S'
  ) {
    return <Navigate to='/' />
  } else if (
    Object.keys(pedido).length === 0 &&
    ((num_pedido !== '' && serie_pedido !== '') || (numero_pres !== '' && numero_serie_pres !== ''))
  ) {
    finalizar()
  } else if (Object.keys(pedido).length !== 0 && num_pedido !== '' && serie_pedido !== '') {
    if (forma_pago.pasarela && forma_pago.pasarela !== '') {
      switch (forma_pago.pasarela) {
        case 'WOMPI':
          return (
            <Navigate
              to={{
                pathname: '/wompi',
                state: {
                  articulos_carrito: state.articulos_carrito,
                  moneda: state.moneda,
                  importe_pedido: state.importe_pedido,
                },
              }}
            />
          )
        case 'CARDNET':
          return (
            <Navigate
              to={{
                pathname: '/cardnet',
                state: {
                  articulos_carrito: state.articulos_carrito,
                  moneda: state.moneda,
                  importe_pedido: state.importe_pedido,
                },
              }}
            />
          )

        default:
          break
      }
    }
  }

  const devuelveBuscador = () => {
    if (pedido && !state.guardado && parametros.busc_rapido && parametros.busc_rapido !== 'N') {
      return (
        <div className='row buscador-carrito'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <BuscadorRapido añadir_a={parametros.andr_a} />
          </div>
        </div>
      )
    }
  }

  const devuelveTotales = () => {
    if (!pedido_cargando && !lineas_pedido_cargando) {
      debugger
      return (
        <div className='row totales'>
          <div className='col'>
            <div className='row'>
              <div className='col-5' />
              <div className='col-2 actualizar-lineas'>
                {lineasModificadas ? (
                  <button
                    className='btn-link actualizar-lineas'
                    style={{ display: 'flex' }}
                    onClick={e => {
                      e.preventDefault()
                      dispatch(postLineas(pedido.lineas, hash, pedido, null))
                    }}>
                    <CheckFat size={20} />
                    <span>{i18n.t('carrito.crractu').toUpperCase()}</span>
                  </button>
                ) : (
                  ''
                )}
              </div>
              <div className='col-2 titulo'>{i18n.t('general.total').toUpperCase()}</div>
              <div className='col-2 total'>
                {pedido.lineas === undefined || pedido.lineas.length === 0
                  ? 0
                  : new Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: portal.moneda,
                    }).format(
                      Number.parseFloat(
                        pedido.lineas
                          .filter(linea => linea.tipo_linea === 'P')
                          .map(linea => {
                            return Number.parseFloat(linea.importe_neto_lin)
                          })
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
                      ).toFixed(precio.decimales_pvp),
                    )}
              </div>
              <div className='col-1'></div>
            </div>
            <div className='row'>
              <div className='col-7'></div>
              <div className='col-2 titulo'>
                {i18n.t('general.total').toUpperCase() + ' + ' + i18n.t('general.impuesto')}
              </div>
              <div className='col-2 total'>
                {pedido.lineas === undefined || pedido.lineas.length === 0
                  ? 0
                  : new Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: portal.moneda,
                    }).format(
                      Number.parseFloat(
                        pedido.lineas
                          .filter(linea => linea.tipo_linea === 'P')
                          .map(linea => {
                            return Number.parseFloat(linea.importe_neto_lin_impuestos)
                          })
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
                      ).toFixed(precio.decimales_pvp),
                    )}
              </div>
              <div className='col-1'></div>
            </div>
          </div>
        </div>
      )
    }
  }

  const devuelveTextoBoton = (es_pedido, es_presupuesto) => {
    if (es_pedido && es_presupuesto) {
      return i18n.t('general.pedyprp').toUpperCase()
    } else if (es_pedido && !es_presupuesto) {
      return i18n.t('general.pedido').toUpperCase()
    } else if (!es_pedido && es_presupuesto) {
      return i18n.t('general.presup').toUpperCase()
    } else {
      return i18n.t('general.carrito').toUpperCase()
    }
  }

  return (
    <Fragment>
      <div className='container'>
        <div id='tt-pageContent'>
          <div className='container-indent'>
            {pedido_cargando || parametros === undefined || parametros === null ? (
              <Spinner showSpinner={pedido_cargando} />
            ) : (
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <>
                      {devuelveBuscador()}
                      {pedido && Object.keys(pedido).length > 0 && pedido.lineas ? (
                        pedido.lineas.some(linea => linea.tipo_linea === 'P') ? (
                          <>
                            {devuelveLineas('pedido')}
                            {devuelveTotales()}
                            <TarifasEnvioPortes
                              mostrar_mensaje={parametros.msg_tar_env}
                              tipo_tarifa={parametros.tipo_msg_tar_env}
                            />
                          </>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}

                      {pedido &&
                      Object.keys(pedido).length > 0 &&
                      pedido?.lineas.some(linea => linea.tipo_linea === 'O') ? (
                        <Fragment>{devuelveLineas('presupuesto')}</Fragment>
                      ) : (
                        ''
                      )}
                    </>
                  </div>
                </div>
                <div className='row tt-shopcart-btn'>
                  <div className='col-sm-12 col-md-4 col-lg-4 continuar'>
                    <Link
                      to='/articulos'
                      className='btn-link'>
                      <ArrowFatLineLeft size={20} />
                      <span>{i18n.t('general.contcomp').toUpperCase()}</span>
                    </Link>
                  </div>
                  <div className='col-sm-12 col-md-4 col-lg-4 borrar'>
                    <button
                      className='btn-link'
                      onClick={handleEliminarPedido}>
                      <Trash size={20} />
                      <span>{i18n.t('carrito.crrbort').toUpperCase()}</span>
                    </button>
                  </div>
                  <div className='col-sm-12 col-md-4 col-lg-4 añadir'>
                    {pedido.lineas &&
                    pedido.lineas.filter(linea => linea.tipo_linea === 'P').length > 0 ? (
                      <BotonAnadirLista
                        origen='resumen'
                        lineas={pedido.lineas.filter(linea => linea.tipo_linea === 'P')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='tt-shopcart-wrapper'>
                      <div
                        className='row mostrar_resumen_btn'
                        style={state.guardado ? { display: 'block' } : { display: 'none' }}>
                        {state.ver_resumen ? (
                          <button
                            className='btn-link mostrar-res'
                            onClick={mostrar_resumen}>
                            <span>{i18n.t('resumen.resocurs')}</span>
                            <CaretUp
                              size={18}
                              weight='bold'
                            />
                          </button>
                        ) : (
                          <button
                            className='btn-link mostrar-res'
                            onClick={mostrar_resumen}>
                            <span>{i18n.t('resumen.resmosrs')}</span>
                            <CaretDown
                              size={18}
                              weight='bold'
                            />
                          </button>
                        )}
                      </div>
                      <div
                        className='row'
                        style={
                          state.guardado && !state.ver_resumen
                            ? { display: 'none' }
                            : { display: 'block' }
                        }>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <TiposPedido pedidoGuardado={state.guardado} />
                        </div>
                      </div>
                      <div
                        className='row'
                        style={
                          state.guardado && !state.ver_resumen
                            ? { display: 'none' }
                            : { display: 'block' }
                        }>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                          <FormasEnvio pedidoGuardado={state.guardado} />
                        </div>

                        {forma_envio?.tipo && forma_envio.tipo === 'D' ? (
                          <div className='col-sm-12 col-md-6 col-lg-6'>
                            <Transportistas pedidoGuardado={state.guardado} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className='row'
                        style={
                          state.guardado && !state.ver_resumen
                            ? { display: 'none' }
                            : { display: 'block' }
                        }>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          {forma_envio?.tipo && forma_envio.tipo === 'D' ? (
                            <DomiciliosEnvio
                              editable={true}
                              pedidoGuardado={state.guardado}
                            />
                          ) : (
                            ''
                          )}

                          {forma_envio?.tipo && forma_envio.tipo === 'T' ? (
                            <Almacenes pedidoGuardado={state.guardado} />
                          ) : (
                            ''
                          )}
                          {forma_envio?.tipo && forma_envio.tipo === 'R' ? (
                            <Rutas pedidoGuardado={state.guardado} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div
                        className='row'
                        style={
                          state.guardado && !state.ver_resumen
                            ? { display: 'none' }
                            : { display: 'block' }
                        }>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <FormasPago pedidoGuardado={state.guardado} />
                        </div>
                      </div>

                      {pedido && Object.keys(pedido).length > 0 ? (
                        <div
                          className='row'
                          style={
                            state.guardado && !state.ver_resumen
                              ? { display: 'none' }
                              : { display: 'block' }
                          }>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='tt-shopcart-box'>
                              <h4 className='tt-title'>{i18n.t('resumen.resdat')}</h4>
                              <p>{i18n.t('resumen.resdam')}</p>
                              <div
                                className='row'
                                style={
                                  state.guardado && !state.ver_resumen
                                    ? { display: 'none' }
                                    : { display: 'block' }
                                }>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <PersonaPedido pedidoGuardado={state.guardado} />
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <NumeroPedidoCliente pedidoGuardado={state.guardado} />
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <EmailPedido pedidoGuardado={state.guardado} />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <ObservacionesPedido pedidoGuardado={state.guardado} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className='tt-shopcart-box tt-boredr-large'>
                        {pedido_cargando ? (
                          <Spinner showSpinner={pedido_cargando} />
                        ) : (
                          <>
                            {state.guardado ? (
                              <Fragment>
                                <button
                                  className='btn btn-lg boton-pedido'
                                  onClick={modificarPedido}>
                                  <span className='icon icon-check_circle'></span>
                                  {i18n.t('general.modificr').toUpperCase()}{' '}
                                  {devuelveTextoBoton(es_pedido, es_presupuesto)}
                                </button>

                                <button
                                  className='btn btn-lg boton-pedido'
                                  onClick={pedirConfirmacion}>
                                  <span className='icon icon-check_circle'></span>
                                  {i18n.t('general.finalizr').toUpperCase()}{' '}
                                  {devuelveTextoBoton(es_pedido, es_presupuesto)}
                                </button>
                              </Fragment>
                            ) : (
                              <button
                                className='btn btn-lg boton-pedido'
                                onClick={actualizarPedido}>
                                <span className='icon icon-check_circle'></span>
                                {i18n.t('general.guarcamb').toUpperCase()}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Resumen
