import login from "../translations/login/gl_ES.json";
import informes from "../translations/informes/gl_ES.json";
import general from "../translations/general/gl_ES.json";
import articulos from "../translations/articulos/gl_ES.json";
import resumen from "../translations/resumen/gl_ES.json";
import subscripciones from "../translations/subscripciones/gl_ES.json";
import carrito from "../translations/carrito/gl_ES.json";
import buscador from "../translations/buscador/gl_ES.json";
import expedientes from "../translations/expedientes/gl_ES.json";

export default {
  translation: {
    login,
    informes,
    general,
    articulos,
    resumen,
    subscripciones,
    carrito,
    buscador,
    expedientes,
  },
};
