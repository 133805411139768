import React, { Fragment } from "react";
import ListadoArticulos from "./ListadoArticulos";

const ArticulosRelacionados = (props) => {
  return (
    <Fragment>
      <ListadoArticulos origen="relacionados" mostrar={"lista"} id_articulo={props.id_articulo} />
    </Fragment>
  );
};

export default ArticulosRelacionados;
