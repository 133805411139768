import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { confirmAlert } from "react-confirm-alert";
import { generaPagoCardnet } from "../../actions/cardnet";
import i18n from "../../lang/idiomas";

var this_componente;

export class Cardnet extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    forma_pago: PropTypes.object.isRequired,
    moneda: PropTypes.string.isRequired,
    num_pedido: PropTypes.string.isRequired,
    serie_pedido: PropTypes.string.isRequired,
    ejercicio: PropTypes.string.isRequired,
    numero_pres: PropTypes.string.isRequired,
    numero_serie_pres: PropTypes.string.isRequired,
    generaPagoCardnet: PropTypes.func.isRequired,
  };

  state = {
    volver: "",
    error: false,
  };

  constructor(props) {
    super(props);
    this_componente = this;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://lab.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js?key=" + this.props.forma_pago.parama_1;
    document.body.appendChild(script);
  }

  OnTokenReceived(token) {
    this_componente.props.generaPagoCardnet(token.TokenId, this_componente.props.pedido.id_pedido);
  }

  myCheckoutFunction(pedido) {
    window.PWCheckout.Bind("tokenCreated", this.OnTokenReceived);
    window.PWCheckout.SetProperties({
      name: "Pago Cardnet",
      email: pedido.email,
      //"image": "http://mywebsitedomain.com/images/logocheckout.png",
      "button-label": "Pagar " + pedido.importe_cobrado,
      description: "Pago pedido Nº: " + pedido.id_pedido,
      currency: "$",
      amount: pedido.importe_cobrado,
      lang: "ESP",
      form_id: "shoppingcart_form",
      autoSubmit: "false",
      checkout_card: 1,
      empty: "false",
    });

    window.PWCheckout.OpenIframeNormal();
  }

  finalizar = () => {
    const info_pedido = this.props.serie_pedido && this.props.serie_pedido !== "" && this.props.num_pedido && this.props.num_pedido !== "" ? this.props.serie_pedido + "/" + this.props.num_pedido : "";
    confirmAlert({
      title: "PEDIDO REALIZADO",
      message: "Estamos verificando el pago del pedido : " + info_pedido + ", puede continuar navegando.",
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: "Aceptar",
          onClick: () => {
            let analytics = new AnalyticsEdisa();
            analytics.registraEvento({
              modulo: "Pedido",
              tipo: "Purchase",
              valor: this.props.location.state.importe_pedido,
              params: {
                articulos: this.props.location.state.articulos_carrito,
                divisa: this.props.location.state.moneda,
                precio: this.props.location.state.importe_pedido,
              },
            });
            this.setState({ volver: "S" });
          },
        },
      ],
    });
  };

  render() {
    if (
      (Object.keys(this.props.pedido).length === 0 && this.props.num_pedido === "" && this.props.serie_pedido === "" && this.props.numero_pres === "" && this.props.numero_serie_pres === "") ||
      this.state.volver === "S"
    ) {
      return <Navigate to="/" />;
    } else if (
      Object.keys(this.props.pedido).length === 0 &&
      ((this.props.num_pedido !== "" && this.props.serie_pedido !== "") || (this.props.numero_pres !== "" && this.props.numero_serie_pres !== ""))
    ) {
      this.finalizar();
    } else if (this.state.error) {
      return (
        <Fragment>
          <div className="container">
            <div id="tt-pageContent" className="articulo">
              <div className="container-indent">
                <div className="container container-fluid-mobile">
                  <h1 className="tt-title-subpages noborder">{i18n.t("cardnet.procpago")}</h1>
                  <div className="row">
                    <div className="col">
                      <p>{i18n.t("cardnet.pagfalde")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="container">
            <div id="tt-pageContent" className="articulo">
              <div className="container-indent">
                <div className="container container-fluid-mobile">
                  <h1 className="tt-title-subpages noborder">{i18n.t("cardnet.propagom")}</h1>
                  <div className="row">
                    <div className="col">
                      {this.props.pedido_cargando ? <Spinner showSpinner={this.props.pedido_cargando} /> : ""}
                      <form id="shoppingcart_form">
                        <p>{i18n.t("cardnet.decomsfi")}</p>
                        <p>
                          <button
                            id="btnCheckout"
                            class="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              this.myCheckoutFunction(this.props.pedido);
                            }}
                          >
                            {i18n.t("cardnet.proapago")}
                          </button>
                        </p>
                        <p>
                          <input type="hidden" id="PWToken" name="PWToken" />
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  forma_pago: state.formasPago.forma_pago,
  moneda: state.precios.moneda,
  num_pedido: state.pedidos.num_pedido,
  serie_pedido: state.pedidos.serie_pedido,
  ejercicio: state.pedidos.ejercicio,
  numero_pres: state.pedidos.numero_pres,
  numero_serie_pres: state.pedidos.numero_serie_pres,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  pedido_cargando: state.pedidos.isLoading,
});

export default connect(mapStateToProps, { generaPagoCardnet })(Cardnet);
