import React, { Component, Fragment } from "react";
import i18n from "../../lang/idiomas";

let id;
let elemento;
export class InfromesFiltroField extends Component {
  state = {
    startValue:
      !Array.isArray(this.props.filtro.valor_inicial_filtro) && this.props.filtro.valor_inicial_filtro
        ? this.props.filtro.data_type === "NUMBER"
          ? Number(this.props.filtro.valor_inicial_filtro)
          : this.props.filtro.valor_inicial_filtro
        : this.props.filtro.valor_inicial_filtro_desde && !Array.isArray(this.props.filtro.valor_inicial_filtro_desde)
        ? this.props.filtro.data_type === "NUMBER"
          ? Number(this.props.filtro.valor_inicial_filtro_desde)
          : this.props.filtro.valor_inicial_filtro_desde
        : null,
    endValue:
      !Array.isArray(this.props.filtro.valor_inicial_filtro_hasta) && this.props.filtro.valor_inicial_filtro_hasta
        ? this.props.filtro.data_type === "NUMBER"
          ? Number(this.props.filtro.valor_inicial_filtro_hasta)
          : this.props.filtro.valor_inicial_filtro_hasta
        : null,
  };

  onClick = (e) => {
    e.preventDefault();
    if (e.target.dataset.informe !== "") {
      this.props.getFiltrosPluginsInforme(e.target.dataset.informe);
    }
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      startValue: e.currentTarget.value,
    });
    // Seteamos elemento
    id = this.props.filtro.titulo_columna;
    elemento = document.getElementById(id);
    if (elemento) {
      elemento.style.border = "unset";
    }
  };

  onChangeHasta = (e) => {
    e.preventDefault();
    this.setState({
      endValue: e.currentTarget.value,
    });
    // Seteamos elemento HASTA
    id = this.props.filtro.titulo_columna + "_HASTA";
    elemento = document.getElementById(id);
    if (elemento) {
      elemento.style.border = "unset";
    }
  };

  onChangeDesde = (e) => {
    e.preventDefault();
    this.setState({
      startValue: e.currentTarget.value,
    });

    // Seteamos elemento DESDE
    id = this.props.filtro.titulo_columna + "_DESDE";
    elemento = document.getElementById(id);
    if (elemento) {
      elemento.style.border = "unset";
    }
  };

  onBlur = (e) => {
    e.preventDefault();
    id = this.props.filtro.titulo_columna;

    if (this.props.filtro.obligatorio === "S" && this.props.filtro.data_type === "NUMBER") {
      if (e.currentTarget.id === id + "_DESDE") {
        if (Number(this.state.startValue) > Number(this.state.endValue)) {
          this.setState({
            endValue: this.state.startValue,
          });
        }
      }
      if (e.currentTarget.id === id + "_HASTA") {
        if (Number(this.state.endValue) < Number(this.state.startValue)) {
          this.setState({
            startValue: this.state.endValue,
          });
        }
      }
    }
  };

  render() {
    if (this.props.filtro.tipo_filtro === "I") {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  id={this.props.filtro.titulo_columna + "_DESDE"}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro + "_DESDE"}
                  onChange={this.onChangeDesde}
                  onBlur={this.onBlur}
                  placeholder={this.props.filtro.titulo_columna + " " + i18n.t("general.desde")}
                  required={this.props.filtro.obligatorio === "S"}
                  value={this.state.startValue}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  id={this.props.filtro.titulo_columna + "_HASTA"}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro + "_HASTA"}
                  onChange={this.onChangeHasta}
                  onBlur={this.onBlur}
                  placeholder={this.props.filtro.titulo_columna + " " + i18n.t("general.hasta")}
                  required={this.props.filtro.obligatorio === "S"}
                  value={this.state.endValue}
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  id={this.props.filtro.titulo_columna}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro}
                  onChange={this.onChange}
                  placeholder={this.props.filtro.titulo_columna}
                  required={this.props.filtro.obligatorio === "S"}
                  defaultValue={!Array.isArray(this.props.filtro.valor_inicial_filtro) && this.props.filtro.valor_inicial_filtro ? this.props.filtro.valor_inicial_filtro : ""}
                  value={this.state.startValue}
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default InfromesFiltroField;
