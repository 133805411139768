import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getOfertas } from '../../actions/ofertas'
import ListadoArticulos from './ListadoArticulos'

export class Ofertas extends Component {
  static propTypes = {
    getOfertas: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Fragment>
        <div className='container-indent'>
          <div className='container-fluid-custom container-fluid-custom-mobile-padding'>
            <div className='titulo'>
              <h2>Ofertas</h2>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <ListadoArticulos
                  mostrar='lista'
                  origen='ofertas'
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { getOfertas })(Ofertas)
