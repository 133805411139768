import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPrecio } from '../../actions/precios'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'

const Precio = props => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const precio = useSelector(state => state.precios.precio)
  const decimales_precios = useSelector(state => state.precios.decimales_precios)
  const decimales_pvp = useSelector(state => state.precios.decimales_pvp)
  const decimales_significativos = useSelector(state => state.precios.decimales_significativos)
  const locale = useSelector(state => state.precios.locale)
  const auth = useSelector(state => state.auth)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const pedido = useSelector(state => state.pedidos.pedido)
  const presentaciones = useSelector(state => state.presentaciones.presentaciones)
  const prevProps = useRef()

  useEffect(() => {
    // Comprobamos si el precio es para la ficha, y si lo es lo volvemos a pedir (para garantizar la mayor fiabilidad posible)
    if (
      props?.codigo_articulo &&
      props?.origen === 'ficha' &&
      precio[props.codigo_articulo] === undefined
    ) {
      if (props.presentacion && presentaciones?.[props.codigo_articulo]?.[props.presentacion]) {
        if (props.codigo_articulo) {
          dispatch(
            getPrecio(
              props.codigo_articulo,
              1,
              id_pedido_seleccionado,
              pedido.domicilio_envio,
              props.presentacion,
              pedido.almacen,
            ),
          )
        }
      }
    }
    prevProps.current = {
      portal,
      precio,
      locale,
      auth,
      id_pedido_seleccionado,
      pedido,
    }
    prevProps.current.codigo_articulo = props.codigo_articulo
  }, [])

  useEffect(() => {
    if (
      props?.codigo_articulo &&
      props?.origen === 'ficha' &&
      precio[props.codigo_articulo] === undefined &&
      prevProps.current.codigo_articulo !== props.codigo_articulo
    ) {
      if (props.presentacion && presentaciones?.[props.codigo_articulo]?.[props.presentacion]) {
        if (props.codigo_articulo) {
          dispatch(
            getPrecio(
              props.codigo_articulo,
              1,
              id_pedido_seleccionado,
              pedido.domicilio_envio,
              props.presentacion,
              pedido.almacen,
            ),
          )
        }
      }
    }
    prevProps.current = {
      portal,
      precio,
      locale,
      auth,
      id_pedido_seleccionado,
      pedido,
    }
    prevProps.current.codigo_articulo = props.codigo_articulo
  }, [props.codigo_articulo])

  const mostrarPresentacion = () => {
    return (
      <>
        {precio?.[props.codigo_articulo]?.[props.presentacion] && props.mostrar_pres !== 'N'
          ? '/' + props.presentacion
          : ''}
      </>
    )
  }

  const mostrarImpuestos = () => {
    return (
      <>
        {!Array.isArray(precio[props.codigo_articulo]) &&
        precio[props.codigo_articulo][props.presentacion].impuesto_articulo !== null &&
        props.mostrar_iva !== 'N'
          ? ' + ' + precio[props.codigo_articulo][props.presentacion].impuesto_articulo + '% IVA'
          : ''}
      </>
    )
  }

  const obtenerImpuesto = () => {
    if (precio?.[props.codigo_articulo]?.[props.presentacion]?.impuesto_articulo) {
      let impuesto = Number.parseFloat(
        precio[props.codigo_articulo][props.presentacion].impuesto_articulo,
      ).toFixed(decimales_pvp)
      return <span className='etiqueta-impuesto'>{impuesto.toString() + '%'}</span>
    } else {
      return ''
    }
  }

  const obtenerPrecioNeto = () => {
    if (props.estilo_precio === 'unitario') {
      return (
        <span className='etiqueta-precio'>
          {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                ).toFixed(decimales_pvp),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    } else {
      return (
        <span className='etiqueta-precio'>
          {precio[props.codigo_articulo][props.presentacion].importe_neto
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].importe_neto,
                ).toFixed(decimales_pvp),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    }
  }

  const obtenerPrecioTachado = () => {
    return (
      <span className='etiqueta-precio-tachado'>
        {precio[props.codigo_articulo][props.presentacion].precio_presentacion
          ? new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
            }).format(
              Number.parseFloat(
                precio[props.codigo_articulo][props.presentacion].precio_presentacion,
              ).toFixed(decimales_pvp),
            )
          : ''}

        {mostrarImpuestos()}

        {mostrarPresentacion()}
      </span>
    )
  }

  const obtenerPrecioBruto = () => {
    if (props.estilo_precio === 'unitario') {
      return (
        <span className='etiqueta-precio-bruto'>
          {precio[props.codigo_articulo][props.presentacion].precio_presentacion
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].precio_presentacion,
                ).toFixed(decimales_pvp),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
          {props.añadir_descuento &&
          props.añadir_descuento !== 'null' &&
          props.añadir_descuento !== 'N'
            ? obtenerDescuento()
            : ''}
        </span>
      )
    } else {
      return (
        <span className='etiqueta-precio-bruto'>
          {precio[props.codigo_articulo][props.presentacion].importe_bruto
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].importe_bruto,
                ).toFixed(decimales_pvp),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
          {props.añadir_descuento &&
          props.añadir_descuento !== 'null' &&
          props.añadir_descuento !== 'N'
            ? obtenerDescuento()
            : ''}
        </span>
      )
    }
  }

  const obtenerDescuento = () => {
    if (
      Number.parseFloat(precio[props.codigo_articulo][props.presentacion].dto_final).toFixed(
        decimales_significativos,
      ) > 1
    ) {
      if (
        props.añadir_descuento &&
        props.añadir_descuento !== 'null' &&
        props.añadir_descuento !== 'N'
      ) {
        return (
          ' -' +
          Number(precio[props.codigo_articulo][props.presentacion].dto_final)
            .toFixed(decimales_pvp)
            .toString() +
          '%'
        )
      } else {
        return (
          <span className='etiqueta-descuento'>
            {Number(precio[props.codigo_articulo][props.presentacion].dto_final)
              .toFixed(decimales_pvp)
              .toString() + '%'}
          </span>
        )
      }
    } else {
      return ''
    }
  }

  const obtenerPrecioMixto = () => {
    if (props.estilo_precio === 'unitario') {
      if (
        auth?.user?.netear_precio &&
        auth.user.netear_precio !== 'N' &&
        Number.parseFloat(
          precio[props.codigo_articulo][props.presentacion].precio_presentacion,
        ).toFixed(decimales_pvp) !==
          Number.parseFloat(
            precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
          ).toFixed(decimales_pvp)
      ) {
        return (
          <Fragment>
            <span className='etiqueta-precio-tachado mixto'>
              {precio[props.codigo_articulo][props.presentacion].precio_presentacion
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].precio_presentacion,
                    ).toFixed(decimales_pvp),
                  )
                : ''}

              {mostrarImpuestos()}

              {mostrarPresentacion()}
            </span>
            <span className='etiqueta-precio mixto'>
              {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                    ).toFixed(decimales_pvp),
                  )
                : ''}
            </span>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <span className='etiqueta-precio mixto'>
              {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                    ).toFixed(decimales_pvp),
                  )
                : ''}

              {mostrarImpuestos()}

              {mostrarPresentacion()}
            </span>
          </Fragment>
        )
      }
    } else {
      if (
        auth?.user?.netear_precio &&
        auth.user.netear_precio !== 'N' &&
        Number.parseFloat(precio[props.codigo_articulo][props.presentacion].importe_bruto).toFixed(
          decimales_pvp,
        ) !==
          Number.parseFloat(precio[props.codigo_articulo][props.presentacion].importe_neto).toFixed(
            decimales_pvp,
          )
      ) {
        return (
          <Fragment>
            <span className='etiqueta-precio-tachado mixto'>
              {precio[props.codigo_articulo][props.presentacion].importe_bruto
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].importe_bruto,
                    ).toFixed(decimales_pvp),
                  )
                : ''}

              {mostrarImpuestos()}

              {mostrarPresentacion()}
            </span>
            <span className='etiqueta-precio mixto'>
              {precio[props.codigo_articulo][props.presentacion].importe_neto
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].importe_neto,
                    ).toFixed(decimales_pvp),
                  )
                : ''}
            </span>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <span className='etiqueta-precio mixto'>
              {precio[props.codigo_articulo][props.presentacion].importe_neto
                ? new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                  }).format(
                    Number.parseFloat(
                      precio[props.codigo_articulo][props.presentacion].importe_neto,
                    ).toFixed(decimales_pvp),
                  )
                : ''}

              {mostrarImpuestos()}

              {mostrarPresentacion()}
            </span>
          </Fragment>
        )
      }
    }
  }

  // Precio cargando retornamos spinner
  if (
    (!precio?.[props.codigo_articulo]?.[props.presentacion] ||
      precio?.[props.codigo_articulo]?.[props.presentacion]?.status === 'fetching') &&
    (props.precio_manual === undefined ||
      props.precio_manual === '' ||
      props.precio_manual === null)
  ) {
    return <Spinner showSpinner={true} />
  } else if (props.precio_manual || props.precio_manual === 0) {
    // Si el precio es manual lo retornamos tal cual
    return (
      <Fragment>
        <span className='etiqueta-precio manual'>
          {new Intl.NumberFormat(locale, {
            style: 'currency',
            currency:
              precio?.[props.codigo_articulo]?.[props.presentacion]?.divisa_precio ||
              portal?.moneda ||
              'EUR',
          }).format(Number.parseFloat(props.precio_manual).toFixed(decimales_pvp))}
          {mostrarPresentacion()}
        </span>
      </Fragment>
    )
  } else if (precio?.[props.codigo_articulo]?.[props.presentacion]?.status === 'error') {
    // Error retornamos mensaje
    return (
      <Fragment>
        <span className='etiqueta-precio no-disp'>{i18n.t('general.precnodi')}</span>
      </Fragment>
    )
  } else if (precio?.[props.codigo_articulo]?.[props.presentacion]) {
    // En cualquier otro caso retornamos lo que se nos indique en el prop tipo precio
    return (
      <Fragment>
        <meta
          itemProp='priceCurrency'
          content={precio[props.codigo_articulo][props.presentacion].divisa_precio}
        />
        <meta
          itemProp='price'
          content={Number.parseFloat(
            precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
          ).toFixed(decimales_pvp)}
        />

        {props.tipo_precio && props.tipo_precio !== ''
          ? props.tipo_precio === 'neto'
            ? obtenerPrecioNeto()
            : props.tipo_precio === 'descuento'
            ? obtenerDescuento()
            : props.tipo_precio === 'mixto'
            ? obtenerPrecioMixto()
            : props.tipo_precio === 'bruto'
            ? obtenerPrecioBruto()
            : props.tipo_precio === 'impuesto'
            ? obtenerImpuesto()
            : obtenerPrecioMixto()
          : obtenerPrecioMixto()}
      </Fragment>
    )
  }
}

export default Precio
