import { useDispatch, useSelector } from "react-redux";
import { setEmailPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas"; 
 
 
const EmailPedido = (props) => {
  const dispatch = useDispatch();
  const pedido_cargando = useSelector((state)=>state.pedidos.isLoading);
  const pedido = useSelector((state)=>state.pedidos.pedido);
 
  const onChange = (e) =>{
    if (!pedido_cargando) {
      dispatch(setEmailPedido(e.target.value));
    }
  }
 
  return (
    <form className="form-default">
      <div className="form-group">
        <label htmlFor="persona_pedido">{i18n.t('pedido.emapedid')}</label>
        <input
          className="form-control"
          type="text"
          name="email_pedido"
          id="email_pedido"
          onChange={onChange}
          value={pedido?.email || ""}
          placeholder={i18n.t('pedido.emapedmi')}
          disabled={props.pedidoGuardado || pedido_cargando}
        />
      </div>
    </form>
  );
}
 
export default EmailPedido;