import axios from '../../axiosConfig'

import { b64DecodeUnicode } from '../../helpers/funciones'

import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

let componente

export class Terminos extends Component {
  state = {
    componente: null,
    contenido: '',
    tipo: '',
    html: '',
    numPages: null,
    pageNumber: 1,
  }

  componentWillMount() {
    if (this.state.componente !== componente) {
      this.setState({
        ...this.state,
        componente: componente,
        contenido: '',
        tipo: '',
        html: '',
        numPages: null,
        pageNumber: 1,
      })
    }
  }

  componentDidMount() {
    this.cargarContenido()
  }

  componentDidUpdate() {
    this.cargarContenido()
  }

  cargarContenido() {
    if (this.state.componente !== componente) {
      this.setState({
        ...this.state,
        componente: componente,
        contenido: '',
        tipo: '',
        html: '',
        numPages: null,
        pageNumber: 1,
      })
    } else if (this.state.contenido === '' && this.state.html === '') {
      // por si viene como par�metro o si se recupera el componente de la petici�n
      componente = componente?.componente ? componente.componente : componente

      axios
        .get(`/estaticos?componente=${componente}`)
        .then(res => {
          let estatico = res.data[0]

          if (estatico.tipo === 'MD') {
            //estatico.contenido = b64DecodeUnicode(estatico.html)
            estatico.contenido = estatico.html
          } else if (estatico.tipo === 'PDF') {
            estatico.contenido = atob(estatico.contenido)
          }

          //no hay que hacer nada si es html
          //else if (estatico.tipo === "HTML") {
          //estatico.contenido = b64DecodeUnicode(estatico.contenido);
          //estatico.html = estatico.html;
          //}

          this.setState({
            contenido: estatico.contenido,
            html: estatico.html,
            tipo: estatico.tipo,
          })
        })
        .catch(err => {
          console.log(err)

          this.setState({
            contenido: '',
            tipo: '',
            html: '',
            numPages: null,
            pageNumber: 1,
          })
        })
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    if (this.state.numPages === null) {
      this.setState({ numPages })
    }
  }

  siguientePagina = e => {
    e.preventDefault()
    if (this.state.pageNumber + 1 <= this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 })
    }
  }

  anteriorPagina = e => {
    e.preventDefault()
    if (this.state.pageNumber - 1 > 0) {
      this.setState({ pageNumber: this.state.pageNumber - 1 })
    }
  }

  seleccionarContenido(tipo, contenido, html) {
    switch (tipo) {
      case 'MD':
        return (
          <ReactMarkdown
            className='mt-5'
            children={contenido}
          />
        )

      case 'HTML':
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>
      default:
        return ''
    }
  }

  render() {
    // Partimos la URL
    let url = window.location.pathname
    let splittedURL = url.split('/')
    // Cogemos el codigo_articulo
    let comp = splittedURL[2]
    componente = this.props.componente ? this.props.componente : comp

    const { contenido, tipo, html } = this.state
    return (
      <div className='container'>
        <div id='tt-pageContent'>{this.seleccionarContenido(tipo, contenido, html)}</div>
      </div>
    )
  }
}

export default Terminos
