import axios from "../axiosConfig";

import {
  FETCH_ARTICULO,
  GET_ARTICULO,
  FETCH_ARTICULOS_DOC,
  GET_ARTICULOS_DOC,
  FETCH_ARTICULOS_AUX,
  GET_ARTICULOS_AUX,
  FETCH_CADENA_LOGISTICA,
  GET_CADENA_LOGISTICA,
  FETCH_CARACTERISTICAS_ARTICULO,
  GET_CARACTERISTICAS_ARTICULO,
  GET_ARTICULO_SUCCESS,
  GET_ARTICULO_FAIL,
  FLUSH_ARTICULO,
} from "./types";

import { tokenConfig } from "./auth";

// GET ARTICULO
export const getArticulo =
  (id_articulo, domicilio_envio = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_ARTICULO,
    });
    axios
      .get(`/articulos/${id_articulo}/?domicilio_envio=${domicilio_envio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_ARTICULO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ARTICULO_FAIL,
        });
        console.log(err);
      });
  };

export const getArticulosDoc = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS_DOC,
  });
  axios
    .get(`/articulosdoc/?codigo_articulo=${codigo_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ARTICULOS_DOC,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getArticulosAux = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS_AUX,
  });
  axios
    .get(`/articulosaux/?codigo_articulo=${codigo_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ARTICULOS_AUX,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getCadenaLogisticaArticulo =
  (codigo_articulo, tipo_cadena = "GEN") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_CADENA_LOGISTICA,
    });
    axios
      .get(`/cadenalogistica/?articulo=${codigo_articulo}&tipo=${tipo_cadena}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_CADENA_LOGISTICA,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const getValoresClavesArticulo = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_CARACTERISTICAS_ARTICULO,
  });
  axios
    .get(`/artclavesestadisticas/?articulo=${codigo_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CARACTERISTICAS_ARTICULO,
        payload: res.data,
        articulo: codigo_articulo,
      });
    })
    .catch((err) => console.log(err));
};

export const getArticuloSuccess = () => (dispatch, getState) => {
  dispatch({
    type: GET_ARTICULO_SUCCESS,
  });
};

export const limpiarArticuloSeleccionado = () => (dispatch, getState) => {
  dispatch({
    type: FLUSH_ARTICULO,
  });
};
