import axios from '../axiosConfig'
import { tokenConfig } from './auth'

import {
  GET_PRECIO,
  GET_PRECIO_FAIL,
  GET_PRECIO_SUCCESS,
  GET_PRECIOS,
  GET_PRECIOS_FAIL,
  GET_PRECIOS_SUCCESS,
} from './types'

let body

export const getPrecio =
  (
    codigo_articulo,
    cantidad_pedida = 1,
    id_pedido_seleccionado = '',
    domicilio_envio = '',
    presentacion = '',
    almacen_pedido = '',
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_PRECIO,
      codigo_articulo: codigo_articulo,
      presentacion: presentacion,
    })

    axios
      .get(
        `/precio/?codigo_articulo=${codigo_articulo}&cant=${cantidad_pedida}&id_pedido_seleccionado=${id_pedido_seleccionado}&domicilio_envio=${domicilio_envio}&pres=${presentacion}&almacen_pedido=${almacen_pedido}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_PRECIO_SUCCESS,
          codigo_articulo: codigo_articulo,
          presentacion: presentacion,
          payload: res.data,
        })
      })
      .catch(err => {
        console.log('Precio:', err.response.data)
        dispatch({
          type: GET_PRECIO_FAIL,
          codigo_articulo: codigo_articulo,
          presentacion: presentacion,
        })
      })
  }

export const getPrecios =
  (
    articulos_cantidades = [],
    origen = '',
    domicilio_envio = '',
    maximo_agrupados = 36,
    almacen_pedido = '',
  ) =>
  (dispatch, getState) => {
    //Comprobamos si supera el maximo

    const state = getState()
    const id_pedido_seleccionado =
      state.pedidos.id_pedido_seleccionado === ''
        ? state.pedidos.pedido.id_pedido ?? ''
        : state.pedidos.id_pedido_seleccionado
    if (articulos_cantidades.length > Number(maximo_agrupados)) {
      let z = 0 //Indice del array de arrays
      let array_aux = [] //Declaramos un array auxiliar
      array_aux[z] = [] //Lo iniciamos como array de arrays

      for (let x = 0; x < articulos_cantidades.length; x++) {
        //Hacemos push del valor actual
        array_aux[z].push({
          codigo_articulo: articulos_cantidades[x].codigo_articulo,
          cantidad: articulos_cantidades[x].cantidad,
          presentacion: articulos_cantidades[x].presentacion,
        })

        //Si x es divisor entero del maximo (sin ser 0 o 1)
        if (
          x !== 0 &&
          x !== 1 &&
          (x + 1) % Number(maximo_agrupados) === 0 &&
          x + 1 !== articulos_cantidades.length
        ) {
          z++ //Aumentamos el indice
          array_aux[z] = [] //Iniciamos el siguiente valor
        }
      }
      z = 0
      //Lanzamos peticion de precios variable del array de arrays
      array_aux.forEach(array_articulos => {
        body = JSON.stringify({
          articulos_cantidades: array_articulos,
          domicilio_envio,
          almacen_pedido,
        })
        z++
        dispatch({
          type: GET_PRECIOS,
          articulos_cantidades: array_articulos,
        })
        axios
          .post(
            `/precio/getPrecios/?origen=${origen}&total_articulos_precio=${articulos_cantidades.length}&parte=${z}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
            body,
            tokenConfig(getState),
          )
          .then(res => {
            dispatch({
              type: GET_PRECIOS_SUCCESS,
              articulos_cantidades: array_articulos,
              payload: res.data,
            })
          })
          .catch(err => {
            console.log('La peticion de precios ha fallado.')
            dispatch({
              type: GET_PRECIOS_FAIL,
              articulos_cantidades: array_articulos,
            })
          })
      })
    } else {
      //Si no supera el maximo se lanza una unica peticion
      body = JSON.stringify({
        articulos_cantidades,
        domicilio_envio,
        almacen_pedido,
      })
      dispatch({
        type: GET_PRECIOS,
        articulos_cantidades: articulos_cantidades,
      })
      axios
        .post(
          `/precio/getPrecios/?origen=${origen}&total_articulos_precio=${articulos_cantidades.length}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
          body,
          tokenConfig(getState),
        )
        .then(res => {
          dispatch({
            type: GET_PRECIOS_SUCCESS,
            articulos_cantidades: articulos_cantidades,
            payload: res.data,
          })
        })
        .catch(err => {
          console.log('La peticion de precios ha fallado.')
          dispatch({
            type: GET_PRECIOS_FAIL,
            articulos_cantidades: articulos_cantidades,
          })
        })
    }
  }
