import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { confirmAlert } from "react-confirm-alert";
import { ArrowFatLineLeft, FileX, FilePdf, File, FileCsv, FilePng, FileImage, FileDoc, FileJpg, FileXls, FileZip } from "phosphor-react";
import { setTipoExpediente, cerrarExpediente, setExpediente, getDatosFicherosExpediente, obtenerFicheroExpediente } from "../../actions/expedientes";
import i18n from "../../lang/idiomas";
import Spinner from "../layout/Spinner";
import { dynamicSort } from "../../helpers/funciones";

export class ExpedienteDetalle extends Component {
  static propTypes = {
    expedientes: PropTypes.array.isRequired,
    setTipoExpediente: PropTypes.func.isRequired,
    setExpediente: PropTypes.func.isRequired,
    cerrarExpediente: PropTypes.func.isRequired,
    getDatosFicherosExpediente: PropTypes.func.isRequired,
    obtenerFicheroExpediente: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.props.expedientes[this.props.expediente_seleccionado].tiene_ficheros === "S") {
      this.props.getDatosFicherosExpediente(this.props.expedientes[this.props.expediente_seleccionado]);
    }
  }

  componentWillUnmount() {
    this.props.setTipoExpediente(this.props.tipo_expediente_seleccionado);
  }

  pintarStatus() {
    switch (this.props.expedientes[this.props.expediente_seleccionado].status) {
      case "0300":
        return <div className="status-activo">{i18n.t("expedientes.exst0300")}</div>;
      case "0500":
        return <div className="status-espera">{i18n.t("expedientes.exst0500")}</div>;
      case "1000":
        if (this.props.expedientes[this.props.expediente_seleccionado].usuario_cierre === this.props.usuario.usuario_libra) {
          return <div className="status-cerrado">{i18n.t("expedientes.exst1kb")}</div>;
        } else {
          return <div className="status-cerrado">{i18n.t("expedientes.exst1ka")}</div>;
        }

      default:
        break;
    }
  }

  pintarTipo(tipo) {
    switch (tipo) {
      case "PDF":
        return <FilePdf size={40} weight={"bold"} />;
      case "CSV":
        return <FileCsv size={40} weight="bold" />;
      case "DOC":
        return <FileDoc size={40} weight="bold" />;
      case "JPG":
      case "JPEG":
        return <FileJpg size={40} weight="bold" />;
      case "PNG":
        return <FilePng size={40} weight="bold" />;
      case "XLS":
      case "ODS":
        return <FileXls size={40} weight="bold" />;
      case "ZIP":
        return <FileZip size={40} weight="bold" />;
      case "MPEG":
        return <FileImage size={40} weight="bold" />;
      default:
        return <File size={40} weight="bold" />;
    }
  }

  descargarFichero(fichero) {
    this.props.obtenerFicheroExpediente(fichero);
  }

  cerrarExpediente() {
    confirmAlert({
      title: i18n.t("expedientes.excietit"),
      message: i18n.t("expedientes.excietex"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.aceptar"),
          onClick: () => {
            this.props.cerrarExpediente(this.props.expedientes[this.props.expediente_seleccionado]);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
      willUnmount: () => {},
    });
  }

  render() {
    let porcentaje = 100 / this.props.expedientes[this.props.expediente_seleccionado].campos.length;
    porcentaje = porcentaje + "%";
    return (
      <Fragment>
        <div className="content-indent expediente">
          <div className="base-expediente">
            <div className="acciones-expediente">
              <button
                className="btn-volver-listado"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setTipoExpediente(this.props.tipo_expediente_seleccionado);
                }}
              >
                <ArrowFatLineLeft size={20} />
                <span>{i18n.t("general.volver")}</span>
              </button>
              <button
                className="btn-cerrar-exp"
                disabled={this.props.expedientes[this.props.expediente_seleccionado].status === "1000" ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  this.cerrarExpediente();
                }}
              >
                <FileX size={20} />
                <span>{i18n.t("expedientes.excerrar")}</span>
              </button>
            </div>
            <div className="numero">
              <div className="titulo numero">{i18n.t("expedientes.exnumexp") + " :"} </div>
              <div className="valor numero">{this.props.expedientes[this.props.expediente_seleccionado].numero_expediente}</div>
            </div>
            <div className="tipo">
              <div className="titulo tipo">{i18n.t("expedientes.extipo") + " :"} </div>
              <div className="valor tipo">{this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].nombre}</div>
            </div>
            <div className="status">
              <div className="titulo status">{i18n.t("expedientes.exstatus") + " :"} </div>
              <div className="valor status">{this.pintarStatus()}</div>
            </div>
          </div>

          <div className="detalle-expediente">
            {this.props.expedientes[this.props.expediente_seleccionado].campos.filter((campo) => campo.oculto !== 'S').sort(dynamicSort("orden")).map((campo, index) => (
              <div className={"campo " + campo.columna} key={"campo " + campo.columna + "-" + index}>
                <div className={"titulo-campo"} key={"titulo-" + campo.columna + "-" + index} style={{ width: porcentaje }}>
                  <span>{campo.literal}</span>
                </div>
                <div className={"valor-campo"} key={"valor-" + campo.columna + "-" + index} style={{ width: porcentaje }}>
                  <span> {campo.tipo_input === "file" ? this.props.expedientes[this.props.expediente_seleccionado].tiene_ficheros : campo.codigo_lov && (campo.d_valor || campo.d_valor === 0 ) && campo.tipo_lov !== "M" ? campo.d_valor : campo.valor}</span>
                </div>
              </div>
            ))}
          </div>
          {this.props.expedientes[this.props.expediente_seleccionado].tiene_ficheros === "S" ? (
            <Fragment>
              <div className="titulo-ficheros">{i18n.t("general.ficheros") + " :"}</div>
              {this.props.ficheros && this.props.ficheros.length > 0 ? (
                <div className="detalle-ficheros">
                  {this.props.ficheros.map((fichero, index) => (
                    <div
                      className={"tarjeta-fichero " + fichero.extension}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        this.descargarFichero(fichero);
                      }}
                    >
                      <div className="tipo">{this.pintarTipo(fichero.extension.toUpperCase())}</div>
                      <div className="nombre">{fichero.nombre}</div>
                    </div>
                  ))}
                </div>
              ) : this.props.expedientes_loading ? (
                <Spinner showSpinner={this.props.expedientes_loading} />
              ) : (
                i18n.t("expedientes.exerrfic")
              )}
            </Fragment>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  expediente_seleccionado: state.expedientes.expediente_seleccionado,
  usuario: state.auth.user,
  expedientes: state.expedientes.expedientes,
  expedientes_loading: state.expedientes.isLoading,
  language: state.languageSelector.language,
  ficheros: state.expedientes.lineas_expediente,
});

export default connect(mapStateToProps, {
  setTipoExpediente,
  cerrarExpediente,
  setExpediente,
  getDatosFicherosExpediente,
  obtenerFicheroExpediente,
})(ExpedienteDetalle);
