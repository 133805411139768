import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNumeroPedidoCliente } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
 
 
const NumeroPedidoCliente = (props) => {
  const dispatch = useDispatch()
  const pedido_cargando = useSelector((state) => state.pedidos.isLoading)
  const pedido = useSelector((state) => state.pedidos.pedido)
  
  const onChange = (e) => {
    if (!pedido_cargando) {
      dispatch(setNumeroPedidoCliente(e.target.value));
    }
  };
 
 
  return (
    <form className="form-default">
      <div className="form-group">
        <label htmlFor="pedido_cliente">{i18n.t("pedido.numpedcl")}</label>
        <input
          className="form-control"
          type="text"
          name="pedido_cliente"
          id="pedido_cliente"
          onChange={onChange}
          value={pedido?.pedido_cliente || ""}
          placeholder={i18n.t("pedido.numpclmi")}
          disabled={props.pedidoGuardado || pedido_cargando}
        />
      </div>
    </form>
  );
 
}
 
export default NumeroPedidoCliente