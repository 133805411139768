import { FETCH_ARTICULOS_KIT, GET_ARTICULOS_KIT, FAIL_GET_ARTICULOS_KIT } from "../actions/types";

const initialState = {
  articulos_kit: [],
  loading: false,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULOS_KIT:
      return {
        ...state,
        loading: true,
        articulos_kit: [],
      };
    case GET_ARTICULOS_KIT:
      return {
        ...state,
        loading: false,
        articulos_kit: action.payload,
      };
    case FAIL_GET_ARTICULOS_KIT:
      return {
        ...state,
        loading: false,
        articulos_kit: [],
      };
    default:
      return state;
  }
}
