import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import {
  FETCH_MENU_INFORMES,
  GET_MENU_INFORMES,
  GET_FILTROS_PLUGINS_INFORME,
  SET_INFORME_SELECCIONADO,
  SET_DATOS_INFORME,
  GET_DATOS_INFORME,
  OBTENER_EFACTURA,
  DESCARGA_EFACTURA,
  OBTENER_REPORT,
  DESCARGA_REPORT,
  GET_MAS_DATOS_INFORME,
  SET_MAS_DATOS_INFORME,
  SET_LISTA_VALORES,
  FETCH_PERMISOS_MENU_INFORMES,
  GET_PERMISOS_MENU_INFORMES,
  GENERA_PEDIDO_INFORME,
  GET_PEDIDO_ACTIVO,
  GET_DATOS_INFORME_SUCCESS,
  GET_DATOS_INFORME_FAIL,
  GENERA_PEDIDO_INFORME_SUCCESS,
  GENERA_PEDIDO_INFORME_FAIL,
} from "./types";

import { createMessage } from "./messages";
import { descargarFichero } from "../helpers/funciones";

// GET MENU INFORMES
export const getMenuInformes =
  (usuario_web = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_MENU_INFORMES,
    });

    axios
      .get(`/menuinformes/?usuario_web=${usuario_web}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_MENU_INFORMES,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err?.response?.status === 403) {
          dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
        }
      });
  };

// GET FILTROS Y PLUGINS INFORME
export const getFiltrosPluginsInforme = (informe) => (dispatch, getState) => {

  dispatch({
    type: SET_INFORME_SELECCIONADO,
    payload: informe,
  });

  axios
    .get(`/filtrospluginsinformes/?informe=${informe}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FILTROS_PLUGINS_INFORME,
        payload: res.data["respuesta"],
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err?.response?.status === 403) {
        dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
      }
    });
};

// LIMPIAR INFORME ACTUAL
export const limpiarInformeSeleccionado = () => (dispatch) => {
  dispatch({
    type: SET_INFORME_SELECCIONADO,
    payload: "",
  });
};

// GET DATOS DEL INFORME INFORME
export const getDatosInforme =
  (informe, filtros, tipo_salida = "") =>
  (dispatch, getState) => {
    const body = JSON.stringify({ informe, filtros, tipo_salida });

    dispatch({
      type: GET_DATOS_INFORME,
    });

    axios
      .post("/datosinformes/", body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: SET_DATOS_INFORME,
          payload: res.data.respuesta,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DATOS_INFORME_FAIL,
        });
        if (err.response && err?.response?.status === 403) {
          dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
        }
      });
  };

// GET DATOS DEL INFORME INFORME
export const getMasDatosInforme =
  (informe, filtros, tipo_salida = "") =>
  (dispatch, getState) => {
    const body = JSON.stringify({ informe, filtros, tipo_salida });

    dispatch({
      type: GET_MAS_DATOS_INFORME,
    });

    axios
      .post("/datosinformes/", body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: SET_MAS_DATOS_INFORME,
          payload: res.data.respuesta,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DATOS_INFORME_FAIL,
        });
        if (err.response && err?.response?.status === 403) {
          dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
        }
      });
  };

export const obtenerEFactura = (ejercicio, numero_serie, numero_factura) => (dispatch, getState) => {
  const body = JSON.stringify({ ejercicio, numero_serie, numero_factura });

  dispatch({
    type: OBTENER_EFACTURA,
  });

  axios
    .post("/obtnerefactura/", body, tokenConfig(getState))
    .then((res) => {
      if (Array.isArray(res.data.registros)) {
        for (var i = 0; i < res.data.registros.length; i++) {
          var registro = res.data.registros[i];

          descargarFichero(registro.nombre_archivo, registro.archivo);
        }
      } else {
        descargarFichero(res.data.registros.registro.nombre_archivo, res.data.registros.registro.archivo);
      }

      dispatch({
        type: DESCARGA_EFACTURA,
      });
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        dispatch(
          createMessage("No tienes permiso para acceder a esta funcionalidad", "error")
        );
      }
    });
};

export const obtenerReport = (modulo, parametros) => (dispatch, getState) => {
  const body = JSON.stringify({ modulo, parametros });

  dispatch({
    type: OBTENER_REPORT,
  });

  axios
    .post("/obtnerreport/", body, tokenConfig(getState))
    .then((res) => {
      descargarFichero(res.data.nombre, res.data.fichero);

      dispatch({
        type: DESCARGA_REPORT,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESCARGA_REPORT,
      });
      if (err.response && err?.response?.status === 403) {
        dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
      }
    });
};

// GET DATOS DEL INFORME INFORME
export const imprimirInforme =
  (informe, filtros, tipo_salida = "") =>
  (dispatch, getState) => {
    const body = JSON.stringify({ informe, filtros, tipo_salida });

    let nombre;

    if (tipo_salida === "XLS") {
      nombre = informe + ".xls";
    } else if (tipo_salida === "HTML") {
      nombre = informe + ".html";
    } else {
      nombre = informe + ".pdf";
    }

    dispatch({
      type: OBTENER_REPORT,
    });

    axios
      .post("/datosinformes/", body, tokenConfig(getState))
      .then((res) => {
        descargarFichero(nombre, res.data.respuesta.fichero, tipo_salida);
        dispatch({
          type: DESCARGA_REPORT,
        });
      })
      .catch((err) => console.log(err));
  };

export const getLov = (informe, lista_valores) => (dispatch, getState) => {
  axios
    .get(`/lovs/?informe=${informe}&lista_valores=${lista_valores}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SET_LISTA_VALORES,
        payload: res.data,
        lista_valores: lista_valores,
      });
    })
    .catch((err) => console.log(err));
};

export const getPermisosMenuInformes =
  (usuario_web = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PERMISOS_MENU_INFORMES,
    });

    axios
      .get(`/usuario/permisosgi/?usuario_web=${usuario_web}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PERMISOS_MENU_INFORMES,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const generarPedidoInforme =
  (informe, programa, filtros = [], tipo_salida = "") =>
  (dispatch, getState) => {
    let body = JSON.stringify({ informe, filtros, tipo_salida });
    if (programa !== undefined && programa !== null && programa === "carrito-ped") {
      //Obtenemos datos plugin informe-hijo
      axios
        .get(`/filtrospluginsinformes/?informe=${informe}`, tokenConfig(getState))
        .then((res) => {
          // Definimos varibles a usar
          let codigo_articulo;
          let presentacion;
          let cantidad;
          let tipo_linea;

          res.data.respuesta.plugins.plugin_e.filtros_plugin.forEach(
            // Seteamos sus valores
            (parametro) => {
              switch (parametro.parametro) {
                case "articulo":
                  codigo_articulo = parametro.valor_parametro;
                  break;
                case "presentacion":
                  presentacion = parametro.valor_parametro;
                  break;
                case "cantidad":
                  cantidad = parametro.valor_parametro;
                  break;
                case "tipo_lin":
                  tipo_linea = parametro.valor_parametro;
                  break;
                default:
                  break;
              }
            }
          );

          // Obtenemos los datos del informe correspondiente
          dispatch({
            type: GET_DATOS_INFORME,
          });
          axios
            .post("/datosinformes/", body, tokenConfig(getState))
            .then((res) => {
              dispatch({
                type: GET_DATOS_INFORME_SUCCESS,
              });

              filtros = []; // Limpiamos el array

              //Cargamos el mismo con los datos obtenidos
              for (let i = 0; i < res.data.respuesta.registros.length; i++) {
                let linea_ped = {
                  codigo_articulo: res.data.respuesta.registros[i][codigo_articulo],
                  presentacion: res.data.respuesta.registros[i][presentacion],
                  cantidad: parseInt(res.data.respuesta.registros[i][cantidad]),
                  tipo_linea: res.data.respuesta.registros[i][tipo_linea],
                };

                filtros[i] = linea_ped;
              }
              //Volcamos el nuevo valor
              body = JSON.stringify({ informe, filtros });

              //Llamamos al generar pedido
              dispatch({
                type: GENERA_PEDIDO_INFORME,
              });
              axios
                .post(`/pedidos/insertalineasinforme/?informe=${informe}`, body, tokenConfig(getState))
                .then((res) => {
                  dispatch({
                    type: GENERA_PEDIDO_INFORME_SUCCESS,
                  });
                  dispatch({
                    type: GET_PEDIDO_ACTIVO,
                    payload: res.data,
                  });
                  if (res.status === 202) {
                    dispatch(createMessage("Algunos articulos no han sido añadidos al pedido, revise pedido.", "success"));
                  } else {
                    dispatch(createMessage("Se han añadido todos los artículos al pedido", "success"));
                  }
                })
                .catch((err) => {
                  dispatch({
                    type: GENERA_PEDIDO_INFORME_FAIL,
                  });
                  if (err.response && err.response.data !== undefined) {
                    console.log(err);
                    dispatch(createMessage(err.response.data, err.response.status));
                  } else {
                    console.log(err);
                    dispatch(createMessage("No se han podido añadir los artículos a su pedido en este momento.", "error"));
                  }
                });
            })
            .catch((err) => {
              dispatch({
                type: GET_DATOS_INFORME_FAIL,
              });
              console.log(err);
              if (err.response && err?.response?.status === 403) {
                dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
              }
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err?.response?.status === 403) {
            dispatch(createMessage("No tienes permiso para acceder a esta funcionalidad", "error"));
          }
        });
    } else {
      dispatch({
        type: GENERA_PEDIDO_INFORME,
      });
      body = JSON.stringify({ informe, filtros });
      axios
        .post(`/pedidos/insertalineasinforme/?informe=${informe}`, body, tokenConfig(getState))
        .then((res) => {
          dispatch({
            type: GENERA_PEDIDO_INFORME_SUCCESS,
          });
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: res.data,
          });
          dispatch(createMessage("Articulo añadido a pedido", "success"));
        })
        .catch((err) => {
          dispatch({
            type: GENERA_PEDIDO_INFORME_FAIL,
          });
          if (err.response.data !== undefined) {
            console.log(err);
            dispatch(createMessage(err.response.data, err.response.status));
          } else {
            console.log(err);
            dispatch(createMessage("No se ha podido añadir el articulo en este momento.", "error"));
          }
        });
    }
  };
