import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";
import { GET_ARTICULOS_CONJUNTO, FETCH_ARTICULOS_CONJUNTO, FAIL_GET_ARTICULOS_CONJUNTO } from "./types";

export const getArticulosConjunto = (codigo_articulo) => (dispatch, _) => {
  dispatch({ type: FETCH_ARTICULOS_CONJUNTO });
  const url = `articulosconjunto/${codigo_articulo}`;

  axios
    .get(url, tokenConfig)
    .then((result) => {
      dispatch({
        type: GET_ARTICULOS_CONJUNTO,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({ type: FAIL_GET_ARTICULOS_CONJUNTO });
      dispatch(createMessage("No se ha podido cargar el contenido del conjunto", error.response.status));
    });
};
