import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { enviarRegistroCompleto } from '../../actions/auth'
import { getProvincias } from '../../actions/provincias'
import { getEstados } from '../../actions/estados'
import { getTiposNif } from '../../actions/tiposNif'
import { ordenarMenu, programaActivo, validaPass } from '../../helpers/funciones'
import { getPedidoActivo } from '../../actions/pedidos'
import { getMenus } from '../../actions/portal'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { createMessage } from '../../actions/messages'
import { getComunidadesAutonomas } from '../../actions/comunidadesAutonomas'
import i18n from '../../lang/idiomas'

registerLocale('es', es)

export class RegistroCompleto extends Component {
  state = {
    nif: '',
    nombre: '',
    fecha_nacimiento: '',
    direccion: '',
    codigo_postal: '',
    localidad: '',
    provincia: '',
    estado: '',
    email: '',
    telefono: '',
    usuario_web_registro: '',
    password_registro: '',
    tipo_entidad: '',
    razon_social: '',
    terminos_condiciones: false,
    politica_cookies: false,
    politica_privacidad: false,
    comunidad_autonoma: '',
    redirect_component: <></>,
  }

  static propTypes = {
    enviarRegistroCompleto: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    provincias: PropTypes.array.isRequired,
    estados: PropTypes.array.isRequired,
    tipos_nif: PropTypes.array.isRequired,
    createMessage: PropTypes.func.isRequired,
    getComunidadesAutonomas: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getEstados()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated ||
      prevProps.invitado !== this.props.invitado
    ) {
      this.props.getMenus()

      if (this.props.menus) {
        if (programaActivo(this.props.menus, 'articulos')) {
          this.props.getPedidoActivo(
            this.props.hash != null ? this.props.hash : '',
            'S',
            this.props.id_pedido_seleccionado,
          )
        }
      }
    }
  }

  noLoginCallback = () => {
    this.setState({
      ...this.state,
      redirect_component: <Navigate to='/' />,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    if (!this.state.terminos_condiciones) {
      this.props.createMessage(i18n.t('usuarios.udeaccon'), 'warning')
    } else if (!this.state.politica_cookies) {
      this.props.createMessage(i18n.t('usuarios.udeaccoo'), 'warning')
    } else if (!this.state.politica_privacidad) {
      this.props.createMessage(i18n.t('usuarios.udeacpop'), 'warning')
    } else if (!validaPass(this.state.password_registro)) {
      this.props.createMessage(i18n.t('usuarios.ulonnoc'), 'error')
    } else {
      this.props.enviarRegistroCompleto(this.state, this.props.hash, this.noLoginCallback)
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name === 'estado') {
      this.props.getTiposNif(e.target.value)
      this.props.getComunidadesAutonomas(e.target.value)
      this.props.getProvincias(e.target.value)
    }

    if (e.target.name === 'comunidad_autonoma') {
      this.props.getProvincias(this.state.estado, e.target.value)
    }
  }

  handleChange = date => {
    this.setState({
      fecha_nacimiento: date,
    })
  }

  render() {
    const menus = this.props.menus

    if (menus && menus.length > 0) {
      menus.sort(ordenarMenu)

      if (this.props.isAuthenticated && this.props.invitado === false) {
        const irA = menus.find(menu => menu.activo === 'S' && menu.programa)
        if (irA) {
          return <Navigate to={'/' + irA.programa} />
        } else {
          return ''
        }
      }
    }

    const InputFechaNacimiento = ({ value, onClick }) => (
      <input
        className={'form-control'}
        type='text'
        value={value}
        name='fecha_nacimiento'
        id='input_fecha_nacimiento'
        placeholder='Fecha nacimiento'
        onClick={onClick}
        readOnly
      />
    )

    return (
      <div
        id='tt-pageContent'
        className='registro'>
        {this.state.redirect_component}
        <div className='container-indent'>
          <div className='container'>
            <a
              className='tt-logo'
              href='index.html'>
              <img
                className='tt-retina'
                src='images/custom/logo.png'
                alt=''
              />
            </a>
            <h1 className='tt-title-subpages noborder'>{this.props.portal.descripcion}</h1>
            <div className='tt-login-form'>
              <div className='row	justify-content-center'>
                <div className='col-sm-12 col-md-8 col-lg-8'>
                  <div className='tt-item'>
                    <h2 className='tt-title'>{i18n.t('usuarios.uformreg')}</h2>
                    <div className='form-default justify-content-center'>
                      <form onSubmit={this.onSubmit}>
                        <div className='row'>
                          <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className='form-group'>
                              <label htmlFor='input_estado'>{i18n.t('usuarios.uestdo')}*</label>
                              <select
                                className='form-control'
                                name='estado'
                                id='input_estado'
                                onChange={this.onChange}
                                value={this.state.estado}
                                placeholder={i18n.t('usuarios.uestdo')}>
                                <option value=''>{i18n.t('usuarios.uestdo')}</option>
                                {this.props.estados && this.props.estados.length > 0
                                  ? this.props.estados.map((estado, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={estado.codigo}>
                                          {estado.nombre}
                                        </option>
                                      )
                                    })
                                  : ''}
                              </select>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_tipo_entidad'>
                                {i18n.t('usuarios.utientda')}*
                              </label>
                              <select
                                className='form-control'
                                name='tipo_entidad'
                                id='input_tipo_entidad'
                                onChange={this.onChange}
                                value={this.state.tipo_entidad}
                                placeholder={i18n.t('usuarios.utientda')}>
                                <option value=''>{i18n.t('usuarios.utientda')}</option>
                                {this.props.tipos_nif && this.props.tipos_nif.length > 0
                                  ? this.props.tipos_nif.map((tipo_nif, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={tipo_nif.tipo}>
                                          {tipo_nif.descripcion}
                                        </option>
                                      )
                                    })
                                  : ''}
                              </select>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_nombre'>{i18n.t('usuarios.unommin')}*</label>
                              <input
                                className='form-control'
                                type='text'
                                name='nombre'
                                id='input_nombre'
                                onChange={this.onChange}
                                value={this.state.nombre}
                                placeholder={i18n.t('usuarios.unommin')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_razon_social'>
                                {i18n.t('usuarios.urazsoc')}
                              </label>
                              <input
                                className='form-control'
                                type='text'
                                name='razon_social'
                                id='input_razon_social'
                                onChange={this.onChange}
                                value={this.state.razon_social}
                                placeholder={i18n.t('usuarios.urasocs')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_nif'>{i18n.t('usuarios.udni')}</label>
                              <input
                                className='form-control'
                                type='text'
                                name='nif'
                                id='input_nif'
                                onChange={this.onChange}
                                value={this.state.nif}
                                placeholder={i18n.t('usuarios.udni')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_fecha_nacimiento'>
                                {i18n.t('usuarios.ufecnac')}
                              </label>
                              <DatePicker
                                onChange={this.handleChange}
                                isClearable
                                selected={this.state.fecha_nacimiento}
                                dateFormat='dd/MM/yyyy'
                                todayButton='Hoy'
                                locale='es'
                                customInput={<InputFechaNacimiento />}
                                className='form-control'
                                name='fecha_nacimiento'
                                placeholderText={i18n.t('usuarios.ufecnac')}
                              />
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className='form-group'>
                              <label htmlFor='input_comunidad_autonoma'>
                                {i18n.t('usuarios.ucomaut')}
                              </label>
                              <select
                                className='form-control'
                                name='comunidad_autonoma'
                                id='input_comunidad_autonoma'
                                onChange={this.onChange}
                                value={this.state.comunidad_autonoma}
                                placeholder={i18n.t('usuarios.ucomaut')}>
                                <option value=''>{i18n.t('usuarios.ucomaut')}</option>
                                {this.props.comunidades_autonomas &&
                                this.props.comunidades_autonomas.length > 0
                                  ? this.props.comunidades_autonomas.map(
                                      (comunidad_autonoma, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={comunidad_autonoma.comunidad_autonoma}>
                                            {comunidad_autonoma.nombre}
                                          </option>
                                        )
                                      },
                                    )
                                  : ''}
                              </select>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_provincia'>{i18n.t('usuarios.uprov')}*</label>
                              <select
                                className='form-control'
                                name='provincia'
                                id='input_provincia'
                                onChange={this.onChange}
                                value={this.state.provincia}
                                placeholder={i18n.t('usuarios.uprov')}>
                                <option value=''>{i18n.t('usuarios.uprov')}</option>
                                {this.props.provincias && this.props.provincias.length > 0
                                  ? this.props.provincias.map((provincia, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={provincia.provincia}>
                                          {provincia.nombre}
                                        </option>
                                      )
                                    })
                                  : ''}
                              </select>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_localidad'>{i18n.t('usuarios.uloc')}*</label>
                              <input
                                className='form-control'
                                type='text'
                                name='localidad'
                                id='input_localidad'
                                onChange={this.onChange}
                                value={this.state.localidad}
                                placeholder={i18n.t('usuarios.uloc')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_codigo_postal'>
                                {i18n.t('usuarios.ucodpos')}*
                              </label>
                              <input
                                className='form-control'
                                type='text'
                                name='codigo_postal'
                                id='input_codigo_postal'
                                onChange={this.onChange}
                                value={this.state.codigo_postal}
                                placeholder={i18n.t('usuarios.ucodpos')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_direccion'>{i18n.t('usuarios.udir')}*</label>
                              <input
                                className='form-control'
                                type='text'
                                name='direccion'
                                id='input_direccion'
                                onChange={this.onChange}
                                value={this.state.direccion}
                                placeholder={i18n.t('usuarios.udir')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_email'>{i18n.t('usuarios.uemlmin')}*</label>
                              <input
                                className='form-control'
                                type='text'
                                name='email'
                                id='input_email'
                                onChange={this.onChange}
                                value={this.state.email}
                                placeholder={i18n.t('usuarios.uemlmin')}
                              />
                            </div>

                            <div className='form-group'>
                              <label htmlFor='input_telefono'>{i18n.t('usuarios.utel')}*</label>
                              <input
                                className='form-control'
                                type='text'
                                name='telefono'
                                id='input_telefono'
                                onChange={this.onChange}
                                value={this.state.telefono}
                                placeholder={i18n.t('usuarios.utel')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label htmlFor='input_usuario_web_registro'>
                                {i18n.t('usuarios.uusweb')}*
                              </label>
                              <input
                                className='form-control'
                                type='text'
                                name='usuario_web_registro'
                                id='input_usuario_web_registro'
                                onChange={this.onChange}
                                value={this.state.usuario_web_registro}
                                placeholder={i18n.t('usuarios.uusweb')}
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='input_password_registro'>
                                {i18n.t('usuarios.ucont')}*
                              </label>
                              <input
                                className='form-control'
                                type='password'
                                name='password_registro'
                                id='input_password_registro'
                                autoComplete='new-password'
                                onChange={this.onChange}
                                value={this.state.password_registro}
                                placeholder={i18n.t('usuarios.ucont')}
                              />
                              <p>{i18n.t('usuarios.ureqmin')}</p>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-auto mr-auto'>
                            <div className='form-check'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='terminos_condiciones'
                                name='terminos_condiciones'
                                onChange={this.onChange}
                                checked={this.state.terminos_condiciones}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='terminos_condiciones'>
                                {i18n.t('usuarios.uhelyac')}
                              </label>
                            </div>
                            <div className='form-check'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='politica_cookies'
                                name='politica_cookies'
                                onChange={this.onChange}
                                checked={this.state.politica_cookies}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='politica_cookies'>
                                {i18n.t('usuarios.uhlyapp')}
                              </label>
                            </div>
                            <div className='form-check'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='politica_privacidad'
                                name='politica_privacidad'
                                onChange={this.onChange}
                                checked={this.state.politica_privacidad}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='politica_privacidad'>
                                {i18n.t('usuarios.uhlyapp')}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-auto mr-auto'>
                            <div className='form-group'>
                              <button
                                type='submit'
                                className='btn btn-primary'>
                                {i18n.t('usuarios.uenvi')}
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          {i18n.t('usuarios.uyatiusr')}{' '}
                          <Link to='/login'>{i18n.t('usuarios.uaccsit')}</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
  provincias: state.provincias.provincias,
  estados: state.estados.estados,
  tipos_nif: state.tiposNif.tipos_nif,
  comunidades_autonomas: state.comunidadesAutonomas.comunidades_autonomas,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
})

export default connect(mapStateToProps, {
  enviarRegistroCompleto,
  getEstados,
  getProvincias,
  getTiposNif,
  getMenus,
  getPedidoActivo,
  createMessage,
  getComunidadesAutonomas,
})(RegistroCompleto)
