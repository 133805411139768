import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Novedades from './Novedades'
import Ofertas from './Ofertas'
import SelectorAlmacen from './SelectorAlmacen'
import { PropTypes } from 'prop-types'

export class NovedadesOfertas extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
  }

  render() {
    if (
      Object.keys(this.props.pedido).length === 0 ||
      this.props.pedido?.data?.codigo_cliente === ''
    ) {
      return (
        <Fragment>
          <SelectorAlmacen />
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div className='container'>
            <div id='tt-pageContent'>
              <Novedades />
              <Ofertas />
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

const mapStateToProps = state => ({
  pedido: state.pedidos.pedido,
})

export default connect(mapStateToProps, {})(NovedadesOfertas)
