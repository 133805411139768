import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setArticulo, setLineas } from "../../actions/botonanadirlista";
import { HeartStraight, ListBullets } from "phosphor-react";
import SubscripcionesStock from "../usuarios/SubscripcionesStock";
import i18n from "../../lang/idiomas";
import ArticuloNecesidades from "./ArticuloNecesidades";
import { programaActivo } from "../../helpers/funciones";

const BotonAnadirLista = (props) => {
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.portal.menus);
  const articulos = useSelector((state) => state.listadoArticulos.articulos.articulos);
  const articulo = useSelector((state) => state.articulo.articulo);
  const pedido_habitual = useSelector((state) => state.listadoArticulos.pedido_habitual.articulos);
  const ofertas = useSelector((state) => state.listadoArticulos.ofertas.articulos);
  const novedades = useSelector((state) => state.listadoArticulos.novedades.articulos);
  const relacionados = useSelector((state) => state.listadoArticulos.relacionados.articulos);
  const equivalentes = useSelector((state) => state.listadoArticulos.equivalentes.equivalentes);

  const openModal = (e) => {
    e.preventDefault();
    if (props.codigo_articulo) {
      // Origen => Articulo, TablaArticulos o ListaArticulos
      let valor = document.getElementById("input-cant-" + props.codigo_articulo).value;
      let valor_mob = document.getElementById("input-cant-mob-" + props.codigo_articulo).value;
      // Comprobamos que tengan valor
      if (valor && valor_mob) {
        // Comprobamos cual es el mayor y enviamos
        dispatch(setArticulo(props.codigo_articulo, valor >= valor_mob ? valor : valor_mob, props.presentacion));
      } else {
        // Si no enviamos 1
        dispatch(setArticulo(props.codigo_articulo, "1", props.presentacion));
      }
    } else if (props.lineas) {
      // Origen => Pedido
      dispatch(setLineas(props.lineas));
    }
  };

  const cargarArticulo = () => {
    let articulo_aux;

    let existeArticulo = -1;
    switch (props.origen) {
      case "articulo":
        if (articulo) {
          articulo_aux = articulo;
        }
        break;
      case "articulos":
        if (articulos && articulos.length > 0) {
          existeArticulo = articulos.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = articulos[existeArticulo];
          }
        }
        break;
      case "relacionados":
        if (relacionados && relacionados.length > 0) {
          existeArticulo = relacionados.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = relacionados[existeArticulo];
          }
        }
        break;
      case "equivalentes":
        if (equivalentes && equivalentes.length > 0) {
          existeArticulo = equivalentes.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = equivalentes[existeArticulo];
          }
        }
        break;
      case "pedido_habitual":
        if (pedido_habitual && pedido_habitual.length > 0) {
          existeArticulo = pedido_habitual.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = pedido_habitual[existeArticulo];
          }
        }
        break;
      case "ofertas":
        if (ofertas && ofertas.length > 0) {
          existeArticulo = ofertas.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = ofertas[existeArticulo];
          }
        }
        break;
      case "novedades":
        if (novedades && novedades.length > 0) {
          existeArticulo = novedades.findIndex((articulo) => articulo.codigo_articulo === props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = novedades[existeArticulo];
          }
        }
        break;
      default:
        break;
    }
    return articulo_aux;
  };

  let anadir_lista = false;
  if (menus) {
    anadir_lista = programaActivo(menus, "listasCompra");
  }
  let articulo2 = cargarArticulo();

  if (anadir_lista) {
    switch (props.origen) {
      case "articulos":
      case "pedido_habitual":
      case "ofertas":
      case "novedades":
      case "equivalentes":
      case "relacionados":
        return (
          <Fragment>
            <div className="botonera-oculta-listado">
              {articulo2 ? <ArticuloNecesidades articulo={articulo2} origen={props.origen} /> : ""}
              {props.btn_subs === "S" ? <SubscripcionesStock origen={props.origen} codigo_articulo={props.codigo_articulo} /> : ""}
              <button className="boton-wishlist" data-tposition="left" onClick={openModal}>
                <HeartStraight size={20} weight="regular" alt={i18n.t("general.altaddwl")} />
              </button>
            </div>
          </Fragment>
        );
      case "articulos_mobile":
        return (
          <Fragment>
            {" "}
            <button className="tt-btn-wishlist" onClick={openModal}>
              <span className="ocultar">{i18n.t("general.wishlist")}</span>
            </button>
          </Fragment>
        );
      case "articulo":
        return (
          <Fragment>
            {articulo2 ? <ArticuloNecesidades articulo={articulo2} origen={props.origen} /> : ""}
            <div className="iconos-funcion">
              <button className="btn list" onClick={openModal}>
                <HeartStraight size={30} alt={i18n.t("general.altaddwl")} /> {i18n.t("general.altaddwl").toUpperCase()}
              </button>
              {props.btn_subs === "S" ? <SubscripcionesStock origen={props.origen} codigo_articulo={props.codigo_articulo} /> : ""}
            </div>
          </Fragment>
        );
      case "tabla":
        return (
          <Fragment>
            <button
              className="btn btn-primary"
              //data-tooltip="Añadir a lista"
              value="O"
              onClick={openModal}
              tabIndex={props.tabIndex}
            >
              {props.mostrar_texto_btn === "S" ? i18n.t("general.lista").toUpperCase() : <HeartStraight size={20} alt={i18n.t("general.altaddwl")} />}
            </button>
          </Fragment>
        );
      case "carrito":
        return (
          <Fragment>
            <div className="tt-cart-btn">
              <div className="tt-item">
                <button onClick={openModal} className="btn">
                  {i18n.t("general.saveaswl").toUpperCase()}
                </button>
              </div>
            </div>
          </Fragment>
        );
      case "resumen":
        return (
          <Fragment>
            <button className="btn-link" onClick={openModal}>
              <ListBullets size={20} weight="regular" /> <span>{i18n.t("general.saveaswl").toUpperCase()}</span>
            </button>
          </Fragment>
        );
      default:
        break;
    }
  } else {
    return "";
  }
};

export default BotonAnadirLista;
