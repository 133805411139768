import { SET_CUSTOMER } from "../actions/types.js";

const initialState = {
  cliente_seleccionado: "",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        cliente_seleccionado: action.payload,
      };
    default:
      return state;
  }
}
