import React, { useRef, useEffect, useState, Fragment, version } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "../../lang/idiomas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FilePdf,
  FileXls,
  MagnifyingGlass,
  Download,
  ShoppingCart,
  CaretDown,
  CaretUp
} from "phosphor-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import InformesMenu from "./InformesMenu";
import InformesFiltroField from "./InformesFiltroField";
import InformesFiltroFecha from "./InformesFiltroFecha";
import InformesFiltroLOV from "./InformesFiltroLOV";
import Spinner from "../layout/Spinner";
import {
  getMenuInformes,
  getDatosInforme,
  getFiltrosPluginsInforme,
  obtenerEFactura,
  obtenerReport,
  imprimirInforme,
  getMasDatosInforme,
  limpiarInformeSeleccionado,
  generarPedidoInforme
} from "../../actions/informes";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

let p_empresa;
let p_org_comer;
let p_num_serie;
let p_num_pedido;
let p_num_linea;
let p_codigo_articulo;
let p_presentacion;
let p_cantidad;
let p_tipo_linea;
let p_activo;
let index;

const Informes = (props) => {
  const dispatch = useDispatch();
  const informes = useSelector((state) => state.informes.informes);
  const informe_seleccionado = useSelector(
    (state) => state.informes.informe_seleccionado
  );
  const datos = useSelector((state) => state.informes.datos);
  const numero_registros = useSelector(
    (state) => state.informes.numero_registros
  );
  const filtros = useSelector((state) => state.informes.filtros);
  const plugins = useSelector((state) => state.informes.plugins);
  const permitir_excel = useSelector((state) => state.informes.permitir_excel);
  const informes_loading = useSelector((state) => state.informes.isLoading);
  const pathLoc = useSelector((state) => state.informes.pathLoc);
  const language = useSelector((state) => state.languageSelector.language);

  const [inicio, setInicio] = useState(1);
  const [fin, setFin] = useState(10);
  const [pluginTouched, setPluginTouched] = useState(false);
  const [pluginsShowed, setPluginsShowed] = useState(null);
  const [filtersShowed, setFiltersShowed] = useState(false);
  const [faltanObligatorios, setFaltanObligatorios] = useState([]);

  useEffect(() => {
    window.wookie();

    // Solicitamos el menu Informes
    dispatch(getMenuInformes());

    return () => {
      // Limpiamos el imforme al desmontar el componente
      dispatch(limpiarInformeSeleccionado());
    };
  }, []);

  useEffect(() => {
    // Solicitamos los datos con el nuevo idioma
    dispatch(getMenuInformes());
    dispatch(getFiltrosPluginsInforme(informe_seleccionado));
  }, [language]);

  useEffect(() => {
    // Si no existen filtros solicitamos en informe
    const hay_filtro = filtros.some(
      (filtro) =>
        filtro.campo_de_filtro === "S" &&
        (filtro.tipo_filtro !== "E" || filtro.filtro_calendario === "S")
    );

    if (!hay_filtro && !pluginTouched && !informes_loading) {
      const submit_formulario = document.getElementById(
        "filtros_informe_submit"
      );

      if (submit_formulario) {
        submit_formulario.click();
      }
    }
  }, [filtros]);

  useEffect(() => {
    //Reseteamos los valores de las variables.
    p_empresa = "";
    p_org_comer = "";
    p_num_serie = "";
    p_num_pedido = "";
    p_num_linea = "";
    p_codigo_articulo = "";
    p_presentacion = "";
    p_cantidad = "";
    p_tipo_linea = "";
    p_activo = "";

    if (plugins && plugins.length > 0) {
      //Seteamos el indice
      index = -1;

      const hay_plugin = plugins.some(
        (plugin) =>
          plugin.plugin.programa_llamado === "carrito-lin" &&
          plugin.filtros_plugin.some((filtro) => filtro.parametro === "activo")
      );

      if (hay_plugin) {
        index = plugins.findIndex(
          (plugin) =>
            plugin.plugin.programa_llamado === "carrito-lin" &&
            plugin.filtros_plugin.some(
              (filtro) => filtro.parametro === "activo"
            )
        );

        if (index !== -1) {
          for (const fp of plugins[index].filtros_plugin) {
            switch (fp.parametro) {
              case "cod_emp":
                p_empresa = fp.valor_parametro;
                break;
              case "org_comer":
                p_org_comer = fp.valor_parametro;
                break;
              case "num_serie":
                p_num_serie = fp.valor_parametro;
                break;
              case "num_pedido":
                p_num_pedido = fp.valor_parametro;
                break;
              case "num_linea":
                p_num_linea = fp.valor_parametro;
                break;
              case "activo":
                p_activo = fp.valor_parametro;
                break;
              case "cantidad":
                p_cantidad = fp.valor_parametro;
                break;
              case "articulo":
                p_codigo_articulo = fp.valor_parametro;
                break;
              case "presentacion":
                p_presentacion = fp.valor_parametro;
                break;
              case "tipo_lin":
                p_tipo_linea = fp.valor_parametro;
                break;
              default:
                break;
            }
          }
        }
      }
    }
  }, [plugins]);

  const modificarEstadoPluggin = (estadoPlugin) => {
    setInicio(1);
    setFin(10);
    setPluginTouched(estadoPlugin);
  };

  const onPlugin = (e) => {
    e.preventDefault();

    const param = e.currentTarget.dataset;
    const plugin = plugins.filter(
      (plugin) => plugin.plugin.codigo === param.codigo
    )[0];

    let filtro = [];
    if (plugin.filtros_plugin && plugin.filtros_plugin.length > 0) {
      for (const fp of plugin.filtros_plugin) {
        if (fp.tipo_parametro === "C") {
          filtro.push({
            nombre: fp.parametro,
            valor: fp.valor_parametro,
          });
        } else {
          filtro.push({
            nombre: fp.parametro,
            valor: datos[param.datos][fp.valor_parametro],
          });
        }
      }
    }

    if (param.informe && param.informe !== "") {
      const filtros = {
        filtro: filtro,
        inicio: inicio,
        fin: fin,
      };
      dispatch(getFiltrosPluginsInforme(plugin.plugin.informe_llamado));
      dispatch(getDatosInforme(plugin.plugin.informe_llamado, filtros));
      modificarEstadoPluggin(true);
    }

    if (param.programa) {
      switch (param.programa) {
        case "efactura":
          dispatch(
            obtenerEFactura(filtro[0].valor, filtro[2].valor, filtro[1].valor)
          );
          break;
        case "carrito-ped":
          let plugin_hijo = plugins.filter(
            (plugin_h) => plugin_h.plugin.codigo === "1"
          )[0];
          let datos_hijo = datos[param.datos];
          let filtro_hijo = {};
          let filtro_aux = [];
          if (
            plugin_hijo.filtros_plugin &&
            plugin_hijo.filtros_plugin.length > 0
          ) {
            for (const fph of plugin_hijo.filtros_plugin) {
              if (fph.tipo_parametro === "C") {
                filtro_aux.push({
                  nombre: fph.parametro,
                  valor: fph.valor_parametro,
                });
              } else {
                filtro_aux.push({
                  nombre: fph.parametro,
                  valor: datos_hijo[fph.valor_parametro],
                });
              }
            }
            filtro_hijo.filtro = filtro_aux;
          }
          dispatch(
            generarPedidoInforme(
              plugin_hijo.plugin.informe_llamado,
              param.programa,
              filtro_hijo, //Datos
              "" //Tipo salida
            )
          );
          break;
        case "carrito-lin":
          let filtro_linea = [];
          let datos = datos[param.datos];
          let linea_ped = {
            codigo_articulo: datos[p_codigo_articulo],
            presentacion: datos[p_presentacion],
            cantidad: parseInt(datos[p_cantidad]),
            tipo_linea: datos[p_tipo_linea],
          };
          filtro_linea[0] = linea_ped;
          dispatch(
            generarPedidoInforme(
              informe_seleccionado,
              param.programa,
              filtro_linea, //Datos
              "" //Tipo salida
            )
          );
          break;
        default:
          break;
      }
    }

    if (param.report && param.report !== "") {
      dispatch(obtenerReport(param.report, filtro));
    }
  };

  const obtenerFiltros = () => {
    let filtro = [];
    let faltan_campos = false;
    for (const f of filtros) {
      if (f.campo_de_filtro === "S") {
        if (f.tipo_filtro === "I") {
          if (f.obligatorio === "S") {
            let filtro_hasta =
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro + "_HASTA"
              ];
            let filtro_desde =
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro + "_DESDE"
              ];

            if (filtro_hasta.value === null || filtro_hasta.value === "") {
              filtro_hasta.style.border = "1px solid #FF0000";
              faltan_campos = true;
            }
            if (filtro_desde.value === null || filtro_desde.value === "") {
              filtro_desde.style.border = "1px solid #FF0000";
              faltan_campos = true;
            }
          }
          filtro.push({
            nombre: f.nombre_filtro + "_HASTA",
            valor:
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro + "_HASTA"
              ].value,
          });
          filtro.push({
            nombre: f.nombre_filtro + "_DESDE",
            valor:
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro + "_DESDE"
              ].value,
          });
        } else if (f.tipo_filtro !== "E") {
          if (f.obligatorio === "S") {
            let filtro_normal =
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro
              ];

            if (filtro_normal.value === null || filtro_normal.value === "") {
              filtro_normal.style.border = "1px solid #FF0000";
              faltan_campos = true;
            }
          }
          filtro.push({
            nombre: f.nombre_filtro,
            valor:
              document.getElementById("filtros_informe").elements[
                f.nombre_filtro
              ].value,
          });
        }
      }
    }
    if (faltan_campos) {
      return false;
    } else {
      return filtro;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let filtro = obtenerFiltros();

    if (filtro === false) {
      confirmAlert({
        title: i18n.t("informes.inftitfc"),
        message: i18n.t("informes.inftexfc"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.aceptar"),
            onClick: () => {
              "";
            },
          },
        ],
      });
    } else {
      setInicio(1);
      setFin(10);
      setPluginTouched(false);

      const filtros = {
        filtro: filtro,
        inicio: 1,
        fin: 999999,
      };

      dispatch(getDatosInforme(informe_seleccionado, filtros));
    }
  };

  const descargarExcel = () => descargar("XLS");

  const descargarPdf = () => descargar("PDF");

  const descargar = (tipo_salida) => {
    if (tipo_salida !== "") {
      let filtro = obtenerFiltros();

      if (filtro === false) {
        confirmAlert({
          title: i18n.t("informes.inftitfc"),
          message: i18n.t("informes.inftexfc"),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: i18n.t("general.aceptar"),
              onClick: () => {
                "";
              },
            },
          ],
        });
      } else {
        const filtros = {
          filtro: filtro,
          inicio: inicio,
          fin: fin,
        };

        dispatch(imprimirInforme(informe_seleccionado, filtros, tipo_salida));
      }
    }
  };

  const masRegistros = (e) => {
    e.preventDefault();

    const nuevo_inicio = inicio + 10;
    const nuevo_fin = fin + 10;

    setInicio(nuevo_inicio);
    setFin(nuevo_fin);
    setPluginTouched(false);

    let filtro = obtenerFiltros();
    if (filtro === false) {
      confirmAlert({
        title: i18n.t("informes.inftitfc"),
        message: i18n.t("informes.inftexfc"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.aceptar"),
            onClick: () => {
              "";
            },
          },
        ],
      });
    } else {
      const filtros = {
        filtro: filtro,
        inicio: nuevo_inicio,
        fin: nuevo_fin,
      };

      dispatch(getMasDatosInforme(informe_seleccionado, filtros));
    }
  };

  const verPlugins = (indice) => {
    pluginsShowed === indice ? setPluginsShowed(null) : 
    setPluginsShowed(indice);
  };

  const filtrosOcultos = {
    display: "none",
  };

  const informe =
    informe_seleccionado !== undefined &&
    informe_seleccionado !== null &&
    informe_seleccionado !== ""
      ? informes.filter((f) => f.nombre_informe === informe_seleccionado)[0]
      : undefined;

  return (
    <Fragment>
      <InformesMenu setPlugginState={modificarEstadoPluggin} />
      <div className="container contenedor_informes">
        {informes.length === 0 && informes_loading ? (
          <Spinner showSpinner={informes_loading} />
        ) : informe_seleccionado === "" ? (
           <p className="sel_informe_txt">{i18n.t("informes.inftitle")}</p>
        ) : pluginTouched === false ? (
          <Fragment>
            <div className="titulo">
              <h2>{informe.nombre}</h2>
            </div>
              <div
                className={datos.length === 0 ? "tt-item active" : "tt-item"}
              >
                <div className="tt-collapse-title" onClick={(e) => {
                             e.preventDefault();
                             setFiltersShowed(!filtersShowed);
                }}>
                  {i18n.t("general.filtros")}
                </div>
                <div
                  className="tt-collapse-content"
                  style={
                    datos.length === 0 ? { display: "block" } : filtersShowed ? { display: "block" } : { display: "none" }
                  }
                >
                  <form
                    className="form-default"
                    id="filtros_informe"
                    onSubmit={onSubmit}
                  >
                    {filtros.map((filtro) => {
                      if (filtro.campo_de_filtro === "S") {
                        if (filtro.filtro_calendario === "S") {
                          return (
                            <InformesFiltroFecha
                              key={filtro.id_columna}
                              filtro={filtro}
                            />
                          );
                        } else if (
                          filtro.lista_valores &&
                          filtro.lista_valores.length !== 0 &&
                          filtro.tipo_filtro !== "E"
                        ) {
                          return (
                            <InformesFiltroLOV
                              key={filtro.id_columna}
                              filtro={filtro}
                            />
                          );
                        } else if (filtro.tipo_filtro !== "E") {
                          return (
                            <InformesFiltroField
                              key={filtro.id_columna}
                              filtro={filtro}
                            />
                          );
                        }
                      }
                      return "";
                    })}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="filtros_informe_submit"
                    >
                      <MagnifyingGlass size={22} weight="bold" />{" "}
                      {i18n.t("general.enviar")}
                    </button>
                  </form>
                </div>
              </div>
          </Fragment>
        ) : (
          ""
        )}

        {informe_seleccionado !== "" && informes_loading ? (
          <Spinner showSpinner={informes_loading} />
        ) : datos.length === 0 ? (
          ""
        ) : (
          <Fragment>
            <div className="tt-shopcart-table informes_desctop">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="fila_titulo_y_botones">
                  <div className="botonera">
                    <div className="botones_imprimir">
                      {permitir_excel !== undefined &&
                      permitir_excel === "S" ? (
                        <button
                          className="btn_descarga_informe"
                          onClick={descargarExcel}
                          value="XLS"
                        >
                          <FileXls size={22} weight={"regular"} />{" "}
                          {informes_loading ? (
                            <span>{i18n.t("general.cargando")}</span>
                          ) : (
                            <span>{i18n.t("informes.downexl")}</span>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn_descarga_informe"
                        onClick={descargarPdf}
                        value="PDF"
                      >
                        <FilePdf size={22} weight={"regular"} />{" "}
                        {informes_loading ? (
                          <span>{i18n.t("general.cargando")}</span>
                        ) : (
                          <span>{i18n.t("informes.downpdf")}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tbScroll">
                  <div className="row titulos rowScroll">
                    {plugins.length !== 0 ? (
                      <div
                        style={{ width: "10%", flexShrink: 0 }}
                        className={"col"}
                      >
                        {" "}
                      </div>
                    ) : (
                      ""
                    )}
                    {filtros.map((cab, ind) => {
                      if (cab.tipo_filtro !== "E") {
                        return (
                          <div
                            style={
                              Number.parseInt(cab.ancho_web) !== 0
                                ? {
                                    width: cab.ancho_web + "%",
                                    flexShrink: 0,
                                  }
                                : { display: "none" }
                            }
                            className={"col"}
                            key={"cab_" + ind}
                          >
                            {cab.titulo_columna}
                          </div>
                        );
                      }
                      return "";
                    })}
                  </div>

                  {datos.map((fila, ind) => (
                    <Fragment key={"Fragment_" + ind}>
                      <div className="row fila" key={"fila_" + ind}>
                        <div
                          className="row datos rowScroll"
                          key={"fil_" + ind}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   verPlugins(ind);
                          // }}
                        >
                          {plugins.length === 0 ? null : (
                            <div
                              className="col plugins"
                              style={{ width: "10%", padding: "unset" , flexShrink: 0 }}
                            >
                              {plugins.length === 1 ? (
                                <button
                                  className={"btn btn-primary btn-plugin ind1"}
                                  key={plugins[0].plugin.codigo}
                                  onClick={onPlugin}
                                  data-datos={ind}
                                  data-codigo={plugins[0].plugin.codigo}
                                  data-informe={
                                    plugins[0].plugin.informe_llamado
                                  }
                                  data-programa={
                                    plugins[0].plugin.programa_llamado
                                  }
                                  data-report={plugins[0].plugin.report_llamado}
                                >
                                  {plugins[0].plugin.icono !== "" &&
                                  plugins[0].plugin.icono === "download" ? (
                                    <Download size={22} weight="fill" />
                                  ) : plugins[0].plugin.icono ===
                                    "shopping-cart" ? (
                                    <ShoppingCart size={22} weight="fill" />
                                  ) : (
                                    ""
                                  )}
                                  {plugins[0].plugin.icono !== ""
                                    ? ""
                                    : plugins[0].plugin.descripcion}
                                </button>
                              ) : (
                                <button
                                  className={"btn btn-primary btn-plugin ind1"}
                                  key={"btn-verMas-" + ind}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    verPlugins(ind);
                                  }}
                                >
                                  {pluginsShowed === ind ? <CaretUp size={22} weight='fill'/>: <CaretDown size={22} weight='fill'/>}
                                  {pluginsShowed === ind ? "Ocultar": "Ver más"}
                                </button>
                              )}
                            </div>
                          )}
                          {filtros.map((filtro, ind) => {
                            if (filtro.tipo_filtro !== "E") {
                              return (
                                <div
                                  style={
                                    Number.parseInt(filtro.ancho_web) !== 0
                                      ? {
                                          width: filtro.ancho_web + "%",
                                          flexShrink: 0,
                                        }
                                      : { display: "none" }
                                  }
                                  className={"col"}
                                  key={"col_" + ind}
                                >
                                  {fila[filtro.alias_columna]}
                                </div>
                              );
                            }
                            return "";
                          })}
                        </div>
                        {plugins.length === 0 ? null : (
                          <div
                            className="fila-plugins"
                            id={"fila-plugins-" + ind}
                            style={pluginsShowed === ind ? {display: 'flex'} : {display: 'none'}}
                          >
                            {plugins.map((plugin, indice) => (
                              <button
                                className={
                                  "btn btn-primary btn-plugin ind" + indice
                                }
                                key={plugin.plugin.codigo}
                                onClick={onPlugin}
                                data-datos={ind}
                                data-codigo={plugin.plugin.codigo}
                                data-informe={plugin.plugin.informe_llamado}
                                data-programa={plugin.plugin.programa_llamado}
                                data-report={plugin.plugin.report_llamado}
                              >
                                {" "}
                                {informes_loading ? (
                                  <Spinner showSpinner={informes_loading} />
                                ) : plugin.plugin.icono !== "" ? (
                                  <FontAwesomeIcon icon={plugin.plugin.icono} />
                                ) : (
                                  ""
                                )}
                                {plugin.plugin.descripcion}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>

            <div className="tt-shopcart-table informes_mobile">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row fila_titulo_y_botones">
                  <div className="titulo">
                    {informe !== undefined ? <h2>{informe.nombre}</h2> : ""}
                  </div>
                  <div className="botones_imprimir">
                    {permitir_excel !== undefined && permitir_excel === "S" ? (
                      <button
                        className="btn_descarga_informe"
                        onClick={descargarExcel}
                        value="XLS"
                      >
                        <FileXls size={22} weight={"regular"} />{" "}
                        {informes_loading ? (
                          <span>{i18n.t("general.cargando")}</span>
                        ) : (
                          <span>{i18n.t("informes.downexl")}</span>
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn_descarga_informe"
                      onClick={descargarPdf}
                      value="PDF"
                    >
                      <FilePdf size={22} weight={"regular"} />{" "}
                      {informes_loading ? (
                        <span>{i18n.t("general.cargando")}</span>
                      ) : (
                        <span>{i18n.t("informes.downpdf")}</span>
                      )}
                    </button>
                  </div>
                </div>

                {datos.map((fila, ind) => (
                  <div className="row" key={"fil_" + ind}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      {filtros.map((filtro, ind) => {
                        if (filtro.tipo_filtro !== "E") {
                          return (
                            <div className="row" key={ind}>
                              <div
                                className="col-6 titulos"
                                style={
                                  Number.parseInt(filtro.ancho_web) !== 0
                                    ? {
                                        width: filtro.ancho_web + "%",
                                      }
                                    : { display: "none" }
                                }
                              >
                                {filtro.titulo_columna}
                              </div>
                              <div
                                style={
                                  Number.parseInt(filtro.ancho_web) !== 0
                                    ? {
                                        width: filtro.ancho_web + "%",
                                      }
                                    : { display: "none" }
                                }
                                className={"col-6"}
                                key={"col_" + ind}
                              >
                                {fila[filtro.alias_columna]}
                              </div>
                            </div>
                          );
                        }
                        return "";
                      })}

                      {plugins.length === 0 ||
                      (fila[p_activo] !== undefined &&
                        fila[p_activo] !== null &&
                        fila[p_activo] !== "A") ||
                      (fila[p_tipo_linea] !== undefined &&
                        fila[p_tipo_linea] !== null &&
                        fila[p_tipo_linea] !== "N") ? null : (
                        <div className="row">
                          <div className=" columna-plugins">
                            {plugins.map((plugin) => {
                              return (
                                <button
                                  className="btn btn-primary btn-plugin"
                                  key={plugin.plugin.codigo}
                                  onClick={onPlugin}
                                  data-datos={ind}
                                  data-codigo={plugin.plugin.codigo}
                                  data-informe={plugin.plugin.informe_llamado}
                                  data-programa={plugin.plugin.programa_llamado}
                                  data-report={plugin.plugin.report_llamado}
                                >
                                  {plugin.plugin.icono !== "" ? (
                                    <FontAwesomeIcon
                                      icon={plugin.plugin.icono}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {plugin.plugin.descripcion}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        )}
        {/* {datos.length === Number.parseInt(numero_registros) || inicio >= Number.parseInt(numero_registros) ? (
            ""
          ) : (
            // <div className="text-center mt-5">
            //   <button onClick={masRegistros} className="btn btn-border">
            //     MOSTRAR MÁS
            //   </button>
            // </div>
            ""
          )} */}
      </div>
    </Fragment>
  );
};

export default Informes;
