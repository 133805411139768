import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { getEfacturasVentas, subirEfacturasVentas, getMasEfacturasVentas } from "../../actions/efacturas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../lang/idiomas";

export class EfacturasVentas extends Component {
  static propTypes = {
    efacturas_ventas: PropTypes.array.isRequired,
  };

  state = {
    ficheros: [],
  };

  componentDidMount() {
    this.props.getEfacturasVentas();
  }

  componentDidUpdate() {}

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  subirEfacturas = (e) => {
    e.preventDefault();

    let readers = [];
    const readFile = (file) => {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
          resolve(fr.result);
        };

        fr.onerror = function () {
          reject(fr);
        };

        fr.readAsBinaryString(file);
      });
    };

    if (this.state.ficheros.length > 0) {
      for (const selectedFile of this.state.ficheros) {
        if (selectedFile) {
          readers.push(readFile(selectedFile));
        }
      }

      Promise.all(readers).then((values) => {
        let fileArray = [];

        for (let index = 0; index < this.state.ficheros.length; index++) {
          let selectedFile = this.state.ficheros[index];
          if (selectedFile) {
            let fileName = selectedFile?.name;
            let fileType = selectedFile?.type;
            let contenido = btoa(values[index]);
            fileArray.push({
              nombre_fichero: fileName,
              contenido_fichero: contenido,
              tipo_fichero: fileType,
            });
          }
        }

        this.props.subirEfacturasVentas(fileArray);
        this.setState({ ficheros: [] });
      });
    }
  };

  limpiarFicheros = (e) => {
    e.preventDefault();
    this.setState({ ficheros: [] });
  };

  masEfacturas = (e) => {
    e.preventDefault();
    if (this.props.efacturas_ventas_next !== "") {
      this.props.getMasEfacturasVentas(this.props.efacturas_ventas_next);
    }
  };

  render() {
    let status_facturas = {
      "00": i18n.t("efactura.efspend"),
      10: i18n.t("efactura.efsinva"),
      11: i18n.t("efactura.efsvalnd"),
      12: i18n.t("efactura.efsenpva"),
      20: i18n.t("efactura.efsvalid"),
      80: i18n.t("efactura.efserin"),
      90: i18n.t("efactura.efsintli"),
    };

    return (
      <div className="container">
        <div id="tt-pageContent" className="efacturas-ventas">
          <div className="container-indent formulario-efactura">
            <div className="container container-fluid-mobile">
              <h1 className="tt-title-subpages noborder">{i18n.t("efactura.efavent")}</h1>
              <div className="form-default justify-content-center">
                <form onSubmit={this.subirEfacturas} onReset={this.limpiarFicheros}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      this.setState({ ficheros: acceptedFiles });
                    }}
                    accept={["application/pdf", "text/xml"]}
                    multiple={true}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="form-group">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />

                          {this.state.ficheros.length > 0 ? (
                            <ul className="lista-ficheros-seleccionados">
                              {this.state.ficheros.map((file) => (
                                <li key={file.name}>
                                  <FontAwesomeIcon icon={file.type === "application/pdf" ? faFilePdf : faFileInvoice} className="mr-2" />
                                  {file.name}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p>{i18n.t("efactura.nfichsel")}</p>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div class="form-group row">
                    <button type="submit" className="btn btn-primary enviar">
                      {i18n.t("efactura.envfact")}
                    </button>
                    <button type="reset" className="btn btn-primary cancelar">
                      {i18n.t("general.cancelar")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container-indent lista-efacturas">
            <div className="container container-fluid-mobile">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  {this.props.efacturas_ventas && this.props.efacturas_ventas.length > 0 ? (
                    <Fragment>
                      <div className="tt-shopcart-table efactura_ventas_desctop row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="row titulos">
                            <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("efactura.fechgrab")}</div>
                            {/* <div className="col-sm-12 col-md-1 col-lg-1">
                              SERIE NOTA DE CRÉDITO
                            </div>
                            <div className="col-sm-12 col-md-1 col-lg-1">
                              NÚMERO NOTA DE CRÉDITO
                            </div>
                            <div className="col-sm-12 col-md-1 col-lg-1">
                              FECHA NOTA DE CRÉDITO
                            </div>
                            <div className="col-sm-12 col-md-1 col-lg-1">
                              TOTAL NOTA DE CRÉDITO
                            </div> */}
                            <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("efactura.nomarchi")}</div>
                            <div className="col-sm-12 col-md-1 col-lg-1">{i18n.t("efactura.status")}</div>
                            {/* <div className="col-sm-12 col-md-1 col-lg-1">
                              FECHA FACTURA
                            </div> */}
                            <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("efactura.fechret")}</div>
                            <div className="col-sm-12 col-md-1 col-lg-1">{i18n.t("efactura.totret")}</div>
                            <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("efactura.errinteg")}</div>
                            <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("efactura.errvalid")}</div>
                          </div>

                          {this.props.efacturas_ventas.map((efactura) => {
                            return (
                              <div className="row efactura-ventas-row" key={efactura.id_detalle}>
                                <div className="col-sm-12 col-md-2 col-lg-2">
                                  {efactura.fecha_grabacion && efactura.fecha_grabacion !== "" ? new Date(Date.parse(efactura.fecha_grabacion)).toLocaleDateString(this.props.locale) : ""}
                                </div>

                                {/* <div className="col-sm-12 col-md-1 col-lg-1">
                                  {efactura.serie_nc}
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">
                                  {efactura.numero_nc}
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">
                                  {new Date(
                                    Date.parse(efactura.fecha_nc)
                                  ).toLocaleDateString(this.props.locale)}
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">
                                  {efactura.total_nc}
                                </div> */}

                                <div className="col-sm-12 col-md-2 col-lg-2">{efactura.nombre_archivo}</div>

                                <div className="col-sm-12 col-md-1 col-lg-1">{efactura.status ? status_facturas[efactura.status] : i18n.t("efactura.fadesc")}</div>

                                {/* <div className="col-sm-12 col-md-1 col-lg-1">
                                  {new Date(
                                    Date.parse(efactura.fecha_factura)
                                  ).toLocaleDateString(this.props.locale)}
                                </div> */}

                                <div className="col-sm-12 col-md-2 col-lg-2">
                                  {efactura.fecha_retencion && efactura.fecha_retencion !== "" ? new Date(Date.parse(efactura.fecha_retencion)).toLocaleDateString(this.props.locale) : ""}
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">
                                  {efactura.total_retencion && efactura.total_retencion !== ""
                                    ? new Intl.NumberFormat(this.props.locale, {
                                        style: "currency",
                                        currency: this.props.moneda,
                                      }).format(efactura.total_retencion)
                                    : ""}
                                </div>

                                <div className="col-sm-12 col-md-2 col-lg-2">{efactura.error_integracion}</div>

                                <div className="col-sm-12 col-md-2 col-lg-2">{efactura.error_validacion}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="tt-shopcart-table efacturas_ventas_mobile row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {this.props.efacturas_ventas.map((efactura) => {
                            return (
                              <div className="row efacturas-ventas-row" key={efactura.id_detalle}>
                                <div className="col-12">
                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.fechgrab")}</div>
                                    <div className="col-6">
                                      {efactura.fecha_grabacion && efactura.fecha_grabacion !== "" ? new Date(Date.parse(efactura.fecha_grabacion)).toLocaleDateString(this.props.locale) : ""}
                                    </div>
                                  </div>

                                  {/* <div className="row element-row">
                                    <div className="col-6 titulos">
                                      SERIE NOTA DE CRÉDITO
                                    </div>
                                    <div className="col-6">
                                      {efactura.serie_nc}
                                    </div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">
                                      NÚMERO NOTA DE CRÉDITO
                                    </div>
                                    <div className="col-6">
                                      {efactura.numero_nc}
                                    </div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">
                                      FECHA NOTA DE CRÉDITO
                                    </div>
                                    <div className="col-6">
                                      {new Date(
                                        Date.parse(efactura.fecha_nc)
                                      ).toLocaleDateString(this.props.locale)}
                                    </div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">
                                      TOTAL NOTA DE CRÉDITO
                                    </div>
                                    <div className="col-6">
                                      {efactura.total_nc}
                                    </div>
                                  </div> */}

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.nomarchi")}</div>
                                    <div className="col-6">{efactura.nombre_archivo}</div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.status")}</div>
                                    <div className="col-6">{efactura.status ? status_facturas[efactura.status] : "Desconocido"}</div>
                                  </div>

                                  {/* <div className="row element-row">
                                    <div className="col-6 titulos">
                                      FECHA FACTURA
                                    </div>
                                    <div className="col-6">
                                      {new Date(
                                        Date.parse(efactura.fecha_factura)
                                      ).toLocaleDateString(this.props.locale)}
                                    </div>
                                  </div> */}

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.fechret")}</div>
                                    <div className="col-6">
                                      {efactura.fecha_retencion && efactura.fecha_retencion !== "" ? new Date(Date.parse(efactura.fecha_retencion)).toLocaleDateString(this.props.locale) : ""}
                                    </div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.totret")}</div>
                                    <div className="col-6">
                                      {efactura.total_retencion && efactura.total_retencion !== ""
                                        ? new Intl.NumberFormat(this.props.locale, {
                                            style: "currency",
                                            currency: this.props.moneda,
                                          }).format(efactura.total_retencion)
                                        : ""}
                                    </div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.errinteg")}</div>
                                    <div className="col-6">{efactura.error_integracion}</div>
                                  </div>

                                  <div className="row element-row">
                                    <div className="col-6 titulos">{i18n.t("efactura.errvalid")}</div>
                                    <div className="col-6">{efactura.error_validacion}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-center mt-5">
                          {this.props.efacturas_ventas_next && this.props.efacturas_ventas_next !== "" ? (
                            <button onClick={this.masEfacturas} className="btn btn-border">
                              {i18n.t("efactura.mosmas")}
                            </button>
                          ) : (
                            <div className="text-center mt-5">
                              <button className="btn btn-border01">{i18n.t("efactura.nomasfac")}</button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  efacturas_ventas: state.efacturaVentas.efacturas_ventas,
  efacturas_ventas_next: state.efacturaVentas.next,
  efacturas_ventas_previous: state.efacturaVentas.previous,
  locale: state.portal.portal.locale,
  moneda: state.portal.portal.moneda,
});

export default connect(mapStateToProps, {
  getEfacturasVentas,
  subirEfacturasVentas,
  getMasEfacturasVentas,
})(EfacturasVentas);
