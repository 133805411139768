import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { registerLocale } from "react-datepicker";
import { getLOVExpediente, setValorCampoExpediente } from "../../actions/expedientes";
import Select from "react-select";

import es from "date-fns/locale/es";
import i18n from "../../lang/idiomas";

registerLocale("es", es);

let filtros_procesados = [];
let prev_filtros_proc;
let valores;
let filtros_keys;
let lista_filtrada = {};

export class Selector extends Component {
  static propTypes = {
    lov: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getLOVExpediente: PropTypes.func.isRequired,
    setValorCampoExpediente: PropTypes.func.isRequired,
  };

  state = { selected: null };

  cargaDatos = () => {
    // Cargamos los valores actuales a la variable de previos
    prev_filtros_proc = filtros_procesados;
    this.props.getLOVExpediente(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente, this.props.campo.codigo_lov, this.props.campo.columna, filtros_procesados);
  };

  procesarValores = (e) => {
    //Limpiamos el array
    filtros_procesados = [];
    valores = { ...this.props.filtros };

    //Eliminamos  valores innecesarios
    delete valores["filesAux"];
    delete valores["fileInput"];

    filtros_keys = Object.keys(valores);

    // Cargamos el array de filtros
    filtros_keys.forEach((codigo) => {
      filtros_procesados.push({
        codigo: codigo,
        valor: this.props.filtros[codigo] || this.props.filtros[codigo] === 0 ? this.props.filtros[codigo] : "",
      });
    });
  };

  procesaDatos = (e) => {
    // Funcion que carga datos al entrar en el campo
    e.preventDefault();
    // Comprobamos que se ha seteado el valor previamente
    if (valores) {
      // Cargamos los valores actuales en las variables
      this.procesarValores(e);
      // Si ambas variables tienen carga
      if (prev_filtros_proc && filtros_procesados) {
        let pedir = false;
        //Buscamos la lista en cuestion
        let lista = this.props.lov.filter(
          (lista_valores) =>
            lista_valores.tipo_expediente === this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente &&
            lista_valores.codigo_lov === this.props.campo.codigo_lov &&
            lista_valores.campo === this.props.campo.columna
        )[0];
        // Pedimos si no coincide longitud o no hay datos
        if (prev_filtros_proc.length !== filtros_procesados.length || !lista?.data || lista.data.length === 0) {
          pedir = true;
        } else {
          // Recuperamos el campo correspondiente a LV
          let campo_buscado = this.props.tipos_expedientes_campos.filter((campo_buscado) => campo_buscado.columna === this.props.campo.columna)[0];
          if (campo_buscado) {
            for (let campo_aux of this.props.tipos_expedientes_campos) {
              // Si son anteriores a la LV
              if (campo_aux.orden < campo_buscado.orden) {
                // Recuperamos el indice del array de filtros
                let indice_filtro_campo_aux = prev_filtros_proc.findIndex((filtro) => filtro.codigo === campo_aux.columna);
                //Comparamos valores previos y actuales
                if (indice_filtro_campo_aux !== -1) {
                  if (prev_filtros_proc[indice_filtro_campo_aux].valor !== filtros_procesados[indice_filtro_campo_aux].valor) {
                    pedir = true;
                  }
                }
              }
            }
          }
        }
        if (pedir) {
          // Llamamos a LISA para cargar los datos
          this.cargaDatos();
        }
      }
    } else {
      // Cargamos los valores actuales en las variables
      this.procesarValores(e);
      // Llamamos a LISA para cargar los datos
      this.cargaDatos();
    }
  };

  handleBlur = (e) => {
    e.preventDefault();

    let ind_campo = -1;
    // Recuperamos el indice del campo
    ind_campo = this.props.tipos_expedientes_campos.findIndex((campo) => campo.columna === this.props.campo.columna);

    if (ind_campo !== -1) {
      // Cargamos el valor guardado en el estado local al redux
      if (this.state.selected && this.state.selected !== this.props.tipos_expedientes_campos[ind_campo].valor) {
        this.props.setValorCampoExpediente(this.props.campo.columna, this.state.selected);
        this.props.handleChange(this.props.campo.columna, this.state.selected);
      }
    }
  };

  handleChange = (e) => {
    let ind_campo = -1;
    let valor_aux;
    let d_aux;
    // Recuperamos el indice del campo
    ind_campo = this.props.tipos_expedientes_campos.findIndex((campo) => campo.columna === this.props.campo.columna);
    if (this.props.campo.tipo_lov === "M") {
      for (let valor of e) {
        if (valor_aux) {
          valor_aux = valor_aux + "," + valor.value;
          d_aux = d_aux + "," + valor.label;
        } else {
          valor_aux = valor.value;
          d_aux = valor.label;
        }
      }
    } else {
      valor_aux = e.value;
      d_aux = e.label;
    }
    if (ind_campo !== -1) {
      if (this.props.tipos_expedientes_campos[ind_campo].valor !== valor_aux) {
        // Cargamos estado local
        this.setState({ selected: valor_aux });
        // Cargamos estado del redux
        this.props.setValorCampoExpediente(this.props.campo.columna, valor_aux, d_aux);
        // Cargamos estado padre ( Completar el array de filtros del resto de campos )
        this.props.handleChange(this.props.campo.columna, valor_aux);
      }
    }
  };

  render() {
    // Recorremos las lovs
    if (this.props.lov && this.props.lov.length > 0) {
      // Recuperamos la lista a tratar
      lista_filtrada = this.props.lov.filter(
        (lista_valores) =>
          lista_valores.tipo_expediente === this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente &&
          lista_valores.codigo_lov === this.props.campo.codigo_lov &&
          lista_valores.campo === this.props.campo.columna
      )[0];
    }

    // Si no existe en los filtros lo iniciamos a null
    if (!([this.props.campo.columna] in this.props.filtros)) {
      this.props.handleChange(this.props.campo.columna, null);
    }

    return (
      <Fragment>
        <div className="form-group selector" key={this.props.index}>
          <label
            className={this.props.origen === "expedientes" ? (this.props.campo.desactivado === "S" ? "campo-expediente-label-disabled" : "campo-expediente-label") : ""}
            htmlFor={this.props.campo.columna}
          >
            {this.props.campo.literal}
          </label>
          {lista_filtrada && lista_filtrada.data && lista_filtrada.data.length > 0 ? (
            <Select
              id={this.props.campo.columna}
              key={this.props.campo.columna + "-" + this.props.campo.orden}
              className={"selector-rapido"}
              onFocus={this.procesaDatos}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              isMulti={this.props.campo.tipo_lov === "M" ? true : false}
              options={lista_filtrada && lista_filtrada.data ? lista_filtrada.data : []}
              isLoading={lista_filtrada && lista_filtrada.loading ? lista_filtrada.loading : ""}
              loadingMessage={() => i18n.t("expedientes.excardat")}
              noOptionsMessage={() => i18n.t("expedientes.exnoseen")}
              placeholder={i18n.t("expedientes.exselna")}
              isDisabled={this.props.isValidating || this.props.campo.desactivado === "S" ? true : false}
            />
          ) : (
            <Select
              id={this.props.campo.columna}
              key={this.props.campo.columna + "-" + this.props.campo.orden}
              className={"selector-rapido"}
              onFocus={this.procesaDatos}
              options={[]}
              isMulti={this.props.campo.tipo_lov === "M" ? true : false}
              onBlur={this.handleBlur}
              isLoading={lista_filtrada && lista_filtrada.loading ? lista_filtrada.loading : ""}
              loadingMessage={() => i18n.t("expedientes.excardat")}
              noOptionsMessage={() => i18n.t("expedientes.exnoseen")}
              placeholder={i18n.t("expedientes.exselna")}
              value={i18n.t("expedientes.exselna")}
              isDisabled={this.props.isValidating || this.props.campo.desactivado === "S" ? true : false}
            />
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  isValidating: state.expedientes.isValidating,
  lov: state.expedientes.valores_lovs,
  auth: state.auth,
});

export default connect(mapStateToProps, { getLOVExpediente, setValorCampoExpediente })(Selector);
