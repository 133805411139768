import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomer } from '../../actions/selectorCliente'
import { getPedidoActivo } from '../../actions/pedidos'
import { obtenerValorParametro } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'

let optionsClientes = []

const SelectorCliente = props => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const ecomParametros = useSelector(state => state.portal.portal.parametros_ecom)
  const ecomClientesPermitidos = useSelector(state => state.auth.user.clientes_permitidos)
  const codigoClienteUsuario = useSelector(state => state.auth.user.id)
  const clienteSeleccionado = useSelector(state => state.selectorCliente.cliente_seleccionado)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const hash = useSelector(state => state.auth.hash)

  const p_auto_crear_pedido = obtenerValorParametro(ecomParametros, 'AUTO_CREAR_PEDIDO', 'GEN')

  const selectorChange = e => {
    dispatch(setCustomer(Number(e.target.value)))
    dispatch(
      getPedidoActivo(hash != null ? hash : '', 'S', id_pedido_seleccionado, p_auto_crear_pedido),
    )
  }

  useEffect(() => {
    window.wookie()

    if (
      isAuthenticated &&
      ecomClientesPermitidos &&
      ecomClientesPermitidos.length > 0 &&
      optionsClientes.length === 0
    ) {
      ecomClientesPermitidos.forEach(cliente =>
        optionsClientes.push({
          key: cliente.id,
          label: cliente.nombre,
          value: cliente.id,
        }),
      )

      if (
        (clienteSeleccionado === null || clienteSeleccionado === '') &&
        codigoClienteUsuario !== ''
      ) {
        dispatch(setCustomer(codigoClienteUsuario))
      }
    }
  })

  if (
    ecomClientesPermitidos !== undefined &&
    ecomClientesPermitidos.length > 0 &&
    (ecomClientesPermitidos.length > 1 ||
      (ecomClientesPermitidos.length === 1 &&
        ecomClientesPermitidos[0].id !== codigoClienteUsuario))
  ) {
    return (
      <div className='tt-dropdown-menu'>
        <div className='tt-mobile-add'>
          <h6 className='tt-title'>{i18n.t('general.cliente').toUpperCase()}</h6>
          <button className='tt-close'>{i18n.t('general.cerrar')}</button>
        </div>
        <div className='tt-dropdown-inner'>
          <div className='tt-client-select-layout'>
            <div className='tt-client-select-content'>
              <div className='selector-cliente'>
                <label htmlFor={'select-clientes'}>{i18n.t('general.cliente')}</label>
                <select
                  className='form-control'
                  name='select-clientes'
                  id='select-clientes'
                  onChange={selectorChange}
                  defaultValue={codigoClienteUsuario}>
                  {ecomClientesPermitidos.map(cliente => {
                    if (cliente.codigo_cliente) {
                      return (
                        <option
                          key={cliente.id}
                          value={cliente.id}>
                          {cliente.descripcion_web_permiso !== undefined
                            ? cliente.descripcion_web_permiso
                            : cliente.nombre}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

export default SelectorCliente
