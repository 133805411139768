import React, { Component } from "react";
import Spinner from "../../layout/Spinner";
import { connect } from "react-redux";
import { ArrowLineLeft, ShoppingCart, X } from "phosphor-react";
import Precio from "../../ecommerce/Precio";
import { Link } from "react-router-dom";
import { Plus, Minus } from "phosphor-react";
import { setPlantillaSeleccionada, toglePlantillaDetails, crearPedido, togglePostLoading } from "../actions/plantillas";
import { postLinea } from "../../../actions/pedidos";
import { createMessage } from "../../../actions/messages";
import { limpiarPresentacion } from "../../../actions/presentaciones";
import { CANTIDAD_NO_VALIDA, NO_ESTA_EL_PRECIO } from "../functions/addToCart";
import DatosPlantilla from "./DatosPlantilla";
import i18n from "../../../lang/idiomas";
import { generarLineasPedidos } from "../functions/addToCart";
import { postLineas, setIdPedidoSeleccionado, getPedidoActivo } from "../../../actions/pedidos";

let cantidades = {};

class DetallesPlantilla extends Component {
  state = {
    pedido_inicial: null,
    responses: [],
    current_values: [],
  };

  createLinesBulk = (id_pedido) => {
    const articulos_cantidades = this.props.plantilla_details.articulos.map((articulo, index) => {
      const input = document.querySelector("#in-" + articulo.codigo_articulo);
      if (!input.value) throw new Error("CANTIDAD NO VALIDA");
      return {
        articulo,
        cantidad: parseFloat(input.value),
        cantidad_minima: this.props.plantilla_details.plantilla_det[index].cantidad_minima,
      };
    });

    try {
      const lineasGeneradas = generarLineasPedidos(
        articulos_cantidades,
        this.props.pedido,
        "P",
        this.props.usuario,
        this.props.hash,
        this.props.precio,
        this.props.presentacion,
        this.props.subpresentacion
      );
      //alert(this.props.pedido.id_pedido);
      this.props.postLineas(lineasGeneradas, this.props.hash ?? "", id_pedido, this.failedLinesCreation);
    } catch (error) {
      console.error({ error });
    }
  };

  failedLinesCreation() {
    this.props.setIdPedidoSeleccionado(this.state.pedido_inicial);
    this.props.getPedidoActivo("", "S", this.state.pedido_inicial);
  }

  sendResponse = (data) => {
    this.setState((state) => {
      return { ...state, responses: [...state.responses, data.message] };
    });
  };

  onCantidadChange(e, element) {
    cantidades[element.codigo_articulo] = e.target.value;
  }

  componentWillUnmount() {
    this.props.setPlantillaSeleccionada(null);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.pedido_inicial && this.props.pedido?.id_pedido) {
      this.setState((state) => {
        return {
          ...state,
          pedido_inicial: this.props.pedido.id_pedido,
        };
      });
    }
    if (this.props.plantilla_details !== prevProps.plantilla_details) {
      const mapped_elements = this.props.plantilla_details.plantilla_det.map((element) => {
        return element.cantidad_minima;
      });

      this.props.togglePostLoading(false);

      this.setState((state) => {
        return {
          ...state,
          current_values: mapped_elements,
        };
      });
    }
  }

  render() {
    return (
      <>
        <DatosPlantilla />
        <div className="tt-shopcart-table listas_compra_detalle_desctop row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row titulos">
              <div className="col-sm-12 col-md-2 col-lg-3">{i18n.t("plantillas.parti")}</div>
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pdescrip")}</div>
              <div className="col-sm-12 col-md-2 col-lg-3">{i18n.t("plantillas.plprecio")}</div>
              <div className="col-sm-12 col-md-1 col-lg-3">{i18n.t("plantillas.pcantid")}</div>
            </div>
            <span
              style={{
                display: "block",
                background: "#2b2b2b",
                opacity: 0.12,
                marginBottom: "10px",
                width: "100%",
                height: "2px",
                marginTop: "5px",
                borderRadius: "1000px",
              }}
            ></span>
            {this.props.plantilla_details ? (
              this.props.plantilla_details.articulos.map((element, index) => {
                return (
                  <div key={`art${element.id}-l1`} className="row lista-plantilla-row">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Link
                        to={`/articulo/${element.codigo_articulo}/${
                          element.descrip_comercial ? element.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : ""
                        }`}
                      >
                        {element.codigo_articulo}
                      </Link>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">{element.descrip_comercial}</div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Precio cantidad={1} codigo_articulo={element.codigo_articulo} presentacion={element.presentacion_web} origen="ficha" />
                    </div>
                    <div className="row col-sm-12 col-md-3 col-lg-3">
                      <div className="row col-sm-12 col-md-12 col-lg-12 actions">
                        <button
                          type="button"
                          className="col-2"
                          onClick={() => {
                            this.setState((state) => {
                              const copied_values = state.current_values;
                              const cantidad_minima = this.props.plantilla_details.plantilla_det[index].cantidad_minima;

                              let new_value = copied_values[index] ? copied_values[index] - cantidad_minima : cantidad_minima;
                              new_value = new_value < cantidad_minima ? cantidad_minima : new_value;

                              copied_values[index] = new_value;

                              return {
                                ...state,
                                current_values: copied_values,
                              };
                            });
                          }}
                        >
                          <Minus size={22} weight={"bold"} />
                        </button>
                        <input
                          className="quantity-input input-cantidad-listas prueba col-7"
                          onChange={(event) => {
                            this.setState((state) => {
                              const coppied_values = state.current_values;

                              let new_value = parseFloat(event.target.value);

                              coppied_values[index] = new_value;

                              return {
                                ...state,
                                current_values: coppied_values,
                              };
                            });
                          }}
                          type="number"
                          min={this.props.plantilla_details.plantilla_det[index].cantidad_minima}
                          value={this.state.current_values[index]}
                          step={1}
                          tabIndex={1}
                          name="cantidad"
                          id={"in-" + element.codigo_articulo}
                          key={element.codigo_articulo}
                          // value={this.props.plantilla_details.plantilla_det[index].cantidad_minima ?? 0}
                          placeholder={this.props.plantilla_details.plantilla_det[index].cantidad_minima}
                          size="5"
                          // onChange={this.onChange}
                        />
                        <button
                          type="button"
                          className="col-2"
                          onClick={() => {
                            this.setState((state) => {
                              const copied_values = state.current_values;
                              const cantidad_minima = this.props.plantilla_details.plantilla_det[index].cantidad_minima;

                              let new_value = copied_values[index] ? copied_values[index] + cantidad_minima : cantidad_minima;
                              new_value = new_value < cantidad_minima ? cantidad_minima : new_value;

                              copied_values[index] = new_value;

                              return {
                                ...state,
                                current_values: copied_values,
                              };
                            });
                          }}
                        >
                          <Plus size={22} weight={"bold"} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_mobile row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            {this.props.plantilla_details ? (
              this.props.plantilla_details.articulos.map((element, index) => {
                return (
                  <div className="row lista-compra-row" key={"line" + element.descrip_comercial}>
                    <div className="col-12">
                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.parti")}</div>
                        <div className="col-7">
                          <Link
                            to={`/articulo/${element.codigo_articulo}/${
                              element.descrip_comercial ? element.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : ""
                            }`}
                          >
                            {element.codigo_articulo}
                          </Link>
                        </div>
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.pdescrip")}</div>
                        <div className="col-7">{element.descrip_comercial}</div>
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.plprecio")}</div>
                        <Precio cantidad={1} codigo_articulo={element.codigo_articulo} presentacion={element.presentacion_web} origen="ficha" />
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.pcantid")}</div>
                        <div className="col-7">
                          <input
                            className="quantity-input input-cantidad-listas prueba"
                            type="number"
                            name="cantidad"
                            id={"in-" + element.codigo_articulo}
                            key={"elemInp" + element.codigo_articulo}
                            value={cantidades[element.codigo_articulo ?? null]}
                            placeholder={this.props.plantilla_details.plantilla_det[index].cantidad_minima}
                            size="5"
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        {this.props.plantilla_details?.articulos?.length === 0 ? (
          <span className="plantilla-vacia">{this.props.isLoading ? i18n.t("plantillas.pcardato") : i18n.t("plantillas.pnoartep")}</span>
        ) : (
          <></>
        )}
        <span style={{ marginTop: "40px", display: "block" }}></span>

        {this.state.responses.length > 0 ? (
          <div className="alert-holder container">
            <div className="row messages-titles-plantilla">
              <div className="col-11">{i18n.t("plantillas.pmsg")}</div>
              <div
                onClick={() => {
                  this.setState((state) => {
                    return {
                      ...state,
                      responses: [],
                    };
                  });
                }}
                className="col-1 exit-holder"
              >
                <X />
              </div>
            </div>
            {this.state.responses.map((element) => {
              return (
                <div key={"alert-error" + element} class="alert alert-danger" role="alert">
                  {element}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        {this.props.loadingPost ? (
          <div className="spinner-holder">
            <Spinner showSpinner={this.props.loadingPost || this.props.isLoading || this.props.pedidos_loading} />
          </div>
        ) : (
          <></>
        )}
        <div className="row btn-holder">
          <button
            disabled={this.props.loadingPost || this.props.isLoading || this.props.plantilla_details?.articulos?.length === 0}
            onClick={() => {
              let stop = false;
              this.props.togglePostLoading(true);
              if (this.props.plantilla_details && this.props.plantilla_details.plantilla_det[0]) {
                this.setState((state) => {
                  return { ...state, responses: [] };
                });
                const articulos = this.props.plantilla_details.articulos;
                for (let i = 0; i < articulos.length; i++) {
                  stop = false;
                  const input = document.querySelector("#in-" + articulos[i].codigo_articulo);
                  if (!input.value || this.props.plantilla_details.plantilla_det[i].cantidad_minima > input.value) {
                    this.sendResponse({
                      articulo: articulos[i].codigo_articulo,
                      message: CANTIDAD_NO_VALIDA + `${i18n.t("plantillas.pdelarti")} ` + articulos[i].descrip_comercial,
                    });
                    this.props.togglePostLoading(false);
                    stop = true;
                  }
                  const artEnPrecio = this.props.precio[articulos[i].codigo_articulo];
                  const EXISTE_PRECIO_PEDIDO = artEnPrecio && artEnPrecio[articulos[i].presentacion_web];
                  if (!EXISTE_PRECIO_PEDIDO) {
                    this.sendResponse({
                      articulo: articulos[i],
                      message: NO_ESTA_EL_PRECIO + `${i18n.t("plantillas.pdelarti")} ` + articulos[i].descrip_comercial,
                    });
                    this.props.togglePostLoading(false);
                    stop = true;
                  }
                  if (i === articulos.length - 1 && stop) {
                    this.props.togglePostLoading(false);
                    return;
                  }
                }

                this.props.crearPedido(this.props.plantilla_details.plantilla_det[0].codigo_plantilla, this.createLinesBulk);
              }
            }}
            className="btn btn-primary btn-crear-pedido-plantilla col-md-2 col-lg-2 col-sm-12"
          >
            {this.props.loadingPost ? i18n.t("plantillas.pcreando") : i18n.t("plantillas.pcrear")}{" "}
          </button>
        </div>
        <span className="cart-warn-plantillas">{i18n.t("plantillas.pclcart")}</span>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  plantilla_details: state.plantillas.plantilla_details,
  precio: state.precios.precio,
  usuario: state.auth.user,
  subpresentacion: state.subpresentaciones.subpresentacion,
  presentacion: state.presentaciones.presentacion,
  pedido: state.pedidos.pedido,
  pedido_plantilla: state.plantillas.pedido_creado_plantilla,
  hash: state.auth.hash,
  pedidos_loading: state.pedidos.isLoading || state.pedidos.isLoadingLines,
  isLoading: state.plantillas.isLoading,
  loadingPost: state.plantillas.loadingPost,
});
export default connect(mapStateToProps, {
  toglePlantillaDetails,
  crearPedido,
  postLinea,
  limpiarPresentacion,
  createMessage,
  togglePostLoading,
  setPlantillaSeleccionada,
  postLineas,
  setIdPedidoSeleccionado,
  getPedidoActivo,
})(DetallesPlantilla);
