import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPersonaPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
 
const PersonaPedido = (props) => {
  const dispatch = useDispatch()
  
  const pedido_cargando = useSelector((state) => state.pedidos.isLoading)
  const pedido = useSelector((state) => state.pedidos.pedido)
 
 
  const onChange = (e) => {
    if (!pedido_cargando) {
      dispatch(setPersonaPedido(e.target.value));
    }
  };
 
 
  return (
    <form className="form-default">
      <div className="form-group">
        <label htmlFor="persona_pedido">{i18n.t("pedido.ppersped")}</label>
        <input
          className="form-control"
          type="text"
          name="persona_pedido"
          id="persona_pedido"
          onChange={onChange}
          value={pedido?.persona_pedido || ""}
          placeholder={i18n.t("pedido.pperpemi")}
          disabled={props.pedidoGuardado || pedido_cargando}
        />
      </div>
    </form>
  );
}
 
export default PersonaPedido