import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

export class RedesSociales extends Component {
  static propTypes = {
    redes: PropTypes.array,
  }

  render() {
    const { redes } = this.props
    return (
      <div className='redes_sociales'>
        <div className='redes_sociales_container'>
          {redes
            ? redes.map(red => {
                const estiloCaja = {}
                estiloCaja['color'] = red.color && red.color !== '' ? `#${red.color}` : '#ffffff'

                return (
                  <div
                    key={red.id}
                    className='red_social_caja'>
                    <a
                      href={red.url}
                      target='_blank'
                      style={estiloCaja}
                      rel='noopener noreferrer'>
                      {red.icono && red.icono !== '' ? (
                        <FontAwesomeIcon icon={['fab', red.icono]} />
                      ) : (
                        red.nombre
                      )}
                    </a>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  redes: state.portal.portal.redes_sociales,
})

export default connect(mapStateToProps)(RedesSociales)
