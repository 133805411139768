import { SET_ARTICULO, SET_OPEN, SET_CLOSE, SET_LINEAS, SET_LINEA_PED, SET_CARNET_PROFESIONAL, NEW_RECIPE } from "../actions/types.js";

const initialState = {
  isOpen: false,
  codigo_articulo: "",
  origen: "",
  lineas: [],
  cantidad_linea: 1,
  presentacion: ""
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ARTICULO:
      return {
        ...state,
        origen: "",
        codigo_articulo: action.payload.codigo_articulo,
        cantidad_linea: action.payload.cantidad_linea,
        presentacion: action.payload.presentacion,
        lineas:[],
        isOpen: true,
      };

    case NEW_RECIPE:
    case SET_LINEA_PED:
      return {
        ...state,
        codigo_articulo: action.payload.linea.articulo,
        origen: action.payload.origen,
        lineas: action.payload.linea,
        cantidad_linea: 1,
        presentacion: "",
        isOpen: true,
      };

    case SET_CARNET_PROFESIONAL:
      return {
        ...state,
        codigo_articulo: "",
        origen: action.payload.origen,
        lineas: action.payload.linea,
        cantidad_linea: 1,
        presentacion: "",
        isOpen: true,
      };

    case SET_LINEAS:
      return {
        ...state,
        lineas: action.payload,
        codigo_articulo: "",
        cantidad_linea: 1,
        presentacion: "",
        origen: "",
        isOpen: true,
      };
    case SET_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case SET_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
