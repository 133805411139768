import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import {
  getArticulo,
  getArticuloSuccess,
  limpiarArticuloSeleccionado,
} from '../../actions/articulo'
import { getStockArticulo } from '../../actions/stocks'
import { cargarParametros, programaActivo } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'
import AnadirCarrito from './AnadirCarrito'
import ArticulosConjunto from './ArticulosConjunto'
import ArticulosEquivalentes from './ArticulosEquivalentes'
import ArticulosKit from './ArticulosKit'
import ArticulosRelacionados from './ArticulosRelacionados'
import ArticulosSustitutos from './ArticulosSustitutos'
import BotonAnadirLista from './BotonAnadirLista'
import FamiliasMenu from './FamiliasMenu'
import Galeria from './Galeria'
import Migas from './Migas'
import Precio from './Precio'
import AgrupacionFiltros from './parciales/AgrupacionFiltros'
import InfoAdicional from './parciales/InfoAdicional'
import InfoClavesEstadisticas from './parciales/InfoClavesEstadisticas'
import OfertasMultiples from './parciales/OfertasMultiples'
import Presentaciones from './parciales/Presentaciones'
import Subpresentaciones from './parciales/Subpresentaciones'

// Variables globales
let parametros
let id_articulo
let anadir_lista = false
let parametros_usados = [
  'mostrar_iva',
  'tipo_precio',
  'stock_cantidad',
  'btn_subs',
  'mostrar_pres',
  'estilo_precio',
]

const Articulo = props => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const articulo = useSelector(state => state.articulo.articulo)
  const menus = useSelector(state => state.portal.menus)
  const auth = useSelector(state => state.auth)
  const pedido = useSelector(state => state.pedidos.pedido)
  const precio = useSelector(state => state.precios.precio)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const cargandoArticulo = useSelector(state => state.articulo.cargandoArticulo)
  const presentacion = useSelector(state => state.presentaciones.presentacion)

  // Recuperamos datos URL
  let url_params = useParams()
  id_articulo = props.codigo_articulo || url_params.id_articulo

  // Cargamos parametros del modulo
  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'ART')

  if (menus) {
    // Comprobamos si existen listas
    anadir_lista = programaActivo(menus, 'listasCompra')
  }

  useEffect(() => {
    return () => {
      dispatch(limpiarArticuloSeleccionado())
    }
  }, [])

  useEffect(() => {
    if (cargandoArticulo === 'loaded') {
      dispatch(getArticuloSuccess())
    }
  }, [cargandoArticulo])

  useEffect(() => {
    if (
      (cargandoArticulo === 'idle' || cargandoArticulo === 'loaded') &&
      articulo.codigo_articulo
    ) {
      dispatch(
        getStockArticulo(
          articulo.codigo_articulo,
          presentacion?.presentacion || articulo.presentacion_web,
          pedido.almacen,
          id_pedido_seleccionado,
          parametros.stock_cantidad,
        ),
      )
    }
  }, [presentacion])

  useEffect(() => {
    window.wookie()

    if (
      ((articulo?.codigo_articulo && id_articulo !== articulo.codigo_articulo) ||
        !articulo.codigo_articulo) &&
      cargandoArticulo === 'idle'
    ) {
      dispatch(getArticulo(id_articulo, auth.hash != null ? auth.hash : ''))
    }

    let analytics = new AnalyticsEdisa()

    analytics.registraEvento({
      modulo: 'Articulos',
      tipo: 'ViewContent',
      valor: articulo.codigo_articulo,
      params: {
        codigo_articulo: articulo.codigo_articulo,
        descrip_comercial: articulo.descrip_comercial,
        divisa: precio?.[articulo.codigo_articulo]?.[
          presentacion?.presentacion || articulo.presentacion_web
        ]
          ? precio[articulo.codigo_articulo][
              presentacion?.presentacion || articulo.presentacion_web
            ].divisa_precio
          : null,
        precio: precio?.[articulo.codigo_articulo]?.[
          presentacion?.presentacion || articulo.presentacion_web
        ]
          ? parseFloat(
              precio[articulo.codigo_articulo][
                presentacion?.presentacion || articulo.presentacion_web
              ].importe_neto,
            )
          : null,
      },
    })
  }, [articulo.id, articulo.codigo_articulo, id_articulo, cargandoArticulo, presentacion])

  return (
    <Fragment>
      <FamiliasMenu />
      <div className='container'>
        <Migas id_articulo={id_articulo} />
        <div
          id='tt-pageContent'
          className='articulo'
          itemScope
          itemType='https://schema.org/Product'>
          <meta
            itemProp='url'
            content={'/articulo/' + id_articulo + '/' + articulo.descrip_comercial}
          />
          <meta
            itemProp='sku'
            content={articulo.codigo_articulo}
          />
          <div className='container-indent'>
            <div className='container container-fluid-mobile'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  {articulo.codigo_articulo ? (
                    <Galeria
                      codigo_articulo={articulo.codigo_articulo}
                      origen='desktop'
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='tt-product-single-info'>
                    <div className='tt-add-info'>
                      <ul>
                        <li
                          itemProp='productID'
                          content={articulo.codigo_articulo}>
                          <span>{i18n.t('general.codart')}</span> {articulo.codigo_articulo}
                        </li>
                      </ul>
                    </div>
                    <h1
                      className='tt-title'
                      itemProp='name'>
                      {articulo.descrip_comercial}
                    </h1>
                    {articulo.descrip_web_reducida && articulo.descrip_web_reducida !== '' ? (
                      <div className='tt-wrapper'>
                        <span className='descrip_web_reducida'>
                          {articulo.descrip_web_reducida}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    {articulo.codigo_articulo && anadir_lista === true ? (
                      <div className='tt-wrapper'>
                        <ul className='tt-list-btn'>
                          <li className='list-btn-art'>
                            {articulo.codigo_articulo && anadir_lista === true ? (
                              <BotonAnadirLista
                                origen='articulo'
                                btn_subs={parametros.btn_subs}
                                codigo_articulo={articulo.codigo_articulo}
                                presentacion={
                                  presentacion.presentacion || articulo.presentacion_web
                                }
                              />
                            ) : (
                              ''
                            )}
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                    {articulo.tiene_agrupaciones === 'S' ? (
                      <Fragment>
                        {articulo.codigo_articulo ? (
                          <AgrupacionFiltros codigo_articulo={articulo.codigo_articulo} />
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className='tt-price'>
                          <span className='new-price'>
                            {articulo.codigo_articulo ? (
                              <Precio
                                codigo_articulo={articulo.codigo_articulo}
                                presentacion={
                                  presentacion.presentacion || articulo.presentacion_web
                                }
                                mostrar_iva={parametros.mostrar_iva}
                                mostrar_pres={parametros.mostrar_pres}
                                estilo_precio={parametros.estilo_precio}
                                tipo_precio={parametros.tipo_precio}
                                origen='ficha'
                              />
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        <div className='tt-wrapper'>
                          {articulo.codigo_articulo ? (
                            <Fragment>
                              <Subpresentaciones codigo_articulo={articulo.codigo_articulo} />
                              <Presentaciones
                                articulo={articulo.codigo_articulo}
                                presentacion_defectiva={articulo.presentacion_web}
                              />
                              <AnadirCarrito
                                articulo={articulo}
                                mostrar_texto_btn='S'
                                origen='ficha'
                              />
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                        {articulo.codigo_articulo ? (
                          <OfertasMultiples articulo={articulo.codigo_articulo} />
                        ) : (
                          ''
                        )}
                        <div className='tt-collapse-block'>
                          <div className='tt-item active'>
                            <div className='tt-collapse-title'>
                              {i18n.t('general.descrip').toUpperCase()}
                            </div>
                            <div
                              className='tt-collapse-content'
                              style={{ display: 'block' }}
                              dangerouslySetInnerHTML={
                                articulo.descrip_web_ampliada
                                  ? {
                                      __html: articulo.descrip_web_ampliada.replaceAll(
                                        '\n',
                                        '<br>',
                                      ),
                                    }
                                  : { __html: '' }
                              }
                              itemProp='description'></div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {articulo.tiene_agrupaciones === 'S' ? (
            <div className='container-indent'>
              <div className='container container-fluid-mobile'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='tt-collapse-block'>
                      <div className='tt-item active'>
                        <div className='tt-collapse-title'>
                          {i18n.t('general.descrip').toUpperCase()}
                        </div>
                        <div
                          className='tt-collapse-content'
                          style={{ display: 'block' }}
                          dangerouslySetInnerHTML={
                            articulo.descrip_web_ampliada
                              ? {
                                  __html: articulo.descrip_web_ampliada.replaceAll('\n', '<br>'),
                                }
                              : { __html: '' }
                          }></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {articulo.codigo_articulo ? (
                <InfoClavesEstadisticas codigo_articulo={articulo.codigo_articulo} />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {articulo.codigo_articulo ? (
            <InfoAdicional codigo_articulo={articulo.codigo_articulo} />
          ) : (
            ''
          )}
          {articulo.tipo_material === 'K' ? (
            <ArticulosKit
              id_articulo={articulo.codigo_articulo}
              articulo_padre={articulo}
            />
          ) : (
            <></>
          )}
          {articulo.tipo_material === 'C' ? <ArticulosConjunto articulo_padre={articulo} /> : <></>}
          {articulo.id ? (
            <ArticulosRelacionados
              id_articulo={articulo.id}
              origen='ficha'
            />
          ) : (
            ''
          )}
          {articulo.id ? (
            <ArticulosEquivalentes
              id_articulo={articulo.codigo_articulo}
              origen='ficha'
            />
          ) : (
            ''
          )}
          {articulo.id ? (
            <ArticulosSustitutos
              id_articulo={articulo.codigo_articulo}
              origen='ficha'
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </Fragment>
  )
}
export default Articulo
