import React, { useEffect, Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlmacenes } from '../../actions/almacenes'
import { nuevoPedido } from '../../actions/pedidos'
import i18n from '../../lang/idiomas'

const SelectorAlmacen = props => {
  const dispatch = useDispatch()

  const almacenesObtenidos = useSelector(state => state.almacenes.almacenes)
  const cargandoAlmacenes = useSelector(state => state.almacenes.isLoading)

  const [almacenSeleccionado, setAlmacen] = useState()
  const [facturar, setFacturar] = useState(false)

  const selectorAlmacenChange = e => {
    setAlmacen(e.target.value)
  }

  const checkFacturar = e => {
    setFacturar(e.target.checked)
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(nuevoPedido(null, almacenSeleccionado, facturar))
  }

  useEffect(() => {
    window.wookie()
    if (!cargandoAlmacenes && almacenesObtenidos.length === 0) {
      dispatch(getAlmacenes())
    }
  }, [almacenesObtenidos, cargandoAlmacenes, dispatch])

  return (
    <Fragment>
      <div className='container container-selector-almacen'>
        <form id='form-selector-almacen'>
          <h6>{i18n.t('selectorAlmacen.seldatos')}</h6>
          <div
            id='formAlmacenes'
            className='form-group'>
            {almacenesObtenidos.length > 0 ? (
              <div key={'select-almacen'}>
                <label htmlFor={'list-item-almacenes'}>{i18n.t('general.almacen')}</label>
                <select
                  className='form-control'
                  name='list-item-almacenes'
                  id='list-item-almacenes'
                  onChange={selectorAlmacenChange}
                  defaultValue={''}>
                  <option
                    key={0}
                    value=''>
                    {i18n.t('selectorAlmacen.selalmac')}
                  </option>
                  {almacenesObtenidos.map(almacen => {
                    return (
                      <option
                        key={almacen.id}
                        value={almacen.almacen}>
                        {almacen.nombre}
                      </option>
                    )
                  })}
                </select>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='checkbox'>
            <label
              className='form-check-label'
              htmlFor='checkboxFacturar'>
              {i18n.t('general.facturar')}
            </label>
            <input
              type='checkbox'
              id='checkboxFacturar'
              name='checkboxFacturar'
              className='check-facturar'
              onChange={checkFacturar}
              checked={facturar !== undefined && facturar !== null ? facturar : false}
            />
          </div>
          <div className='row'>
            <div className='col-auto mr-auto'>
              <div className='form-group'>
                <button
                  type='submit'
                  className='btn btn-primary btn-iniciar-pedido'
                  onClick={onSubmit}>
                  {i18n.t('pedido.piped')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}
export default SelectorAlmacen
