import { SET_ARTICULO, SET_LINEAS } from "../actions/types.js";

export const setArticulo =
  (id_articulo, cantidad_articulo, presentacion) => (dispatch) => {
    let body = {
      codigo_articulo: id_articulo,
      cantidad_linea: cantidad_articulo,
      presentacion: presentacion,
    };
    dispatch({
      type: SET_ARTICULO,
      payload: body,
    });
  };

export const setLineas = (lineas) => (dispatch) => {
  dispatch({
    type: SET_LINEAS,
    payload: lineas,
  });
};
