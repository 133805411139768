import React, { useRef, useEffect, Fragment } from "react";
import { getTiposPedido, setTipoPedido } from "../../actions/tiposPedido";
import i18n from "../../lang/idiomas";
import { useDispatch, useSelector } from "react-redux";

const TiposPedido = (props) => {
  const dispatch = useDispatch();
  const tipos_pedido = useSelector((state) => state.tiposPedido.tipos_pedido);
  const tipo_pedido = useSelector((state) => state.tiposPedido.tipo_pedido);
  const pedido_cargando = useSelector((state) => state.pedidos.isLoading);
  const pedido = useSelector((state) => state.pedidos.pedido);

  useEffect(() => {
      dispatch(getTiposPedido());
  }, []);

  useEffect(() => {
    if (!pedido_cargando) {
      if (tipos_pedido?.length === 1) {
        dispatch(setTipoPedido(tipos_pedido[0]));
      }

      if (tipos_pedido?.length > 1 && pedido.tipo_pedido) {
        let tipo_pedido_seleccionado = tipos_pedido.filter(
          (tipo_pedido) => tipo_pedido.tipo_pedido === pedido.tipo_pedido
        );

        dispatch(setTipoPedido(tipo_pedido_seleccionado[0]));
      }
    }
  }, [tipos_pedido]);

  const onChange = (e) => {
    if (e.target.value !== "") {
      const tipo_pedido = tipos_pedido.filter(
        (tipo_pedido) => tipo_pedido.tipo_pedido === e.target.value
      );

      dispatch(setTipoPedido(tipo_pedido[0]));
    }
  };

  return (
    <Fragment>
      {tipos_pedido && tipos_pedido.length > 0 ? (
        <div className="tt-shopcart-box">
          <h4 className="tt-title">{i18n.t("pedido.ptipoped")}</h4>
          <p>{i18n.t("pedido.pseltped")}</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="tipo_pedido">
                {i18n.t("pedido.ptipoped")}
                <sup>*</sup>
              </label>

              <select
                className="form-control"
                name="tipo_pedido"
                onChange={onChange}
                disabled={
                  pedido_cargando || props.pedidoGuardado
                }
                value={tipo_pedido?.tipo_pedido || ""}
              >
                <option key="0" value="">
                  {i18n.t("pedido.pseltped")}
                </option>
                {tipos_pedido.map((tipo_pedido, index) => {
                    return (
                      <option key={index} value={tipo_pedido.tipo_pedido}>
                        {tipo_pedido.descripcion}
                      </option>
                    );
                })}
              </select>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default TiposPedido;
