import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { Trash, Bell, BellRinging, BellZ } from "phosphor-react";
import i18n from "../../lang/idiomas";
import { actualizaSubscripcionStock, creaSubscripcionStock } from "../../actions/subscripcionesStock";

export class SubscripcionesStock extends Component {
  static propTypes = {
    actualizaSubscripcionStock: PropTypes.func.isRequired,
    creaSubscripcionStock: PropTypes.func.isRequired,
    subscripciones: PropTypes.object,
  };

  pintarTabla(subscripciones) {
    const tabla_subs = (
      <Fragment>
        <div className="tabla-subscripciones">
          <div className="content-indent">
            <div className="tt-subscriber-table tabla_subscripciones_desctop row">
              <div className="col-sm-12 col-md-12 col-lg-12 tabla_subscripciones_desctop_int">
                <div className="row titulos">
                  <div className="col-sm-2 col-md-2 col-lg-2">{i18n.t("general.articulo")}</div>
                  <div className="col-sm-3 col-md-3 col-lg-3">{i18n.t("general.descrip")}</div>
                  <div className="col-sm-3 col-md-3 col-lg-3">{i18n.t("subscripciones.fechulcn")}</div>
                  <div className="col-sm-2 col-md-2 col-lg-2">{i18n.t("general.estado")}</div>
                  <div className="col-sm-2 col-md-2 col-lg-2 titulo-accion">{i18n.t("subscripciones.avisouni")}</div>
                </div>
                {subscripciones && subscripciones.lineas
                  ? subscripciones.lineas.map((linea, index) => {
                      return (
                        <div className="row linea" id={linea.codigo_articulo + index} key={linea.codigo_articulo + index}>
                          <div className="col-sm-2 col-md-2 col-lg-2 articulo">
                            <Link to={"/articulo/" + linea.codigo_articulo + "/ "}>{linea.codigo_articulo}</Link>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3 descripcion">{linea.descripcion}</div>
                          <div className="col-sm-3 col-md-3 col-lg-3">{linea.fecha_ult_comp}</div>
                          <div className="col-sm-2 col-md-2 col-lg-2">
                            {linea.status === "ACT" ? (
                              <div className="status_act">
                                <BellRinging size={20} weight="duotone" alt={i18n.t("subscripciones.altsubac")} />
                                {i18n.t("general.activa")}
                              </div>
                            ) : (
                              <div className="status_des">
                                <BellZ size={20} alt={i18n.t("subscripciones.altsubin")} />
                                {i18n.t("general.inactiva")}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2 acciones">
                            <input
                              type="checkbox"
                              name="miCheck"
                              className="aviso-unico-check"
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.actualizaSubscripcionStock(linea.codigo_articulo, linea.status, linea.aviso_unico === "S" ? "N" : "S", "status");
                              }}
                              defaultChecked={linea.aviso_unico === "S" ? true : false}
                            />
                            <button
                              className="btn eliminar-subscripcion-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.actualizaSubscripcionStock(linea.codigo_articulo, linea.status, linea.aviso_unico, "borrado");
                              }}
                            >
                              <Trash size={20} alt={i18n.t("subscripciones.altdelsb")} />
                            </button>
                            <button
                              className="btn reactivar-subscripcion-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.actualizaSubscripcionStock(linea.codigo_articulo, linea.status, linea.aviso_unico, "renew");
                              }}
                              disabled={linea.status !== "ACT" ? false : true}
                            >
                              <BellRinging size={20} alt={i18n.t("subscripciones.altrensb")} />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );

    if (subscripciones) {
      if (subscripciones.isLoading === true) {
        return <Spinner showSpinner={subscripciones.isLoading}></Spinner>;
      } else if (subscripciones.lineas && subscripciones.lineas.length > 0) {
        return tabla_subs;
      }
    }
    return i18n.t("subscripciones.nosubs");
  }

  comprobarSubscripcion(subscripciones) {
    if (subscripciones && subscripciones.lineas && subscripciones.lineas.length > 0) {
      let existe_sub = false;
      let sub_aux = null;
      subscripciones.lineas.forEach((sub) => {
        if (sub.codigo_articulo === this.props.codigo_articulo) {
          existe_sub = true;
          sub_aux = sub;
        }
        if (existe_sub === true) {
          return;
        }
      });

      return sub_aux;
    } else {
      return null;
    }
  }

  pintarBoton(subscripciones) {
    let sub_aux = this.comprobarSubscripcion(subscripciones);

    if (sub_aux) {
      //Botones para "eliminar" o desactivar la susbcripcion
      switch (this.props.origen) {
        case "articulos":
        case "pedido_habitual":
        case "ofertas":
        case "novedades":
        case "equivalentes":
        case "relacionados":
          if (sub_aux.status === "ACT") {
            return (
              <button
                className="boton-subscribe-off"
                data-tposition="left"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.actualizaSubscripcionStock(
                    this.props.codigo_articulo, // codigo_articulo
                    sub_aux.status, //status
                    sub_aux.aviso_unico, //aviso_unico
                    "articulos"
                  );
                }}
              >
                <BellRinging size={20} weight={"duotone"} alt={i18n.t("subscripciones.altdelsb")} />
              </button>
            );
          } else {
            return (
              <button
                className="boton-subscribe-on"
                data-tposition="left"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.actualizaSubscripcionStock(
                    this.props.codigo_articulo, // codigo_articulo
                    sub_aux.status, //status
                    sub_aux.aviso_unico, //aviso_unico
                    "renew"
                  );
                }}
              >
                <BellZ size={20} weight={"regular"} alt={i18n.t("subscripciones.altrensb")} />
              </button>
            );
          }

        case "articulo":
          if (sub_aux.status === "ACT") {
            return (
              <button
                className="btn subs"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.actualizaSubscripcionStock(
                    this.props.codigo_articulo, // codigo_articulo
                    sub_aux.status, //status
                    sub_aux.aviso_unico, //aviso_unico
                    "articulo"
                  );
                }}
                tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
              >
                {this.props.pedido_cargando ? (
                  <Spinner showSpinner={this.props.pedido_cargando} />
                ) : (
                  <>
                    <BellRinging size={30} weight="duotone" alt={i18n.t("subscripciones.altdelsb")} />
                    {i18n.t("subscripciones.altdelsb").toUpperCase()}
                  </>
                )}
              </button>
            );
          } else {
            return (
              <button
                className="btn subs"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.actualizaSubscripcionStock(
                    this.props.codigo_articulo, // codigo_articulo
                    sub_aux.status, //status
                    sub_aux.aviso_unico, //aviso_unico
                    "renew"
                  );
                }}
                tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
              >
                {this.props.pedido_cargando ? (
                  <Spinner showSpinner={this.props.pedido_cargando} />
                ) : (
                  <>
                    <BellZ size={30} alt={i18n.t("subscripciones.altrensb")} />
                    {i18n.t("subscripciones.altrensb").toUpperCase()}
                  </>
                )}
              </button>
            );
          }
        default:
          return "";
      }
    } else {
      // Botones para crear la subscripcion
      switch (this.props.origen) {
        case "articulos":
        case "pedido_habitual":
        case "ofertas":
        case "novedades":
        case "equivalentes":
        case "relacionados":
          return (
            <button
              className="boton-subscribe-on"
              data-tposition="left"
              onClick={(e) => {
                e.preventDefault();
                this.props.creaSubscripcionStock(
                  this.props.codigo_articulo, // codigo_articulo
                  null, //status
                  "S" //aviso_unico
                );
              }}
            >
              <Bell size={20} weight="regular" alt={i18n.t("subscripciones.altaddsb")} />
            </button>
          );
        case "articulo":
          return (
            <button
              className="btn subs"
              onClick={(e) => {
                e.preventDefault();
                this.props.creaSubscripcionStock(
                  this.props.codigo_articulo, // codigo_articulo
                  null, //status
                  "S" //aviso_unico
                );
              }}
              tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
            >
              {this.props.pedido_cargando ? (
                <Spinner showSpinner={this.props.pedido_cargando} />
              ) : (
                <>
                  <Bell size={30} alt={i18n.t("subscripciones.altaddsb")} />
                  {i18n.t("subscripciones.altaddsb").toUpperCase()}
                </>
              )}
            </button>
          );
        default:
          return "";
      }
    }
  }

  render() {
    if (this.props.origen === "tabla-subs") {
      return this.pintarTabla(this.props.subscripciones);
    } else {
      return this.pintarBoton(this.props.subscripciones);
    }
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  subscripciones: state.auth.user.subscripciones,
});

export default connect(mapStateToProps, {
  actualizaSubscripcionStock,
  creaSubscripcionStock,
})(SubscripcionesStock);
