import React from "react";
import { setObservacionesPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
import { useDispatch, useSelector } from "react-redux";
 
const ObservacionesPedido = (props) => {
  const dispatch = useDispatch();
  const pedido_cargando = useSelector((state)=>state.pedidos.isLoading);
  const pedido = useSelector((state)=>state.pedidos.pedido);
 
  const onChange = (e) => {
    if (!pedido_cargando) {
      dispatch(setObservacionesPedido(e.target.value));
    }
  }
 
  return (
    <form className="form-default">
      <div className="form-group">
        <label htmlFor="observaciones">{i18n.t("pedido.pobsr")}</label>
        <textarea
          className="form-control"
          rows="7"
          name="observaciones"
          id="observaciones"
          value={pedido?.observaciones || ""}
          onChange={onChange}
          disabled={props.pedidoGuardado || pedido_cargando}
        />
      </div>
    </form>
  );
}
 
export default ObservacionesPedido;
