import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getArticulosKit } from "../../actions/articulosKit";
import Precio from "./Precio";
import Imagen from "./Imagen";
import Spinner from "../layout/Spinner";

const ArticulosKit = ({ articulo_padre, id_articulo, margin_top = null, cards_width = null }) => {
  const dispatch = useDispatch();
  const articulosKit = useSelector((state) => state.articulosKit.articulos_kit);
  const isLoading = useSelector((state) => state.articulosKit.loading);

  const style_vars = {
    "--margin-top": margin_top ?? "40px",
    "--cards-width": cards_width ?? "150px",
  };

  useEffect(() => {
    dispatch(getArticulosKit(id_articulo));
  }, []);

  const articuloActual = (articulo) => {
    return articulo_padre.contenido_kit.find((elemento) => {
      return articulo.codigo_articulo === elemento.componente_kit;
    });
  };

  const targetUrl = (articulo) => {
    return "/articulo/" + articulo.codigo_articulo + "/" + (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "");
  };

  const parseNumber = (number, floatPlaces = 2) => {
    const isInteger = number !== Math.floor(number);

    return parseFloat(number).toFixed(isInteger ? 0 : floatPlaces);
  };

  return (
    <div className="articulos-kit-content" style={style_vars}>
      <h1 className="tt-title">Contenido del Kit ({articulosKit.length ?? "--"})</h1>
      {isLoading ? (
        <Spinner showSpinner={isLoading}></Spinner>
      ) : (
        <div className="cards-holder">
          {articulosKit.map((articulo) => {
            return (
              <div className="kit-card" key={articulo.id}>
                <div className="image-holder ">
                  <Link to={targetUrl(articulo)}>
                    <Imagen codigo_articulo={articulo.codigo_articulo} numero_imagen={1} principal="S" tipo_imagen="min" />
                  </Link>
                </div>
                <div className="content">
                  <Link to={targetUrl(articulo)}>
                    <span>{articulo.descrip_comercial}</span>
                  </Link>
                  <span>
                    <Precio codigo_articulo={articulo.codigo_articulo} presentacion={articulo.presentacion_web} cantidad={1} origen="ficha" mostrar_iva="N" mostrar_pres="N" estilo_precio="unitario"/>
                  </span>
                  <span>
                    <b>Cantidad: </b> {parseNumber(articuloActual(articulo).cantidad)} {articuloActual(articulo).presentacion_componente}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ArticulosKit;
