import { connect } from "react-redux";
import PropTypes from "prop-types";
import InputFecha from "./InputFecha";
import Selector from "./Selector";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../layout/Spinner";
import React, { Component, Fragment } from "react";
import i18n from "../../lang/idiomas";
import { dynamicSort } from "../../helpers/funciones";
import { getTiposExpedientesCampos, nuevoExpediente, setTipoExpediente, validaCampoExpediente, setValorCampoExpediente, reiniciaLV, setExpediente } from "../../actions/expedientes";

let elemento;
let valores = {};
let variables = [];
let index = -1;

export class NuevoExpediente extends Component {
  static propTypes = {
    tipos_expedientes_campos: PropTypes.array.isRequired,
    nuevoExpediente: PropTypes.func.isRequired,
    getTiposExpedientesCampos: PropTypes.func.isRequired,
    setTipoExpediente: PropTypes.func.isRequired,
    validaCampoExpediente: PropTypes.func.isRequired,
    setValorCampoExpediente: PropTypes.func.isRequired,
    setExpediente: PropTypes.func.isRequired,
    reiniciaLV: PropTypes.func.isRequired,
  };

  state = {
    filesAux: [],
    fileInput: React.createRef(),
  };

  componentDidMount() {
    // Cargamos los campos relativos al expediente
    if (this.props.tipos_expedientes_campos && this.props.tipos_expedientes_campos.length === 0 && !this.props.expedientes_loading) {
      this.props.getTiposExpedientesCampos(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado]);
    } else {
      // Reiniciamos los valores de los campos
      for (const campo of this.props.tipos_expedientes_campos) {
        if (campo.valor && campo.valor !== "") {
          this.props.setValorCampoExpediente(campo.columna, "");
          this.setState({ [campo.columna]: "" });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Comprobamos si se ha modificado el tipo expediente seleccionado
    if (this.props.tipo_expediente_seleccionado !== prevProps.tipo_expediente_seleccionado && !this.props.expedientes_loading) {
      this.props.getTiposExpedientesCampos(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado]);
    }
  }

  reiniciaValoresPosteriores(campo) {
    // Reinicia los valores de los campos porteriores, en función del "orden"
    index = this.buscaIndice(this.props.tipos_expedientes_campos, campo);

    if (index !== -1) {
      for (let campo_aux of this.props.tipos_expedientes_campos) {
        if (campo_aux.orden > this.props.tipos_expedientes_campos[index].orden) {
          if (campo_aux.valor && campo_aux.valor !== "") {
            this.props.setValorCampoExpediente(campo_aux.columna, "");
            if (campo_aux.tipo_input === "lv") {
              this.props.reiniciaLV(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente, campo_aux.codigo_lov, campo_aux.columna);
            }
            this.setState({ [campo_aux.columna]: "" });
          }
        }
      }
    }
  }

  onBlur = (e) => {
    // Reiniciamos variables
    variables = [];

    // Recorremos los campos para cargar el array
    for (let campo of this.props.tipos_expedientes_campos) {
      variables.push({ campo: campo.columna, valor: campo.valor || campo.valor === 0 ? campo.valor : "" });
    }
    // Llamamos a la validación
    this.props.validaCampoExpediente(
      this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].programa,
      e.currentTarget.id,
      e.currentTarget.value,
      this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers,
      variables
    );
  };

  onChange = (e) => {
    // Buscamos el indice del campo
    index = this.buscaIndice(this.props.tipos_expedientes_campos, e.target.name);

    //Si cambia el valor con respecto al previo
    if (e.target.value !== this.props.tipos_expedientes_campos[index].valor) {
      if (this.props.tipos_expedientes_campos[index].tamano_max && e.target.value.length > this.props.tipos_expedientes_campos[index].tamano_max) {
        e.target.value = e.target.value.slice(0, this.props.tipos_expedientes_campos[index].tamano_max);
      }
      this.props.setValorCampoExpediente(e.target.name, e.target.value);
      this.reiniciaValoresPosteriores(e.target.name);
    }
    // Seteamos valor local y eliminamos el border por si tubiese
    this.setState({ [e.target.name]: e.target.value });
    elemento = document.getElementById(e.target.name);
    elemento.style.border = "unset";
  };

  onChangeFecha = (nombre_campo, valor_campo) => {
    index = this.buscaIndice(this.props.tipos_expedientes_campos, nombre_campo);

    //Si cambia el valor con respecto al previo
    if (valor_campo !== this.state[nombre_campo]) {
      this.reiniciaValoresPosteriores(nombre_campo);
      if (index !== -1) {
        variables = [];
        if (this.props.tipos_expedientes_campos[index].validacion === "S") {
          for (let campo of this.props.tipos_expedientes_campos) {
            variables.push({ campo: campo.columna, valor: campo.valor ? campo.valor : "" });
          }
          this.props.validaCampoExpediente(
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].programa,
            nombre_campo,
            valor_campo,
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers,
            variables
          );
        }
      }
    }
    this.setState({ [nombre_campo]: valor_campo });
    elemento = document.getElementById(nombre_campo);
    if (elemento) {
      elemento.style.border = "unset";
    }
  };

  onChangeLOV = (nombre_campo, valor_campo) => {
    index = this.buscaIndice(this.props.tipos_expedientes_campos, nombre_campo);
    //Si ya está definido en el estado y cambió el valor
    if (valor_campo !== this.state[nombre_campo]) {
      this.reiniciaValoresPosteriores(nombre_campo);
      if (index !== -1) {
        variables = [];
        if (this.props.tipos_expedientes_campos[index].validacion === "S") {
          for (let campo of this.props.tipos_expedientes_campos) {
            variables.push({ campo: campo.columna, valor: campo.valor ? campo.valor : "" });
          }
          this.props.validaCampoExpediente(
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].programa,
            nombre_campo,
            valor_campo,
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers,
            variables
          );
        }
      }
    }

    this.setState({ [nombre_campo]: valor_campo });
    elemento = document.getElementById(nombre_campo);
    if (elemento) {
      elemento.style.border = "unset";
    }
  };

  handleClick = () => {
    this.state.fileInput.current.click();
  };

  buscaIndice(array, nombre_campo) {
    let index = -1;
    index = array.findIndex((campo) => campo.columna === nombre_campo);

    return index;
  }

  onSubmit = (e) => {
    e.preventDefault();
    let falta_campo = true;
    let contador_campos = 0;
    let id_campo = [];
    this.props.tipos_expedientes_campos.forEach((campo_prop) => {
      if (campo_prop.obligatorio === "S") {
        falta_campo = true;
        if (campo_prop.tipo_input === "file") {
          if (this.state.filesAux && this.state.filesAux?.length > 0) {
            //Seteamos valor de campo a S (Indicando la existencia de los mismos)
            valores[campo_prop.columna] = "S";
            falta_campo = false;
          }
        } else {
          if (campo_prop.valor && campo_prop.valor !== "") {
            falta_campo = false;
          }
        }

        if (falta_campo === true) {
          contador_campos = contador_campos + 1;
          id_campo.push(campo_prop.columna);
        }
      } else {
        if (campo_prop.tipo_input === "file") {
          if (this.state.filesAux && this.state.filesAux?.length > 0) {
            //Seteamos valor de campo a N (Indicando la existencia de los mismos)
            valores[campo_prop.columna] = "S";
          } else {
            //Seteamos valor de campo a N (Indicando que no existen ficheros)
            valores[campo_prop.columna] = "N";
          }
        }
      }
    });
    if (contador_campos === 0) {
      let readers = [];
      let fileArray = [];

      const readFile = (file) => {
        return new Promise(function (resolve, reject) {
          let fr = new FileReader();

          fr.onload = function () {
            resolve(fr.result);
          };

          fr.onerror = function () {
            reject(fr);
          };

          fr.readAsBinaryString(file);
        });
      };

      if (this.state.filesAux) {
        for (let index = 0; index < this.state.filesAux?.length; index++) {
          let selectedFile = this.state.filesAux?.item(index);
          if (selectedFile) {
            readers.push(readFile(selectedFile));
          }
        }
        Promise.all(readers).then((values) => {
          for (let index = 0; index < this.state.filesAux.length; index++) {
            let selectedFile = this.state.filesAux.item(index);
            if (selectedFile) {
              let fileName = selectedFile?.name;
              let fileType = selectedFile?.type;
              let contenido = btoa(values[index]);
              fileArray.push({
                nombre: fileName,
                tipo: fileType,
                contenido: contenido,
              });
            }
          }

          valores["id_personalizacion"] = this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers;
          valores["tipo_expediente"] = this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente;
          valores["ficheros"] = fileArray?.length > 0 ? fileArray : [];

          //Convertimos las keys a minusculas para evitar problemas en el back
          let val_aux = Object.keys(valores);
          val_aux.map((valor) => {
            let val_aux_Upper = valor; // Nombre campo original (Puede estar en mayusculas)
            let val_aux_Lower = val_aux_Upper.toLowerCase(); // Nombre campo minusculas
            if (val_aux_Upper !== val_aux_Lower) {
              Object.defineProperty(valores, val_aux_Lower, Object.getOwnPropertyDescriptor(valores, val_aux_Upper));
              delete valores[val_aux_Upper];
            }
            return valores;
          });
          this.props.nuevoExpediente(valores);
          this.props.setTipoExpediente(this.props.tipo_expediente_seleccionado);
        });
      }
    } else {
      confirmAlert({
        title: i18n.t("expedientes.exfadat"),
        message: i18n.t("expedientes.exfacuca"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.aceptar"),
            onClick: () => {
              "";
            },
          },
        ],
        willUnmount: () => {
          for (const element of id_campo) {
            elemento = document.getElementById(element);
            elemento.style.border = "1px solid #FF0000";
          }
        },
      });
    }
  };

  onSelectFile = () => {
    if (this.state.fileInput.current.files.length && this.state.fileInput.current.files.length > 0) {
      this.setState({ filesAux: this.state.fileInput.current.files });
    }
  };

  render() {
    let origen = this.props.origen ? (i18n.t("expedientes.exnuexp") + " ( " + this.props.origen + " )").toUpperCase() : i18n.t("expedientes.exnuexp");
    this.props.tipos_expedientes_campos.forEach((campo_prop) => {
      valores[campo_prop.columna] = campo_prop.valor || campo_prop.valor === 0 ? campo_prop.valor : "";
    });
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">{origen}</h1>
                <div className="row justify-content-center">
                  <div className="col form-body">
                    <div className="form-default justify-content-center">
                      {!this.props.isLoading ? (
                        <form onSubmit={this.onSubmit}>
                          {this.props.tipos_expedientes_campos !== undefined && this.props.tipos_expedientes_campos.length > 0
                            ? this.props.tipos_expedientes_campos.sort(dynamicSort("orden")).map((campo, index) => {
                                switch (campo.tipo_input) {
                                  case "textarea":
                                    return (
                                      <div className="form-group text" key={index}>
                                        <label className={campo.desactivado === "S" ? "campo-expediente-label-disabled": "campo-expediente-label"} htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <textarea
                                          id={campo.columna}
                                          className={"form-control" + (campo.desactivado === "S" ? ' campo-expediente-input-disabled' : ' campo-expediente-input')}
                                          type="text"
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto !== "S" ? false : true}
                                          disabled={campo.desactivado === "S" || this.props.isValidating ? true : false}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={campo.validacion === "S" ? this.onBlur : null}
                                          value={campo.valor ? campo.valor : ""}
                                        />
                                      </div>
                                    );
                                  case "text":
                                    return (
                                      <div className="form-group text" key={index}>
                                        <label className={campo.desactivado === "S" ? "campo-expediente-label-disabled": "campo-expediente-label"} htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={"form-control" + (campo.desactivado === "S" ? ' campo-expediente-input-disabled' : ' campo-expediente-input')}
                                          type="text"
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto !== "S" ? false : true}
                                          disabled={campo.desactivado === "S" || this.props.isValidating ? true : false}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={campo.validacion === "S" ? this.onBlur : null}
                                          value={campo.valor ? campo.valor : ""}
                                        />
                                      </div>
                                    );

                                  case "number":
                                    return (
                                      <div className="form-group number" key={index}>
                                        <label className={campo.desactivado === "S" ? "campo-expediente-label-disabled": "campo-expediente-label"} htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={"form-control" + (campo.desactivado === "S" ? ' campo-expediente-input-disabled' : ' campo-expediente-input')}
                                          type="number"
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto !== "S" ? false : true}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={campo.validacion === "S" ? this.onBlur : null}
                                          disabled={campo.desactivado === "S" || this.props.isValidating ? true : false}
                                          value={campo.valor || campo.valor === 0 ? campo.valor.toString() : ""}
                                        />
                                      </div>
                                    );

                                  case "file":
                                    return (
                                      <div className="form-group file" key={index}>
                                        <label className={campo.desactivado === "S" ? "campo-expediente-label-disabled": "campo-expediente-label"} htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          ref={this.state.fileInput}
                                          type="file"
                                          hidden={campo.oculto !== "S" ? false : true}
                                          multiple={true}
                                          onChange={(ev) => {
                                            this.onSelectFile(ev.nativeEvent.target);
                                          }}
                                        />
                                      </div>
                                    );

                                  case "date":
                                    return <InputFecha key={index} campo={campo} origen={"expedientes"} index={index} handleChange={this.onChangeFecha} />;

                                  case "lv":
                                    return <Selector key={index} campo={campo} origen={"expedientes"} index={index} handleChange={this.onChangeLOV} filtros={valores} />;

                                  default:
                                    return (
                                      <div className="form-group" key={index}>
                                        <label className={campo.desactivado === "S" ? "campo-expediente-label-disabled": "campo-expediente-label"} htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={"form-control" + (campo.desactivado === "S" ? ' campo-expediente-input-disabled' : ' campo-expediente-input')}
                                          type="text"
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto !== "S" ? false : true}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          disabled={campo.desactivado === "S" || this.props.isValidating ? true : false}
                                          value={campo.valor ? campo.valor : ""}
                                        />
                                      </div>
                                    );
                                }
                              })
                            : "No se han encontrado campos"}
                          <div className="row">
                            <div className="col-auto mr-auto">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary">
                                  {i18n.t("expedientes.exenv")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <Spinner showSpinner={this.props.isLoading} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  expedientes_loading: state.expedientes.isLoading,
  isLoading: state.expedientes.isLoading,
  isValidating: state.expedientes.isValidating,
});

export default connect(mapStateToProps, {
  nuevoExpediente,
  setTipoExpediente,
  getTiposExpedientesCampos,
  validaCampoExpediente,
  setValorCampoExpediente,
  reiniciaLV,
  setExpediente,
})(NuevoExpediente);
