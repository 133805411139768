import axios from "../axiosConfig";
import { tokenConfig } from "./auth";

import { GET_STOCK, GET_STOCKS, GET_STOCK_FAIL, GET_STOCKS_FAIL, GET_STOCK_SUCCESS, GET_STOCKS_SUCCESS, LIMPIAR_STOCKS } from "./types";

let body;

export const limpiarStocks = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_STOCKS,
  });
};

export const getStockArticulo =
  (codigo_articulo, presentacion = "", codigo_almacen = "", id_pedido_seleccionado = "", pedir_cantidad = false, situacion = "") =>
  (dispatch, getState) => {
    dispatch({
      type: GET_STOCK,
      codigo_articulo: codigo_articulo,
      presentacion: presentacion,
      situacion: situacion,
    });
    if (codigo_almacen === null) {
      codigo_almacen = "";
    }
    let url = `/stock/?articulo=${codigo_articulo}&presentacion=${presentacion}&almacen=${codigo_almacen}&id_pedido_seleccionado=${id_pedido_seleccionado}&situacion=${situacion}`;
    if (pedir_cantidad !== false) {
      url = `/stock/cantidadStock/?articulo=${codigo_articulo}&presentacion=${presentacion}&almacen=${codigo_almacen}&id_pedido_seleccionado=${id_pedido_seleccionado}&situacion=${situacion}`;
    }
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_STOCK_SUCCESS,
          payload: res.data,
          codigo_articulo: codigo_articulo,
          presentacion: presentacion,
          situacion: situacion,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_STOCK_FAIL,
          codigo_articulo: codigo_articulo,
          presentacion: presentacion,
          situacion: situacion,
        });
      });
  };

export const getStocksArticulos =
  (articulos_stock = [], origen = "", pedir_cantidad = false, id = "", maximo_agrupados = 36) =>
  (dispatch, getState) => {
    //Comprobamos si supera el maximo
    if (articulos_stock.length > Number(maximo_agrupados)) {
      let z = 0; //Indice del array de arrays
      let array_aux = []; //Declaramos un array auxiliar
      array_aux[z] = []; //Lo iniciamos como array de arrays

      for (let x = 0; x < articulos_stock.length; x++) {
        //Hacemos push del valor actual
        array_aux[z].push({
          codigo_articulo: articulos_stock[x].codigo_articulo,
          presentacion: articulos_stock[x].presentacion,
          codigo_almacen: articulos_stock[x].codigo_almacen,
          situacion: articulos_stock[x].situacion,
        });

        //Si x es divisor entero del maximo (sin ser 0 o 1)
        if (x !== 0 && x !== 1 && (x + 1) % Number(maximo_agrupados) === 0 && x + 1 !== articulos_stock.length) {
          z++; //Aumentamos el indice
          array_aux[z] = []; //Iniciamos el siguiente valor
        }
      }
      z = 0;
      //Lanzamos peticion de precios variable del array de arrays
      array_aux.forEach((array_articulos) => {
        body = JSON.stringify({
          articulos_stock: array_articulos,
        });
        z++;
        dispatch({
          type: GET_STOCKS,
          articulos_stock: array_articulos,
        });

        let url = `/stock/multipleStock/?origen=${origen}&total_articulos_stock=${articulos_stock.length}&parte=${z}`;
        if (pedir_cantidad === true || pedir_cantidad === "true" || pedir_cantidad === "S") {
          url = `/stock/multipleStockCantidad/?origen=${origen}&total_articulos_stock=${articulos_stock.length}&parte=${z}`;
        }
        axios
          .post(url, body, tokenConfig(getState))
          .then((res) => {
            dispatch({
              type: GET_STOCKS_SUCCESS,
              articulos_stock: array_articulos,
              payload: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: GET_STOCKS_FAIL,
              articulos_stock: array_articulos,
            });
          });
      });
    } else {
      dispatch({
        type: GET_STOCKS,
        articulos_stock: articulos_stock,
      });

      body = JSON.stringify({ articulos_stock });
      let url = `/stock/multipleStock/?origen=${origen}&total_articulos_stock=${articulos_stock.length}`;
      if (pedir_cantidad === true || pedir_cantidad === "true" || pedir_cantidad === "S") {
        url = `/stock/multipleStockCantidad/?origen=${origen}&total_articulos_stock=${articulos_stock.length}`;
      }
      axios
        .post(url, body, tokenConfig(getState))
        .then((res) => {
          dispatch({
            type: GET_STOCKS_SUCCESS,
            articulos_stock: articulos_stock,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_STOCKS_FAIL,
            articulos_stock: articulos_stock,
          });
        });
    }
  };
