import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMenuExpedientes, getExpedientesTipo, cerrarExpediente, limpiarFiltrosExpedientes } from "../../actions/expedientes";
import ExpedientesMenu from "./ExpedientesMenu";
import Spinner from "../layout/Spinner";
import ExpedientesListado from "./ExpedientesListado";
import ExpedienteDetalle from "./ExpedienteDetalle";
import NuevoExpediente from "./NuevoExpediente";
import i18n from "../../lang/idiomas";

export class Expedientes extends Component {
  state = { accion: "" };

  static propTypes = {
    tipos_expedientes: PropTypes.array.isRequired,
    expedientes: PropTypes.array.isRequired,
    getMenuExpedientes: PropTypes.func.isRequired,
    getExpedientesTipo: PropTypes.func.isRequired,
    tipo_expediente_seleccionado: PropTypes.string.isRequired,
    cerrarExpediente: PropTypes.func.isRequired,
    limpiarFiltrosExpedientes: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.tipos_expedientes && this.props.tipos_expedientes.length === 0 && !this.props.expedientes_loading) {
      this.props.getMenuExpedientes();
    }
  }
  componentWillUnmount() {
    this.props.limpiarFiltrosExpedientes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // Comprobamos si se ha seleccionado un expediente
      // Comprobamos si ha cambiado el orden o numero de expedientes a mostrar
      (prevProps.tipo_expediente_seleccionado !== this.props.tipo_expediente_seleccionado &&
        this.props.tipo_expediente_seleccionado !== "" &&
        this.props.tipos_expedientes &&
        this.props.tipos_expedientes.length > 0 &&
        !this.props.expedientes_loading) ||
      (!this.props.expedientes_loading && (prevProps.mostrar !== this.props.mostrar || prevProps.orden !== this.props.orden))
    ) {
      this.props.getExpedientesTipo(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado], this.props.mostrar, this.props.orden, this.props.inicio, this.props.fin);
    }
  }

  render() {
    return (
      <Fragment>
        <ExpedientesMenu />
        <div className="container contenedor_expedientes">
          {this.props.tipos_expedientes && this.props.tipos_expedientes.length > 0 && this.props.tipo_expediente_seleccionado === "" ? (
            <div className="indicador">
              <p className="sel_informe_txt">{i18n.t("expedientes.exseltip")}</p>
            </div>
          ) : this.props.expedientes && this.props.tipo_expediente_seleccionado !== "" && this.props.expediente_seleccionado === "" ? (
            <ExpedientesListado />
          ) : this.props.expedientes && this.props.tipo_expediente_seleccionado !== "" && this.props.expediente_seleccionado !== "" ? (
            this.props.expediente_seleccionado === "new" ? (
              <NuevoExpediente origen={this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].nombre} />
            ) : (
              <ExpedienteDetalle />
            )
          ) : this.props.expedientes_loading ? (
            <Spinner showSpinner={this.props.expedientes_loading} />
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  expediente_seleccionado: state.expedientes.expediente_seleccionado,
  expedientes: state.expedientes.expedientes,
  expedientes_loading: state.expedientes.isLoading,
  mostrar: state.expedientes.mostrar,
  inicio: state.expedientes.inicio,
  fin: state.expedientes.fin,
  orden: state.expedientes.orden,
  usuario: state.auth.user,
});

export default connect(mapStateToProps, {
  getMenuExpedientes,
  getExpedientesTipo,
  cerrarExpediente,
  limpiarFiltrosExpedientes,
})(Expedientes);
