import { GET_STOCK, GET_STOCK_SUCCESS, GET_STOCK_FAIL, GET_STOCKS, GET_STOCKS_SUCCESS, GET_STOCKS_FAIL, LIMPIAR_STOCKS } from "../actions/types.js";

const initialState = {
  stock: {},
};

export default function reducer(state = initialState, action) {
  let lista_stocks;
  switch (action.type) {
    case LIMPIAR_STOCKS:
      return {
        ...state,
        stock: {},
      };

    case GET_STOCK:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));

      // Comprobamos si existe algun stock para el articulo
      if (lista_stocks[action.codigo_articulo] && Object.keys(lista_stocks[action.codigo_articulo]).length > 0) {
        // Pisamos el valor previo con la nueva busqueda
        if (lista_stocks[action.codigo_articulo][action.presentacion] && Object.keys(lista_stocks[action.codigo_articulo][action.presentacion]).length > 0) {
          lista_stocks[action.codigo_articulo][action.presentacion][action.situacion] = {
            status: "fetching",
          };
        } else {
          lista_stocks[action.codigo_articulo][action.presentacion] = {};
          lista_stocks[action.codigo_articulo][action.presentacion][action.situacion] = {
            status: "fetching",
          };
        }
      } else {
        // Creamos el la lista y añadimos el primer registro
        lista_stocks[action.codigo_articulo] = {};
        lista_stocks[action.codigo_articulo][action.presentacion] = {};
        lista_stocks[action.codigo_articulo][action.presentacion][action.situacion] = {
          status: "fetching",
        };
      }
      return {
        ...state,
        stock: lista_stocks,
      };

    case GET_STOCK_FAIL:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));
      // Seteamos el status a error
      lista_stocks[action.codigo_articulo][action.presentacion][action.situacion] = {
        status: "error",
      };

      return {
        ...state,
        stock: lista_stocks,
      };

    case GET_STOCK_SUCCESS:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));
      // Seteamos el status a iddling
      action.payload.status = "iddling";

      // Pisamos los datos con los nuevos valores
      lista_stocks[action.codigo_articulo][action.presentacion][action.situacion] = action.payload;

      return {
        ...state,
        stock: lista_stocks,
      };

    case GET_STOCKS:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));
      // Recorremos el array de articulos y seteamos los nuevos status
      for (let articulo of action.articulos_stock) {
        if (lista_stocks[articulo.codigo_articulo] && Object.keys(lista_stocks[articulo.codigo_articulo]).length > 0) {
          // Pisamos el valor previo con la nueva busqueda
          if (lista_stocks[articulo.codigo_articulo][articulo.presentacion] && Object.keys(lista_stocks[articulo.codigo_articulo][articulo.presentacion]).length > 0) {
            lista_stocks[articulo.codigo_articulo][articulo.presentacion][articulo.situacion] = {
              status: "fetching",
            };
          } else {
            lista_stocks[articulo.codigo_articulo][articulo.presentacion] = {};
            lista_stocks[articulo.codigo_articulo][articulo.presentacion][articulo.situacion] = {
              status: "fetching",
            };
          }
        } else {
          // Creamos el la lista y añadimos el primer registro
          lista_stocks[articulo.codigo_articulo] = {};
          lista_stocks[articulo.codigo_articulo][articulo.presentacion] = {};
          lista_stocks[articulo.codigo_articulo][articulo.presentacion][articulo.situacion] = {
            status: "fetching",
          };
        }
      }
      return {
        ...state,
        stock: lista_stocks,
      };

    case GET_STOCKS_FAIL:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));
      // Recorremos el array de articulos seteando en estatus error
      for (let articulo of action.articulos_stock) {
        lista_stocks[articulo.codigo_articulo][articulo.presentacion][articulo.situacion] = {
          status: "error",
        };
      }

      return {
        ...state,
        stock: lista_stocks,
      };

    case GET_STOCKS_SUCCESS:
      lista_stocks = JSON.parse(JSON.stringify(state.stock));

      for (let articulo of action.payload) {
        //Setamos el nuevo status de los articulos
        articulo.status = "iddling";
        // Creamos una copia del stock_articulo
        let stock_articulo = JSON.parse(JSON.stringify(articulo));
        // Eliminamos los valores inutiles
        if (stock_articulo) {
          if (stock_articulo.codigo_articulo) {
            delete stock_articulo.codigo_articulo;
          }
          if (stock_articulo.presentacion) {
            delete stock_articulo.presentacion;
          }
          if (stock_articulo.situacion) {
            delete stock_articulo.situacion;
          }
        }
        // Seteamos los valores
        lista_stocks[articulo.codigo_articulo][articulo.presentacion][articulo.situacion] = stock_articulo;
      }

      return {
        ...state,
        stock: lista_stocks,
      };

    default:
      return state;
  }
}
