import { Bell, BellRinging, ClipboardText, ShoppingCart } from 'phosphor-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nuevaReceta } from '../../actions/modalGenerica'
import { postLinea } from '../../actions/pedidos'
import { getPrecio } from '../../actions/precios'
import {
  actualizaSubscripcionStock,
  creaSubscripcionStock,
} from '../../actions/subscripcionesStock'
import { cargarParametros } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'
import BotonAnadirLista from './BotonAnadirLista'

let parametros_usados = [
  'btn_presup',
  'stock_cantidad',
  'btn_subs',
  'cant_def',
  'def_input',
  'andr_cant_list',
  'ej_onblur',
]
let parametros
let input_cant
let input_cant_mob
let modulo

const AnadirCarrito = props => {
  const dispatch = useDispatch()

  const precio = useSelector(state => state.precios.precio)
  const stock = useSelector(state => state.stocks.stock)
  const hash = useSelector(state => state.auth.hash)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const portal = useSelector(state => state.portal.portal)
  const subpresentacion = useSelector(state => state.subpresentaciones.subpresentacion)
  const presentacion = useSelector(state => state.presentaciones.presentacion)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const usuario = useSelector(state => state.auth.user)

  const [valor, setValor] = useState(0)
  const [articulo, setArticulo] = useState({})
  const [filesAux, setFilesAux] = useState([])
  const [filesInput, setFilesInput] = useState(React.createRef())
  const prevProps = useRef()

  switch (props.origen) {
    case 'ficha':
      modulo = 'ART'
      break
    case 'tabla':
      modulo = 'TART'
      break
    case 'lista':
      modulo = 'LART'
      break
    default:
      modulo = 'GEN'
      break
  }

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, modulo)

  const handleValorChange = e => {
    setValor(e.target.value)
  }

  const handleArticuloChange = e => {
    setArticulo(e.target.value)
  }

  const handleFilesAuxChange = e => {
    setFilesAux(e.target.value)
  }

  const handleFilesInputChange = e => {
    setFilesInput(e.target.value)
  }

  useEffect(() => {
    // Seteamos el nuevo articulo
    setArticulo(props.articulo)
  }, [props.articulo])

  useEffect(() => {
    // Recuperamos los inputs
    input_cant = document.getElementById('input-cant-' + articulo.codigo_articulo)
    input_cant_mob = document.getElementById('input-cant-mob-' + articulo.codigo_articulo)
    // Comprobamos que los parametros estén cargados
    if (Object.keys(parametros).length > 0) {
      switch (parametros.def_input) {
        case 'CANT_MIN':
          // Se comprueba si tiene cantidad minima y si es distinta de 0
          if (articulo.cantidad_minima && Number(articulo.cantidad_minima).toFixed(0) !== 0) {
            let cantidad_min = Number(articulo.cantidad_minima).toFixed(0)
            if (props.datos && props.datos.cantidad_pedida) {
              setearValorInput(
                input_cant,
                input_cant_mob,
                Number(props.datos.cantidad_pedida).toFixed(0),
              )
            } else {
              setearValorInput(input_cant, input_cant_mob, cantidad_min)
            }
          }
          break
        case 'PEDIDO':
          let index = -1
          // Buscamos la linea en el pedido
          if (pedido?.lineas && pedido.lineas.length > 0) {
            index = pedido.lineas.findIndex(
              linea => linea.articulo === articulo.codigo_articulo && linea.tipo_linea === 'P',
            )
            if (index !== -1) {
              // Si el valor no es el mismo que el del pedido
              if (valor !== Number(pedido.lineas[index].cantidad_pedida).toFixed(0)) {
                // Se setea el valor en los inputs
                let cantidad_pedido = Number(pedido.lineas[index].cantidad_pedida).toFixed(0)
                if (props.datos && props.datos.cantidad_pedida) {
                  setearValorInput(
                    input_cant,
                    input_cant_mob,
                    Number(props.datos.cantidad_pedida).toFixed(0),
                  )
                } else {
                  setearValorInput(input_cant, input_cant_mob, cantidad_pedido)
                }
              }
            }
          }
          break
        default:
          if (props.datos && props.datos.cantidad_pedida) {
            setearValorInput(
              input_cant,
              input_cant_mob,
              Number(props.datos.cantidad_pedida).toFixed(0),
            )
          } else {
            setearValorInput(input_cant, input_cant_mob, parametros.cant_def || 1)
          }
          break
      }
    }
  }, [articulo])

  useEffect(() => {
    input_cant = document.getElementById('input-cant-' + articulo.codigo_articulo)
    input_cant_mob = document.getElementById('input-cant-mob-' + articulo.codigo_articulo)
    //Si el valor es 0 seteamos el defectivo
    if (valor === 0) {
      if (Object.keys(parametros).length > 0) {
        if (valor !== parseInt(parametros.cant_def)) {
          setearValorInput(input_cant, input_cant_mob, parametros.cant_def)
        }
      }
    }
  }, [valor])

  useEffect(() => {
    // Si existe una subpresentacion seleccionada
    if (
      articulo.codigo_articulo &&
      Object.keys(subpresentacion).length > 0 &&
      articulo.codigo_articulo === subpresentacion.articulo
    ) {
      // Se multiplica por el factor de conversion (en espera de modificar el paquete)
      dispatch(
        getPrecio(
          articulo.codigo_articulo,
          parseFloat(valor) * subpresentacion.factor_conversion,
          id_pedido_seleccionado || pedido?.id_pedido,
          pedido.domicilio_envio,
          articulo.presentacion_web || '',
          pedido.almacen,
        ),
      )
    }
  }, [subpresentacion])

  useEffect(() => {
    // Si existe una presentacion seleccionada
    if (
      articulo.codigo_articulo &&
      Object.keys(presentacion).length > 0 &&
      articulo.codigo_articulo === presentacion.articulo
    ) {
      dispatch(
        getPrecio(
          articulo.codigo_articulo,
          parseFloat(valor),
          id_pedido_seleccionado || pedido?.id_pedido,
          pedido.domicilio_envio,
          presentacion.presentacion,
          pedido.almacen,
        ),
      )
      prevProps.current = { valor: parseFloat(valor).toFixed(4) }
    }
  }, [presentacion])

  const comprobarCarnet = (linea, comprobar_especificos, valida) => {
    if (usuario?.carnets?.lineas.length > 0) {
      let index = -1
      if (comprobar_especificos === 'S') {
        // AUTORIZACIÓN VENTA MAIORISTA PROD. ZOOSANITARIOS
        index = usuario.carnets.lineas.findIndex(carnet => carnet.tipo_carnet === '00002')
        if (index !== -1) {
          // Procesamos sin mensaje
          if (valida === 'otros') {
            linea.valida_otros = 'S'
          } else {
            linea.valida_receta = 'S'
          }
        } else {
          // AUTORIZACIÓN VENTA MEDICAMENTOS OUTRAS CANLES
          index = usuario.carnets.lineas.findIndex(carnet => carnet.tipo_carnet === '00003')
          if (index !== -1) {
            // Procesamos con aviso
            if (valida === 'otros') {
              linea.valida_otros = 'M'
            } else {
              linea.valida_receta = 'M'
            }
          }
        }
      } else {
        index = usuario.carnets.lineas.findIndex(
          carnet => carnet.tipo_carnet === articulo.tipo_carnet_profesional,
        )

        if (index !== -1) {
          // Si existe carnet valido
          linea.valida_carnet = 'S'
        } else {
          linea.valida_carnet = 'N'
        }
      }
    } else {
      if (comprobar_especificos === 'S') {
        if (valida === 'receta') {
          linea.valida_receta = 'N'
        }
      } else {
        linea.valida_carnet = 'N'
      }
    }

    return linea
  }

  const anadirCarro = tipo => {
    if (!pedido_cargando) {
      if (
        precio[articulo.codigo_articulo] &&
        precio[articulo.codigo_articulo][
          presentacion.presentacion ||
            articulo.presentacion_escogida ||
            articulo.presentacion_pedido ||
            articulo.presentacion_web
        ]
      ) {
        let cantidad_pedida = valor

        if (
          (cantidad_pedida === 0 && cantidad_pedida !== parametros.cant_def) ||
          !cantidad_pedida
        ) {
          cantidad_pedida = parseInt(parametros.cant_def)
        }

        if (subpresentacion.subpresentacion) {
          cantidad_pedida = cantidad_pedida * subpresentacion.factor_conversion
        }

        let linea = {
          articulo: articulo.codigo_articulo,
          descripcion: articulo.descrip_comercial,
          precio_venta: null,
          observaciones: '',
          estado_linea: 'B',
          cantidad_pedida: cantidad_pedida,
          presentacion_pedido: presentacion.presentacion || articulo.presentacion_web,
          tipo_linea: tipo,
          sub_pres: '',
          sub_pres_cant: '',
          sub_pres_fc: '',
          presentacion_escogida: presentacion.presentacion || '',
          pres_fc: '',
          valida_carnet: '',
        }

        // Comprobamos si el articulo necesita carnet profesional
        if (articulo.tipo_carnet_profesional) {
          linea = comprobarCarnet(linea, 'N', null)
        }

        // Comprobamos si el articulo necesita receta
        if (articulo.numero_receta) {
          if (articulo.codigo_estad10 && articulo.codigo_estad10 === '10') {
            // Comprobamos si necesita autorizacion por otros canales
            if (usuario.con_receta && usuario.con_receta === 'S') {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (usuario.tipo_cliente === '01') {
                // Se envia mensaje a usuario
                linea = comprobarCarnet(linea, 'S', 'otros')
              } else {
                // Se indica que pasa a estar pendiente de validacion otros canales
                linea.valida_otros = 'N'
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_otros = 'S'
            }
          } else {
            // Comprobamos si el usuario está autorizado o no
            if (usuario.con_receta && usuario.con_receta === 'S') {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (usuario.tipo_cliente === '01') {
                // Se envia mensaje a usuario
                linea = comprobarCarnet(linea, 'S', 'receta')
              } else {
                // Se obliga al usuario a adjuntar receta
                linea.valida_receta = 'N'
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_receta = 'S'
            }
          }
        } else {
          // Comprobamos si necesita autorizacion por otros canales
          if (articulo.codigo_estad10 && articulo.codigo_estad10 === '10') {
            // Comprobamos si es mayorista
            // 01 - Mayorista
            // 02 - Minorista
            if (usuario.tipo_cliente === '01') {
              // Se envia mensaje a usuario
              linea = comprobarCarnet(linea, 'S', 'otros')
            }
          }
        }

        if (subpresentacion.subpresentacion) {
          linea.sub_pres = subpresentacion.subpresentacion
          linea.sub_pres_cant = parseInt(valor)
          linea.sub_pres_fc = parseFloat(subpresentacion.factor_conversion)
          linea.observaciones = linea.sub_pres_cant + ' de ' + linea.sub_pres
        }

        if (presentacion.presentacion) {
          linea.pres_fc = parseFloat(presentacion.factor_conversion)

          linea.presentacion_escogida = presentacion.presentacion
        }

        // Si se incluyeron observaciones en la linea se añaden a la linea de pedido
        if (props.getObservaciones) {
          linea.observaciones = props.getObservaciones(linea.articulo)
        }

        if (!linea.valida_receta || linea.valida_receta === 'S' || linea.valida_receta === 'M') {
          // Añadimos la linea a pedido
          dispatch(postLinea(linea, hash, id_pedido_seleccionado || pedido.id_pedido))
        } else {
          //Se solicita el ingreso de la receta
          dispatch(nuevaReceta(linea, 'receta'))
        }

        let analytics = new AnalyticsEdisa()

        analytics.registraEvento({
          modulo: 'Pedido',
          tipo: 'AddToCart',
          valor: linea.articulo,
          params: {
            codigo_articulo: linea.articulo,
            descrip_comercial: linea.descripcion,
            divisa: precio[linea.articulo][linea.presentacion_pedido].divisa_precio,
            precio: parseFloat(
              props.netear_precio === 'S'
                ? precio[linea.articulo][linea.presentacion_pedido].importe_neto
                : precio[linea.articulo][linea.presentacion_pedido].precio_presentacion *
                    cantidad_pedida,
            ),
          },
        })
      }
    }
  }

  const onBlur = e => {
    if (e.target.value === null || e.target.value === '') {
      e.target.value = parametros.cant_def || 1
      setValor(e.target.value)
    }

    if (e.target.value && parseInt(e.target.value) !== 0) {
      let cantidad = parseFloat(e.target.value).toFixed(4)

      if (subpresentacion.subpresentacion) {
        cantidad = parseFloat(cantidad * subpresentacion.factor_conversion).toFixed(4)
      }

      // if (presentacion.presentacion){
      //   cantidad = parseFloat(cantidad * presentacion.factor_conversion).toFixed(4);
      // }

      if (cantidad !== prevProps.current.valor) {
        dispatch(
          getPrecio(
            articulo.codigo_articulo,
            cantidad,
            id_pedido_seleccionado,
            pedido.domicilio_envio,
            presentacion?.presentacion || articulo.presentacion_web,
            pedido.almacen,
          ),
        )
        prevProps.current = { valor: parseFloat(cantidad).toFixed(4) }
      }
    }
  }

  const onChange = e => {
    setValor(e.target.value || parametros.cant_def || 1)
    if (e.currentTarget.className === 'quantity-input' && parametros.ej_onblur === 'S') {
      onBlur(e)
    }
  }

  const setearValorInput = (input_normal, input_mobil, valor_cant) => {
    if (input_normal) {
      input_normal.value = valor_cant
    }
    if (input_mobil) {
      input_mobil.value = valor_cant
    }
    // Se setea el valor en el estado
    setValor(parseInt(valor_cant))
    prevProps.current = { valor: parseFloat(valor_cant).toFixed(4) }
  }

  const pintaBotonSubscripcion = () => {
    if (usuario?.subscripciones?.lineas?.length > 0) {
      let existe_sub = false
      let sub_aux = null
      usuario.subscripciones.lineas.forEach(sub => {
        if (sub.codigo_articulo === articulo.codigo_articulo) {
          existe_sub = true
          sub_aux = sub
        }
        if (existe_sub === true) {
          return
        }
      })
      if (existe_sub && sub_aux) {
        return (
          <button
            type='submit'
            className='btn btn-primary sub'
            onClick={e => {
              e.preventDefault()
              dispatch(
                actualizaSubscripcionStock(
                  articulo.codigo_articulo, // codigo_articulo
                  sub_aux.status, //status
                  sub_aux.aviso_unico, //aviso_unico
                  'articulos',
                ),
              )
            }}
            tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}>
            <BellRinging
              size={20}
              weight='duotone'
              alt={i18n.t('subscripciones.altdelsb')}
            />
            {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altdelsb').toUpperCase() : ''}
          </button>
        )
      } else {
        return (
          <button
            type='submit'
            className='btn btn-primary no-sub'
            onClick={e => {
              e.preventDefault()
              dispatch(
                creaSubscripcionStock(
                  articulo.codigo_articulo, // codigo_articulo
                  null, //status
                  'S', //aviso_unico
                ),
              )
            }}
            tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}>
            <Bell
              size={20}
              alt={i18n.t('subscripciones.altaddsb')}
            />
            {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altaddsb').toUpperCase() : ''}
          </button>
        )
      }
    } else {
      return (
        <button
          type='submit'
          className='btn btn-primary no-sub'
          onClick={e => {
            e.preventDefault()
            dispatch(
              creaSubscripcionStock(
                articulo.codigo_articulo, // codigo_articulo
                null, //status
                'S', //aviso_unico
              ),
            )
          }}
          tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}>
          <Bell
            size={20}
            alt={i18n.t('subscripciones.altaddsb')}
          />
          {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altaddsb').toUpperCase() : ''}
        </button>
      )
    }
  }

  const comprobarDatos = (tipo_linea, tipo_input) => {
    if (tipo_linea === 'O') {
      return (
        precio?.[articulo.codigo_articulo]?.[
          presentacion?.articulo === articulo.codigo_articulo
            ? presentacion.presentacion
            : articulo.presentacion_pedido || articulo.presentacion_web
        ]?.status === 'iddling' && !pedido_cargando
      )
    } else {
      if (tipo_input === 'button') {
        return (
          (precio?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.status === 'iddling' &&
            !pedido_cargando &&
            parametros &&
            stock?.[articulo.codigo_articulo]?.[
              presentacion?.articulo === articulo.codigo_articulo
                ? presentacion.presentacion
                : articulo.presentacion_pedido || articulo.presentacion_web
            ]?.['null']?.stock === 'S') ||
          stock?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.['null']?.stock > 0 ||
          stock?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.['']?.stock > 0
        )
      } else {
        return (
          precio?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.status !== 'fetching' &&
          !pedido_cargando &&
          stock?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.['null']?.status !== 'fetching'
        )
      }
    }
  }

  switch (props.origen) {
    case 'ficha':
      return (
        <form className='form-inline form-default control_anadir_carrito_ficha'>
          <div className='input-group'>
            <input
              className='form-control desctop'
              id={'input-cant-' + articulo.codigo_articulo}
              disabled={!comprobarDatos('P', 'input')}
              //data-tooltip="Cantidad"
              type='number'
              name='valor'
              value={valor === 0 ? parametros.cant_def : valor}
              onChange={e => setValor(e.target.value || parametros.cant_def || 1)}
              onBlur={parametros.ej_onblur === 'N' ? null : onBlur}
              placeholder={parametros.cant_def}
              tabIndex={1 + (props.index && props.index !== '' ? props.index * 4 : 1)}
            />
            <div className='detach-quantity-desctope mobile'>
              <div className='tt-input-counter style-01'>
                <input
                  className='quantity-input'
                  id={'input-cant-mob-' + articulo.codigo_articulo}
                  disabled={!comprobarDatos('P', 'input')}
                  onChange={onChange}
                  value={valor === 0 ? parametros.cant_def : valor}
                  type='number'
                  pattern='[0-9]*'
                  name='valor'
                  size='5'
                />
              </div>
            </div>
            <button
              type='submit'
              //data-tooltip="Añadir a pedido"
              className='btn btn-primary'
              onClick={e => {
                e.preventDefault()
                anadirCarro('P')
              }}
              tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}
              disabled={!comprobarDatos('P', 'button')}>
              <ShoppingCart
                size={20}
                alt={i18n.t('general.altaddsc')}
              />
              {props.mostrar_texto_btn === 'S' ? i18n.t('general.altaddsc').toUpperCase() : ''}
            </button>
            <div className='row botones-secundarios'>
              <div className='tt-wrapper'>
                <ul className='tt-list-btn'>
                  {parametros?.btn_presup === 'S' ? (
                    <li>
                      <button
                        className='btn-link'
                        onClick={e => {
                          e.preventDefault()
                          anadirCarro('O')
                        }}
                        tabIndex={3 + (props.index && props.index !== '' ? props.index * 4 : 1)}
                        disabled={!comprobarDatos('O', 'button')}>
                        <ClipboardText
                          size={20}
                          alt={i18n.t('general.altaddbg')}
                        />
                        {props.mostrar_texto_btn === 'S'
                          ? i18n.t('general.altaddbg').toUpperCase()
                          : ''}
                      </button>
                    </li>
                  ) : (
                    ''
                  )}
                  {articulo.codigo_articulo && props.anadir_lista ? (
                    <li>
                      <BotonAnadirLista
                        origen='articulo'
                        mostrar_texto_btn={props.mostrar_texto_btn}
                        codigo_articulo={articulo.codigo_articulo}
                        presentacion={presentacion.presentacion || articulo.presentacion_web}
                        tabIndex={
                          4 +
                          (props.index !== undefined && props.index !== null && props.index !== ''
                            ? props.index * 4
                            : 1)
                        }
                      />
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </div>
          </div>
        </form>
      )
    case 'tabla':
      if (props.datos && (props.datos.tipo_articulo === 'F' || props.datos.tipo_articulo === 'R')) {
        return ''
      } else {
        return (
          <form className='form-inline form-default control_anadir_carrito_tabla'>
            <div className='input-group'>
              <input
                className='form-control desctop'
                id={'input-cant-' + articulo.codigo_articulo}
                //data-tooltip="Cantidad"
                type='number'
                name='valor'
                disabled={!comprobarDatos('P', 'input')}
                onChange={e => setValor(e.target.value || parametros.cant_def || 1)}
                onBlur={parametros.ej_onblur === 'N' ? null : onBlur}
                onFocus={e => {
                  e.target.value = null
                }}
                value={valor === 0 ? parametros.cant_def : valor}
                placeholder={parametros.cant_def}
                tabIndex={1 + (props.index && props.index !== '' ? props.index * 4 : 1)}
              />
              <div className='col-12 detach-quantity-desctope mobile'>
                <div className='tt-input-counter style-01'>
                  <input
                    className='quantity-input'
                    id={'input-cant-mob-' + articulo.codigo_articulo}
                    onChange={onChange}
                    value={valor === 0 ? parametros.cant_def : valor}
                    type='number'
                    pattern='[0-9]*'
                    name='valor'
                    size='5'
                  />
                </div>
              </div>
              <div className='botonera'>
                {parametros.btn_subs === 'S' ? (
                  <Fragment>
                    {!comprobarDatos('P', 'button') ? (
                      pintaBotonSubscripcion()
                    ) : (
                      <button
                        type='submit'
                        //data-tooltip="Añadir a pedido"
                        className='btn btn-primary boton_carrito'
                        onClick={e => {
                          e.preventDefault()
                          anadirCarro('P')
                        }}
                        tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}>
                        {props.mostrar_texto_btn === 'S' ? (
                          'Pedido'
                        ) : (
                          <ShoppingCart
                            size={20}
                            alt={i18n.t('general.altaddsc')}
                          />
                        )}
                      </button>
                    )}
                  </Fragment>
                ) : (
                  <button
                    type='submit'
                    //data-tooltip="Añadir a pedido"
                    className='btn btn-primary boton_carrito'
                    onClick={e => {
                      e.preventDefault()
                      anadirCarro('P')
                    }}
                    tabIndex={2 + (props.index && props.index !== '' ? props.index * 4 : 1)}
                    disabled={!comprobarDatos('P', 'button')}>
                    {props.mostrar_texto_btn === 'S' ? (
                      i18n.t('general.pedido')
                    ) : (
                      <ShoppingCart
                        size={20}
                        alt={i18n.t('general.altaddsc')}
                      />
                    )}
                  </button>
                )}
                {parametros?.btn_presup === 'S' ? (
                  <button
                    className='btn boton-carrito'
                    onClick={e => {
                      e.preventDefault()
                      anadirCarro('O')
                    }}
                    tabIndex={3 + (props.index && props.index !== '' ? props.index * 4 : 1)}
                    disabled={!comprobarDatos('O', 'button')}>
                    {props.mostrar_texto_btn === 'S' ? (
                      i18n.t('general.presup')
                    ) : (
                      <ClipboardText
                        size={20}
                        alt={i18n.t('general.altaddbg')}
                      />
                    )}
                  </button>
                ) : (
                  ''
                )}
                {articulo.codigo_articulo && props.anadir_lista ? (
                  <Fragment>
                    <BotonAnadirLista
                      origen='tabla'
                      mostrar_texto_btn={props.mostrar_texto_btn}
                      codigo_articulo={articulo.codigo_articulo}
                      presentacion={presentacion.presentacion || articulo.presentacion_web}
                      tabIndex={4 + (props.index && props.index !== '' ? props.index * 4 : 1)}
                    />
                  </Fragment>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        )
      }

    default:
      return (
        <Fragment>
          <form className='form-inline form-default control_anadir_carrito'>
            <div className='input-group'>
              <input
                className='form-control desctop'
                id={'input-cant-' + articulo.codigo_articulo}
                type='number'
                step={articulo?.multiplo || 1}
                min={articulo?.cantidad_minima || 0}
                name='valor'
                disabled={!comprobarDatos('P', 'input')}
                //data-tooltip="Cantidad"
                onChange={e => {
                  setValor(e.target.value)
                }}
                value={valor}
                onBlur={parametros.ej_onblur === 'N' ? null : onBlur}
                placeholder={parametros.cant_def}
                tabIndex={1 + (props.index && props.index !== '' ? props.index * 2 : 1)}
              />
              <div className='detach-quantity-desctope mobile'>
                <div className='tt-input-counter style-01'>
                  <input
                    id={'input-cant-mob-' + articulo.codigo_articulo}
                    className='quantity-input'
                    onChange={onChange}
                    value={valor === 0 ? parametros.cant_def : valor}
                    disabled={!comprobarDatos('P', 'input')}
                    type='number'
                    pattern='[0-9]*'
                    name='valor'
                    size='5'
                  />
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={e => {
                  e.preventDefault()
                  anadirCarro('P')
                }}
                tabIndex={2 + (props.index && props.index !== '' ? props.index * 2 : 1)}
                disabled={!comprobarDatos('P', 'button')}>
                <ShoppingCart
                  size={20}
                  alt={i18n.t('general.altaddsc')}
                />
                {props.mostrar_texto_btn === 'S' ? i18n.t('general.pedido').toUpperCase() : ''}
              </button>
            </div>
          </form>
        </Fragment>
      )
  }
}

export default AnadirCarrito
