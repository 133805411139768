import React, { Component, Fragment} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { setValorCampoExpediente } from "../../actions/expedientes";
import es from "date-fns/locale/es";
registerLocale("es", es);

export class InputFecha extends Component {
  state = {
    valor: "",
    fecha: "",
  };

  static propTypes = {
    setValorCampoExpediente: PropTypes.func.isRequired,
  };

  handleChange = (date) => {
    // Si se pulsa el boton Hoy se carga la fecha actual con horas minutos y segundos
    if (window.event.target.className === 'react-datepicker__today-button'){
      date = new Date();
    }
    if (date) {
      let fecha = this.procesarFecha(date);
      this.setState({
        valor: date,
        fecha: fecha,
      });
      let date_proc = date.toJSON();
      this.props.setValorCampoExpediente(this.props.campo.columna, date_proc);
      this.props.handleChange(this.props.campo.columna, date_proc);
    } else {
      this.setState({
        valor: date,
        fecha: date,
      });
    }
  };

  procesarFecha(date) {
    // Procesamos la fecha para devolverla en un formato compatible con LISA
    let fechaMuestra = date.toLocaleDateString();
    let hora = date.toTimeString();
    let longitud = hora.indexOf("GMT") - 1;
    hora = hora.substring(0, longitud);
    return fechaMuestra + " " + hora;
  }

  render() {
    const InputFechaDatepicker = ({ onClick }) => (
      <input
        id={this.props.campo.columna}
        className="form-control"
        type="text"
        value={this.state.fecha}
        name={this.props.campo.columna}
        placeholder={this.props.campo.literal}
        required={this.props.campo.obligatorio === "S"}
        onClick={onClick}
        disabled={this.props.isValidating || this.props.campo.desactivado === 'S' ? true : false}
        readOnly
      />
    );

    return (
      <Fragment>
        <div className="form-group fecha" key={this.props.index}>
          <label className={this.props.origen === "expedientes" ? (this.props.campo.desactivado === "S" ? "campo-expediente-label-disabled" : "campo-expediente-label") : ""} htmlFor={this.props.campo.columna}>{this.props.campo.literal}</label>

          <DatePicker
            id={this.props.campo.columna}
            onChange={this.handleChange}
            isClearable
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            dateFormatCalendar="MMMM"
            selected={this.state.valor}
            dateFormat="dd/MM/yyyy HH:MM:ss"
            todayButton="Hoy"
            showTimeSelect={true}
            timeIntervals={15}
            locale="es"
            customInput={<InputFechaDatepicker />}
            className="form-control"
            name={this.props.campo.columna}
            placeholderText={this.props.campo.literal}
            required={this.props.campo.obligatorio === "S"}
            disabled={this.props.isValidating || this.props.campo.desactivado === 'S' ? true : false}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  lov: state.expedientes.valores_lovs,
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  isValidating: state.expedientes.isValidating,
  auth: state.auth,
});
export default connect(mapStateToProps, { setValorCampoExpediente })(InputFecha);
