import axios from "../axiosConfig";
import { FETCH_SUSTITUTOS, GET_SUSTITUTOS } from "./types";
import { tokenConfig } from "./auth";

export const getArticulosSustitutos = (id_articulo) => (dispatch, getState) => {
  dispatch({ type: FETCH_SUSTITUTOS, codigo_articulo: id_articulo });

  axios
    .get(`/articulos/sustitutos/?codigo_articulo=${id_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUSTITUTOS,
        payload: res.data,
        codigo_articulo: id_articulo,
      });
    })
    .catch((err) => console.error(err));
};
