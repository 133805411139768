import { FETCH_ARTICULOS_CONJUNTO, GET_ARTICULOS_CONJUNTO, FAIL_GET_ARTICULOS_CONJUNTO } from "../actions/types";

const initialState = {
  articulos_conjunto: [],
  loading: false,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULOS_CONJUNTO:
      return {
        ...state,
        loading: true,
        articulos_conjunto: [],
      };
    case GET_ARTICULOS_CONJUNTO:
      return {
        ...state,
        loading: false,
        articulos_conjunto: action.payload,
      };
    case FAIL_GET_ARTICULOS_CONJUNTO:
      return {
        ...state,
        loading: false,
        articulos_conjunto: [],
      };
    default:
      return state;
  }
}
