import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createMessage } from "../../actions/messages";
import { cambiarPass } from "../../actions/auth";
import { validaPass } from "../../helpers/funciones";
import SubscripcionesStock from "./SubscripcionesStock.js";
import i18n from "../../lang/idiomas";

export class Cuenta extends Component {
  state = {
    pass: "",
    nuevo_pass: "",
    re_nuevo_pass: "",
    error_iguales: false,
    error_vacios: false,
    subs_ocultas: true,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    subscripciones: PropTypes.object,
    cambiarPass: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    window.wookie();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();

    const { pass, nuevo_pass, re_nuevo_pass } = this.state;
    // perform all neccassary validations
    if (pass !== "" && nuevo_pass !== "" && re_nuevo_pass !== "") {
      if (nuevo_pass !== re_nuevo_pass) {
        this.props.createMessage(i18n.t("usuarios.ucontnoi"), "error");
        this.setState({ error_iguales: true });
      } else if (!validaPass(nuevo_pass) || !validaPass(re_nuevo_pass)) {
        this.props.createMessage(i18n.t("usuarios.unocumre"), "error");
      } else {
        this.props.cambiarPass(pass, nuevo_pass);
      }
    } else {
      this.props.createMessage(i18n.t("usuarios.ucamnopv"), "error");
      this.setState({ error_vacios: true });
    }
  };
  verSubscripciones() {
    if (this.state.subs_ocultas === true) {
      this.setState({ subs_ocultas: false });
    } else {
      this.setState({ subs_ocultas: true });
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { pass, nuevo_pass, re_nuevo_pass, error_iguales, error_vacios, subs_ocultas } = this.state;
    return (
      <Fragment>
        <div id="tt-pageContent">
          <div className="container-indent">
            <div className="container container-fluid-custom-mobile-padding">
              <h1 className="tt-title-subpages noborder">{i18n.t("usuarios.umicuent")}</h1>
              <div className="tt-shopping-layout">
                <div className="tt-wrapper">
                  <h3 className="tt-title">{i18n.t("usuarios.umisdato")}</h3>
                  <div className="tt-table-responsive">
                    <table className="tt-table-shop-02">
                      <tbody>
                        <tr>
                          <td>{i18n.t("usuarios.uusr")}</td>
                          <td>{isAuthenticated ? user.usuario_web : ""} </td>
                        </tr>
                        <tr>
                          <td>{i18n.t("usuarios.uemail")}</td>
                          <td>{isAuthenticated ? user.email_usuario_web : ""} </td>
                        </tr>
                        <tr>
                          <td>{i18n.t("usuarios.unombr")}</td>
                          <td>{isAuthenticated ? user.nombre : ""} </td>
                        </tr>
                        {/*<tr>
                          <td>ADDRESS:</td>
                          <td>
                            Eexercitation ullamco laboris nisi ut aliquip ex ea
                          </td>
                        </tr>
                        <tr>
                          <td>ADDRESS 2:</td>
                          <td>Commodo consequat. Duis aute irure dol</td>
                        </tr>
                        <tr>
                          <td>COUNTRY:</td>
                          <td>Lorem ipsum dolor sit amet conse ctetur</td>
                        </tr>
                        <tr>
                          <td>ZIP:</td>
                          <td>555</td>
                        </tr>
                        <tr>
                          <td>PHONE:</td>
                          <td>888888888</td>
                        </tr>*/}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tt-wrapper">
                  <h3 className="tt-title">{i18n.t("usuarios.ucambcon")}</h3>
                  <div className="tt-login-form">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-lg-6">
                        <div className="tt-item">
                          <div className="form-default">
                            <form onSubmit={this.onSubmit}>
                              <div className="form-group">
                                <label htmlFor="pass">{i18n.t("usuarios.ucontact")}</label>
                                <div className="tt-required">{i18n.t("usuarios.ucamobli")}</div>
                                <input
                                  type="password"
                                  name="pass"
                                  onChange={this.onChange}
                                  value={pass}
                                  className={error_vacios ? "form-control error" : "form-control"}
                                  id="pass"
                                  placeholder={i18n.t("usuarios.uconactm")}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="nuevo_pass">{i18n.t("usuarios.unuecont")}</label>
                                <input
                                  type="password"
                                  name="nuevo_pass"
                                  onChange={this.onChange}
                                  value={nuevo_pass}
                                  className={error_iguales || error_vacios ? "form-control error" : "form-control"}
                                  id="nuevo_pass"
                                  placeholder={i18n.t("usuarios.unueconm")}
                                  required
                                />
                                <p>{i18n.t("usuarios.ureqmin")}</p>
                              </div>
                              <div className="form-group">
                                <label htmlFor="re_nuevo_pass">{i18n.t("usuarios.repcont")}</label>
                                <input
                                  type="password"
                                  name="re_nuevo_pass"
                                  onChange={this.onChange}
                                  value={re_nuevo_pass}
                                  className={error_iguales || error_vacios ? "form-control error" : "form-control"}
                                  id="re_nuevo_pass"
                                  placeholder={i18n.t("usuarios.urecontm")}
                                  required
                                />
                                <p>{i18n.t("usuarios.ureqmin")}</p>
                              </div>
                              <div className="row">
                                <div className="col-auto">
                                  <div className="form-group">
                                    <button className="btn btn-border" type="submit">
                                      {i18n.t("usuarios.ucambiar")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tt-wrapper">
                  <h3 className="tt-title">{i18n.t("usuarios.missubs")}</h3>
                  <button
                    className="btn btn-border subscripciones"
                    disabled={this.props.subscripciones && this.props.subscripciones.lineas && this.props.subscripciones.lineas.length > 0 ? false : true}
                    onClick={(e) => {
                      e.preventDefault();
                      this.verSubscripciones();
                    }}
                  >
                    {i18n.t("usuarios.gessubs")}
                  </button>
                  <div id={isAuthenticated ? "subscripciones-" + user.usuario_web : "subscripciones"} hidden={subs_ocultas}>
                    <SubscripcionesStock origen={"tabla-subs"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  subscripciones: state.auth.user.subscripciones,
});

export default connect(mapStateToProps, { cambiarPass, createMessage })(Cuenta);
