import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";
import { FETCH_ARTICULOS_KIT, GET_ARTICULOS_KIT, FAIL_GET_ARTICULOS_KIT } from "./types";

export const getArticulosKit = (codigo_articulo) => (dispatch, _) => {
  dispatch({ type: FETCH_ARTICULOS_KIT });
  const url = `/articuloskit/${codigo_articulo}`;

  axios
    .get(url, tokenConfig)
    .then((result) => {
      dispatch({ type: GET_ARTICULOS_KIT, payload: result.data });
    })
    .catch((error) => {
      dispatch({ type: FAIL_GET_ARTICULOS_KIT });
      dispatch(createMessage("No se ha podido cargar el contenido del kit", error.response.status));
    });
};
