import React, { Fragment } from "react";
import ListadoArticulos from "./ListadoArticulos";

const ArticulosSustitutos = ({ id_articulo, origen }) => {
  switch (origen) {
    case "ficha":
      return (
        <Fragment>
          <ListadoArticulos mostrar="lista" origen="sustitutos" id_articulo={id_articulo} />
        </Fragment>
      );
    default:
      return "";
  }
};

export default ArticulosSustitutos;
