import Rect, { useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Precio from "./Precio";
import Imagen from "./Imagen";
import { useDispatch, useSelector } from "react-redux";
import { getArticulosConjunto } from "../../actions/articulosConjunto";

const ArticulosConjunto = ({ articulo_padre, margin_top = null, cards_width = null }) => {
  const dispatch = useDispatch();
  const articulosConjunto = useSelector((state) => state.articulosConjunto.articulos_conjunto);
  const isLoading = useSelector((state) => state.articulosConjunto.loading);

  const targetUrl = (articulo) => {
    return "/articulo/" + articulo.codigo_articulo + "/" + (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "");
  };

  const articuloActual = (articulo) => {
    return articulo_padre.contenido_conjunto.find((elemento) => {
      return articulo.codigo_articulo === elemento.codigo_articulo_compo;
    });
  };

  const style_vars = {
    "--margin-top": margin_top ?? "40px",
    "--cards-width": cards_width ?? "150px",
  };

  const parseNumber = (number, floatPlaces = 2) => {
    const areEquals = number !== Math.floor(number);

    return parseFloat(number).toFixed(areEquals ? 0 : floatPlaces);
  };

  useEffect(() => {
    dispatch(getArticulosConjunto(articulo_padre.codigo_articulo));
  }, []);

  return (
    <div className="articulos-conjunto-content" style={style_vars}>
      <h1 className="tt-title">Contenido del Conjunto ({articulosConjunto.length ?? "--"})</h1>
      {isLoading ? (
        <Spinner showSpinner={isLoading}></Spinner>
      ) : (
        <div className="cards-holder">
          {articulosConjunto.map((articulo) => {
            return (
              <div className="conjunto-card" key={articulo.id}>
                <div className="image-holder ">
                  <Link to={targetUrl(articulo)}>
                    <Imagen codigo_articulo={articulo.codigo_articulo} numero_imagen={1} principal="S" tipo_imagen="min" />
                  </Link>
                </div>
                <div className="content">
                  <Link to={targetUrl(articulo)}>
                    <span>{articulo.descrip_comercial}</span>
                  </Link>
                  <span>
                    <Precio codigo_articulo={articulo.codigo_articulo} presentacion={articulo.presentacion_web} cantidad={1} origen="ficha" mostrar_iva="N" mostrar_pres="N" estilo_precio="unitario"/>
                  </span>
                  <span>
                    <b>Cantidad: </b> {parseNumber(articuloActual(articulo).cantidad)} {articulo.presentacion_web}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ArticulosConjunto;
