import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { getArticulosByFamily } from '../../actions/articulos'
import { añadirFiltro, getFiltros, limpiarFiltros, quitarFiltro } from '../../actions/filtros'
import { getStocksArticulos } from '../../actions/stocks'
import { cargarArrayStocks } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import ListadoArticulos from './ListadoArticulos'
import SelectorAlmacen from './SelectorAlmacen'

const ArticulosListItems = props => {
  const dispatch = useDispatch()

  const ecomFiltros = useSelector(state => state.articulos.filtros.filtros)
  const ecomFiltrosClavesEstadisticas = useSelector(
    state => state.articulos.filtros_claves_estadisticas.filtros_claves_estadisticas,
  )
  const cargandoFiltros = useSelector(state => state.articulos.filtros.isLoading)
  const filtros_aplicados = useSelector(state => state.articulos.filtros_aplicados)
  const pedido = useSelector(state => state.pedidos.pedido)
  const articulos_pagina = useSelector(state => state.listadoArticulos.articulos_pagina)
  const articulos_search = useSelector(state => state.listadoArticulos.articulos_search)
  const articulos_orden = useSelector(state => state.listadoArticulos.articulos_orden)
  const clienteSeleccionado = useSelector(state => state.selectorCliente.cliente_seleccionado)
  const listado_articulos = useSelector(state => state.listadoArticulos.articulos)
  const stocks = useSelector(state => state.stocks.stock)
  const isLoadingArticulos = useSelector(state => state.articulos.isLoading)

  const [filtros, setFiltros] = useState({})

  useEffect(() => {
    window.wookie()

    /** Limpiamos filtros para cargar de inicio el listado sin filtros */
    dispatch(limpiarFiltros())
    dispatch(getArticulosByFamily([], articulos_pagina, articulos_orden, articulos_search))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectorChange = target => {
    if (target?.value !== null) {
      if (target.tipo === 'estad') {
        setFiltros({
          ...filtros,
          estad: {
            ...filtros.estad,
            [target.estadistico]: target.value,
          },
          claves: {
            ...filtros.claves,
          },
        })
      } else if (target.tipo === 'clave') {
        setFiltros({
          ...filtros,
          estad: {
            ...filtros.estad,
          },
          claves: {
            ...filtros.claves,
            [target.estadistico]: target.value,
          },
        })
      }

      switch (target.tipo) {
        case 'clave':
          if (
            filtros.hasOwnProperty('claves') &&
            filtros.claves.hasOwnProperty(target.estadistico) &&
            filtros.claves[target.estadistico] !== target.value
          ) {
            dispatch(
              quitarFiltro({
                estadistico: target.estadistico,
                familia: filtros.claves[target.estadistico],
                nombre: '',
              }),
            )
          }
          break

        case 'estad':
          if (
            filtros.hasOwnProperty('estad') &&
            filtros.estad.hasOwnProperty(target.estadistico) &&
            filtros.estad[target.estadistico] !== target.value
          ) {
            dispatch(
              quitarFiltro({
                estadistico: target.estadistico,
                familia: filtros.estad[target.estadistico],
                nombre: '',
              }),
            )
          }
          break

        default:
          break
      }

      dispatch(
        añadirFiltro({
          estadistico: target.estadistico,
          familia: target.value,
          nombre: '',
          tipo: target.tipo,
        }),
      )
    } else {
      if (target.tipo === 'estad') {
        dispatch(
          quitarFiltro({
            estadistico: target.estadistico,
            familia: filtros.estad[target.estadistico],
            nombre: '',
          }),
        )
      } else if (target.tipo === 'clave') {
        dispatch(
          quitarFiltro({
            estadistico: target.estadistico,
            familia: filtros.claves[target.estadistico],
            nombre: '',
          }),
        )
      }
    }
  }

  useEffect(() => {
    let articulos_aux = []
    articulos_aux = cargarArrayStocks('', listado_articulos, stocks, pedido)
    if (articulos_aux?.length > 0) {
      //Solo llamamos a getStocks si hai al menos 1 elemento en el array

      dispatch(
        getStocksArticulos(
          articulos_aux, // articulos_stock
          '', // origen
          '', // pedir_cantidad
          null, // lista_compra
          '', // Maximo de peticiones agrupadas
        ),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteSeleccionado])

  useEffect(() => {
    if (
      !cargandoFiltros &&
      ecomFiltros.length === 0 &&
      ecomFiltrosClavesEstadisticas.length === 0
    ) {
      dispatch(getFiltros())
    }

    if (!isLoadingArticulos) {
      dispatch(
        getArticulosByFamily(
          filtros_aplicados,
          articulos_pagina,
          articulos_orden,
          articulos_search,
        ),
      )
    }
  }, [
    cargandoFiltros,
    ecomFiltros,
    ecomFiltrosClavesEstadisticas,
    filtros_aplicados,
    articulos_pagina,
    articulos_orden,
    articulos_search,
  ])

  if (Object.keys(pedido).length === 0 || pedido.codigo_cliente === '') {
    return (
      <Fragment>
        <SelectorAlmacen />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className='container container-art-list-items'>
          <div className='filtros'>
            {cargandoFiltros ? (
              <Spinner showSpinner={cargandoFiltros} />
            ) : (
              <form id='form-claves-filtros'>
                <div
                  id='formClaves'
                  className='form-group'>
                  {ecomFiltrosClavesEstadisticas.map(clave => {
                    let optionsClave = [
                      {
                        key: 0,
                        estadistico: clave.clave_estadistica,
                        value: null,
                        label: i18n.t('articulosListItems.selcla'),
                        tipo: 'clave',
                      },
                    ]

                    for (const indiceClave in clave.claves) {
                      optionsClave.push({
                        key: clave.id,
                        estadistico: clave.clave_estadistica,
                        value: clave.claves[indiceClave].valor_clave,
                        label: clave.claves[indiceClave].nombre,
                        tipo: 'clave',
                      })
                    }

                    return (
                      <div key={'contaneir-clave-' + clave.clave_estadistica}>
                        <label htmlFor={'clave-' + clave.clave_estadistica}>{clave.nombre}</label>
                        <Select
                          id={'clave-' + clave.clave_estadistica}
                          key={'clave-' + clave.clave_estadistica}
                          options={optionsClave}
                          onChange={selectorChange}
                          placeholder={i18n.t('articulosListItems.selcla')}
                        />
                      </div>
                    )
                  })}
                </div>
                <div
                  id='formFiltros'
                  className='form-group'>
                  {ecomFiltros.map(filtro => {
                    let optionsFiltro = [
                      {
                        key: 0,
                        estadistico: filtro.estadistico,
                        value: null,
                        label: i18n.t('articulosListItems.selfil'),
                        tipo: 'estad',
                      },
                    ]

                    for (const indiceFamilia in filtro.familias) {
                      optionsFiltro.push({
                        key: filtro.id,
                        estadistico: filtro.estadistico,
                        value: filtro.familias[indiceFamilia].codigo_familia,
                        label: filtro.familias[indiceFamilia].descripcion,
                        tipo: 'estad',
                      })
                    }

                    return (
                      <div key={'container-filtro' + filtro.estadistico}>
                        <label htmlFor={'filtro' + filtro.estadistico}>{filtro.nombre}</label>
                        <Select
                          id={'filtro' + filtro.estadistico}
                          key={'filtro' + filtro.estadistico}
                          options={optionsFiltro}
                          onChange={selectorChange}
                          placeholder={i18n.t('articulosListItems.selfil')}
                        />
                      </div>
                    )
                  })}
                </div>
              </form>
            )}
          </div>
          <div className='lista-articulos'>
            <ListadoArticulos
              mostrar='tabla'
              origen='articulos'
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
export default ArticulosListItems
