import React, { Component } from "react";
import { connect } from "react-redux";
import { getListaPlantillas, toglePlantillaDetails } from "./actions/plantillas";
import axios from "../../axiosConfig";
import Spinner from "../layout/Spinner";
import { ArrowLineLeft } from "phosphor-react";

import ListaPlantillas from "./components/ListaPlantillas";
import DetallesPlantilla from "./components/DetallesPlantilla";
import i18n from "../../lang/idiomas";

class Plantillas extends Component {
  componentDidMount() {
    this.props.getListaPlantillas();
  }

  render() {
    let textoMostrar = i18n.t("plantillas.pplantil");
    if (this.props.show_plantilla_details) textoMostrar = i18n.t("plantillas.pdetplan");
    if (this.props.show_plantilla_details && this.props.plantilla_seleccionada) textoMostrar = this.props.plantilla_seleccionada.descripcion;

    return (
      <>
        <div className="container plantillas-pedido">
          <div id="tt-pageContent" className="listas-compra">
            <div className="container-indent">
              <div className="container">
                <>
                  {this.props.show_plantilla_details ? (
                    <h5 onClick={() => this.props.toglePlantillaDetails(false)} className="atras-listas">
                      <ArrowLineLeft size={22} weight={"regular"} /> {i18n.t("plantillas.pplantil")}
                    </h5>
                  ) : (
                    <></>
                  )}
                  <h1 className="tt-title-subpages noborder mt-3">{textoMostrar}</h1>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Spinner showSpinner={this.props.plantillas_loading} />
                      <>{this.props.show_plantilla_details ? <DetallesPlantilla /> : <ListaPlantillas />}</>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  plantillas: state.plantillas,
  lista_plantillas: state.plantillas.lista_plantillas,
  plantillas_loading: state.plantillas.isLoading,
  show_plantilla_details: state.plantillas.show_plantilla_details,
  plantilla_seleccionada: state.plantillas.plantilla_seleccionada,
});

export default connect(mapStateToProps, { getListaPlantillas, toglePlantillaDetails })(Plantillas);
