import login from "../translations/login/es_ES.json";
import informes from "../translations/informes/es_ES.json";
import general from "../translations/general/es_ES.json";
import articulos from "../translations/articulos/es_ES.json";
import resumen from "../translations/resumen/es_ES.json";
import subscripciones from "../translations/subscripciones/es_ES.json";
import carrito from "../translations/carrito/es_ES.json";
import buscador from "../translations/buscador/es_ES.json";
import lista from "../translations/lista/es_ES.json";
import cardnet from "../translations/cardnet/es_ES.json";
import domicilios from "../translations/domicilios/es_ES.json";
import efactura from "../translations/efactura/es_ES.json";
import formas from "../translations/formas/es_ES.json";
import pedido from "../translations/pedido/es_ES.json";
import eparciales from "../translations/eparciales/es_ES.json";
import expedientes from "../translations/expedientes/es_ES.json";
import modal from "../translations/modal/es_ES.json";
import rutas from "../translations/rutas/es_ES.json";
import tarifa from "../translations/tarifa/es_ES.json";
import transportista from "../translations/transportista/es_ES.json";
import wompi from "../translations/wompi/es_ES.json";
import layout from "../translations/layout/es_ES.json";
import usuarios from "../translations/usuarios/es_ES.json";
import common from "../translations/common/es_ES.json";
import plantillas from "../translations/plantillas/es_ES.json";
import selectorAlmacen from "../translations/selectorAlmacen/es_ES.json";
import articulosListItems from "../translations/articulosListItems/es_ES.json";

export default {
  translation: {
    login,
    informes,
    general,
    articulos,
    resumen,
    subscripciones,
    carrito,
    buscador,
    lista,
    cardnet,
    domicilios,
    efactura,
    formas,
    pedido,
    eparciales,
    modal,
    rutas,
    expedientes,
    tarifa,
    transportista,
    wompi,
    layout,
    usuarios,
    common,
    plantillas,
    selectorAlmacen,
    articulosListItems,
  },
};
