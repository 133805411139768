import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import config from "../../config";
import i18n from "../../lang/idiomas";

import "../../css/login.css";

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <div className="tt-coming-soon" style={{ minHeight: "79vh" }}>
        <div className="container">
          <div className="row	justify-content-center">
            <div className="col-md-4 tt-coming-soon-content">
              <a className="tt-logo" href="index.html">
                <img className="tt-retina" src="images/custom/logo.png" alt="" />
              </a>
              <h1 className="tt-title">{i18n.t("usuarios.ugracvis")}</h1>
              <h2 className="tt-title">{i18n.t("usuarios.uesvispr")}</h2>
            </div>
          </div>
        </div>
        <div className="coming-soon-bottom">
          <p>&copy; {i18n.t("usuarios.utodder")}</p>
          <ul className="tt-social-icon">
            {config.redes_sociales.facebook !== "" ? (
              <li>
                <a href={config.redes_sociales.facebook} target="blank" className="icon-g-64">
                  <span className="ocultar">Facebook</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {config.redes_sociales.twitter !== "" ? (
              <li>
                <a href={config.redes_sociales.twitter} target="blank" className="icon-h-58">
                  <span className="ocultar">Twitter</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {config.redes_sociales.google_plus !== "" ? (
              <li>
                <a href={config.redes_sociales.google_plus} target="blank" className="icon-g-66">
                  <span className="ocultar">Google Plus</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {config.redes_sociales.instagram !== "" ? (
              <li>
                <a href={config.redes_sociales.instagram} target="blank" className="icon-g-67">
                  <span className="ocultar">Instagram</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {config.redes_sociales.linkedin !== "" ? (
              <li>
                <a href={config.redes_sociales.linkedin} target="blank" className="icon-g-70">
                  <span className="ocultar">Linkedin</span>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(null, { logout })(Logout);
